/**
 * Parses and nests html content for SendGrid
 * @param {String} htmlString
 */

export default function nestHtmlList(htmlString) {
	let parser = new DOMParser();
	let doc = parser.parseFromString(htmlString, 'text/html');
	let listItems = Array.from(doc.querySelectorAll('li'));

	listItems.forEach((item, index) => {
		let parent = item.parentElement;
		let prevItem = listItems[index - 1]; // The preceding <li> item

		if (item.className.startsWith('ql-indent-')) {
			let newUl = doc.createElement('ul');
			item.remove();
			newUl.appendChild(item);

			if (prevItem) {
				// Append the new nested <ul> to the preceding <li> item
				prevItem.appendChild(newUl);
			} else {
				// If there is no preceding item, append it back to the original parent
				parent.appendChild(newUl);
			}
		}
	});

	// Handle text and image alignment
	let alignElements = Array.from(doc.querySelectorAll('.ql-align-right, .ql-align-left, .ql-align-center'));
	alignElements.forEach((element) => {
		let styleText = '';
		if (element.classList.contains('ql-align-right')) {
			styleText = 'text-align: right;';
		} else if (element.classList.contains('ql-align-left')) {
			styleText = 'text-align: left;';
		} else if (element.classList.contains('ql-align-center')) {
			styleText = 'text-align: center;';
		} else if (element.classList.contains('ql-align-justify')) {
			styleText = 'text-align: justify;';
		}

		if (element.tagName.toLowerCase() === 'img') {
			// Wrap image with a div for alignment if necessary
			const wrapperDiv = doc.createElement('div');
			wrapperDiv.setAttribute('style', styleText);
			element.parentNode.insertBefore(wrapperDiv, element);
			wrapperDiv.appendChild(element);
		} else {
			element.style.cssText += styleText;
		}
	});

	return doc.body.innerHTML;
}
