import React, { useEffect, useState, useContext } from 'react';
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Divider,
	Stack,
	Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';

import AuthContext from '../auth/authContext';
import LoadingSpinner from '../ui/LoadingSpinner';
import GetOrgTree from './GetOrgTree';
import GetParentPositions from './GetParentPositions';
import AddDeleteGroups from './AddDeleteGroups';
import GetPhotoURL from '../ui/GetPhotoURL';
import UserImage from '../ui/UserImage';
import { db } from '../../App';

export default function LeadershipModalEditGroups(props) {
	const [loadingPhoto, setLoadingPhoto] = useState(null);
	const [loadingGroups, setLoadingGroups] = useState(null);
	const [error, setError] = useState(null);
	const authCtx = useContext(AuthContext);
	const [newPosition, setNewPosition] = useState({ ...props.positionData });
	const [photoURL, setPhotoURL] = useState('');
	const [allPositions, setAllPositions] = useState([]);

	//sets groups if not already defined
	useEffect(() => {
		if (newPosition.groups === undefined || newPosition.groups[0] === undefined || newPosition.groups[0] === '') {
			setNewPosition((prevState) => ({
				...prevState,
				groups: [{ id: `${new Date().toISOString()}`, group: '', groupKey: '', memberType: '' }],
			}));
		}
	}, [newPosition]);

	//gets the positions & groups; sets allPositions
	useEffect(() => {
		const getData = async () => {
			setLoadingGroups(true);
			setAllPositions([]);
			const data = await GetOrgTree('org_chart');
			const response = await GetParentPositions(data);
			const newArray = [...response];
			const sortedChoices = newArray.sort((a, b) =>
				a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
			);
			const items = [];
			sortedChoices.map((choice, index) => {
				return items.push({ label: choice.name, key: choice.id, type: choice.type });
			});

			setAllPositions((prevState) => [...prevState, ...items]);

			setLoadingGroups(false);
		};

		getData();
	}, []);

	//gets photoURL
	useEffect(() => {
		const getPhotUrl = async () => {
			setLoadingPhoto(true);
			const url = await GetPhotoURL(newPosition.currentPerson.email, authCtx.token);
			if (url && url.title && url.title === 'Error') {
				setError(url.message);
			} else {
				setPhotoURL(url);
			}
			setLoadingPhoto(null);
		};
		getPhotUrl();
	}, [newPosition.currentPerson.email, authCtx.token]);

	//adds a group
	const addGroup = () => {
		setNewPosition((prevState) => ({
			...prevState,
			groups: [...prevState.groups, { id: `${new Date().toISOString()}`, memberType: '', group: '', groupKey: '' }],
		}));
	};

	//removes the group with the corresponding id
	const handleRemoveGroup = (id) => {
		setNewPosition((prevState) => ({
			...prevState,
			groups: newPosition.groups.filter((item) => item.id !== id),
		}));
	};

	//updates the group for the position
	const handleGroupChange = (e) => {
		const tempArray = [...newPosition.groups];

		for (let object of tempArray) {
			if (object.id === e.id) {
				object.group = e.group;
				object.groupKey = e.groupKey;
			}
		}

		setNewPosition((prevState) => ({
			...prevState,
			groups: [...tempArray],
		}));
	};

	//updates the member type for the position
	const handleMemberTypeChange = (e) => {
		const tempArray = [...newPosition.groups];

		for (let object of tempArray) {
			if (object.id === e.id) {
				object.memberType = e.memberType;
			}
		}

		setNewPosition((prevState) => ({
			...prevState,
			groups: [...tempArray],
		}));
	};

	//submits the group(s) change
	const onSubmit = () => {
		props.submit(newPosition);
	};

	if (error) {
		return (
			<Dialog open={props.open} onClose={props.cancel} fullWidth>
				<DialogTitle sx={{ fontWeight: 'bold', fontSize: '2rem' }}>Error</DialogTitle>
				<DialogContent>
					<Alert severity='error' sx={{ margin: '1rem' }}>
						<strong>
							There was an error. Logout and log back in. If the error continues to occur, contact IT and provide the
							error message below.
						</strong>
					</Alert>
					<Alert severity='info' sx={{ margin: '1rem' }}>
						{error}
					</Alert>
				</DialogContent>
				<DialogActions>
					<Button variant='contained' onClick={props.cancel} color='error'>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	if (loadingPhoto || loadingGroups) {
		return (
			<Dialog open={props.open} onClose={props.cancelModal} fullWidth>
				{/* position avatar and name */}
				<Stack display='flex' justifyContent='center' alignItems='center' color='secondary' mt={1}>
					<Typography color='primary' variant='h6'>
						{newPosition.positionTitle}
					</Typography>
					<Typography color='primary' variant='h6'>
						Groups
					</Typography>
					<UserImage photoURL={photoURL} name={newPosition.currentPerson.label} size={56} />
					<Typography color='primary'>{newPosition.currentPerson.label}</Typography>
					<Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
						<Divider />
					</Box>
				</Stack>

				<DialogContent>
					<LoadingSpinner />
				</DialogContent>
				<DialogActions>
					<Button variant='contained' onClick={props.cancel} color='cancel'>
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Dialog open={props.open} onClose={props.cancel} fullWidth scroll='body' PaperProps={{ sx: { minHeight: '20vh' } }}>
			{/* position avatar and name */}
			<Stack display='flex' justifyContent='center' alignItems='center' color='secondary' mt={1}>
				<Typography color='primary' variant='h6'>
					{newPosition.positionTitle}
				</Typography>
				<Typography color='primary' variant='h6'>
					Groups
				</Typography>
				<UserImage photoURL={photoURL} name={newPosition.currentPerson.label} size={56} />
				<Typography color='primary'>{newPosition.currentPerson.label}</Typography>
				<Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
					<Divider />
				</Box>
			</Stack>

			<DialogContent>
				<Stack display='flex' justifyContent='center' alignItems='center' color='secondary' mt={1}>
					{newPosition.groups.map((group, key) => {
						return (
							<AddDeleteGroups
								key={key}
								allPositions={allPositions}
								group={group}
								onHandleRemoveGroup={handleRemoveGroup}
								onHandleGroupChange={handleGroupChange}
								onHandleMemberTypeChange={handleMemberTypeChange}
							/>
						);
					})}

					<Button color='primary' variant='outlined' startIcon={<Add />} onClick={addGroup}>
						ADD GROUP
					</Button>
					{/* </ButtonGroup> */}
				</Stack>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}></Box>
			</DialogContent>

			<DialogActions>
				<Button variant='contained' onClick={props.cancel} color='cancel'>
					CANCEL
				</Button>
				<Button variant='contained' onClick={onSubmit}>
					SAVE EDITS
				</Button>
			</DialogActions>
		</Dialog>
	);
}
