import { getFunctions, httpsCallable } from 'firebase/functions';

const UpdateMyInfo = (newData) => {
	return new Promise((resolve) => {
		const functions = getFunctions();
		const updateMyInfo = httpsCallable(functions, 'updateMyInfo');

		updateMyInfo({ newData: newData }).then((response) => {
			const res = response.data;
			if (res.status === 200) {
				// console.log(res.data);
				resolve(res.data);
			} else {
				resolve(false);
			}
		});
	});
};

export default UpdateMyInfo;
