import React, { useContext } from 'react';
import { Fragment, useState, useEffect } from 'react';

import moment from 'moment';

import {
	collection,
	getDoc,
	getDocs,
	doc,
	updateDoc,
	addDoc,
	deleteDoc,
} from 'firebase/firestore';

import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Stack,
	Typography,
	Chip,
	Container,
	TextField,
	Switch,
	FormControlLabel,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import LoadingSpinner from '../ui/LoadingSpinner';
import { db } from '../../App';
import Tooltip from '../ui/Tooltip';
import CustomClaimsContext from '../auth/CustomClaimsContext';

export default function AppsScriptsSettings() {
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [data, setData] = useState(null);
	const [originalData, setOriginalData] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null); // State to manage the selected row
	const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup visibility
	const claimsCtx = useContext(CustomClaimsContext);

	const timerTypeOptions = [
		{ label: 'Minutes', value: 'minutes' },
		{ label: 'Hour', value: 'hour' },
		{ label: 'Day', value: 'day' },
		{ label: 'Week', value: 'week' },
		{ label: 'Month', value: 'month' },
	];

	const minutesOptions = [
		{ label: '15 Minutes', value: 15 },
		{ label: '30 Minutes', value: 30 },
		{ label: '45 Minutes', value: 45 },
	];

	const hourOptions = [
		{ label: '1 Hour', value: 1 },
		{ label: '2 Hours', value: 2 },
		{ label: '3 Hours', value: 3 },
		{ label: '4 Hours', value: 4 },
		{ label: '6 Hours', value: 6 },
		{ label: '12 Hours', value: 12 },
	];

	const weekOptions = [
		{ label: 'Sunday', value: 0 },
		{ label: 'Monday', value: 1 },
		{ label: 'Tuesday', value: 2 },
		{ label: 'Wednesday', value: 3 },
		{ label: 'Thursday', value: 4 },
		{ label: 'Friday', value: 5 },
		{ label: 'Saturday', value: 6 },
	];

	const dayOptions = Array.from({ length: 24 }, (_, index) => {
		const startHour = index === 0 ? 12 : index > 12 ? index - 12 : index;
		const endHour =
			index + 1 === 12 ? 12 : (index + 1) % 12 === 0 ? 12 : (index + 1) % 12;
		const periodStart = index < 12 ? 'AM' : 'PM';
		const periodEnd = index + 1 < 12 || index + 1 === 24 ? 'AM' : 'PM';

		const label = `${startHour} ${periodStart} - ${endHour} ${periodEnd}`;
		return { label: label, value: index };
	});

	const monthDayOptions = Array.from({ length: 31 }, (_, i) => ({
		label: `${i + 1}`,
		value: i + 1, // Days of the month, starting from 1 to 31
	}));

	//get Settings from Firestore
	useEffect(() => {
		if (db) {
			const getData = async () => {
				setLoading(true);

				const tempData = [];
				const querySnapshot = await getDocs(collection(db, 'AppsScript'));
				querySnapshot.forEach((document) => {
					let data = document.data();
					tempData.push({ id: document.id, ...data });
					// console.log(data);
				});

				const sortedData = tempData.sort((a, b) =>
					a.scriptTitle.localeCompare(b.scriptTitle)
				);

				setData(sortedData);
				setOriginalData(JSON.parse(JSON.stringify(sortedData)));
				// console.log(tempData['Last TickLog Download'].date.toDate());

				setLoading(false);
			};

			getData();
		}
	}, [db]);

	/**
	 * @param {string} e - value of item being updated
	 * @param {string} index - the index of the document to be updated
	 * @param {string} field - the field to be updated (i.e. hourlyRate)
	 */
	const handleChange = (e, index, field) => {
		if (selectedRow !== null) {
			setData((prevData) => {
				const newData = [...prevData];
				newData[index][field] = e;
				return newData;
			});
		}
	};

	/**
	 * Deletes a document from Firestore based on its ID.
	 * @param {string} ID - The ID of the document to be deleted.
	 */
	const handleRemoval = async (ID) => {
		setSaving(true); // Assuming you have a `setSaving` function to indicate loading state
		handlePopupClose();

		try {
			// Delete the document from Firestore
			await deleteDoc(doc(db, 'AppsScript', ID));

			// Update local state to remove the deleted document from the UI
			setData((prevData) => prevData.filter((item) => item.id !== ID));
		} catch (error) {
			console.error('Error deleting document:', error);
			// Handle the error as needed, possibly showing an error message
		} finally {
			setSaving(false); // Reset the saving/loading indicator
		}
	};

	const handleAddRow = () => {
		// Define the structure of your new row with default/empty values
		const newRow = {
			functionName: '',
			scriptId: '',
			status: false, // Assuming false indicates a 'not yet run' state
			// Add other fields as necessary with their default values
		};

		setData((prevData) => [...prevData, newRow]);
	};

	const handleSave = async (document, index) => {
		setSaving(true);

		let ref;
		let docId = document.id;

		try {
			// Check if document has an id
			if (docId) {
				// Document exists, so update it
				ref = doc(db, 'AppsScript', docId);
				await updateDoc(ref, { ...document });
			} else {
				// No id, so create a new document and get the generated id
				ref = await addDoc(collection(db, 'AppsScript'), { ...document });
				docId = ref.id; // Get the generated document id
			}

			// Fetch the saved or updated document
			const docSnap = await getDoc(ref);

			if (docSnap.exists()) {
				setData((prevData) => {
					const newData = [...prevData];
					newData[index] = { id: docId, ...docSnap.data() };
					return newData;
				});
				setOriginalData((prevData) => {
					const newData = [...prevData];
					newData[index] = { id: docId, ...docSnap.data() };
					return JSON.parse(JSON.stringify(newData));
				});
			}
		} catch (error) {
			console.error('Error saving document:', error);
			// Handle the error as needed
		}

		setSaving(false);
	};

	const handleRowSelect = (index) => {
		setSelectedRow(index);
		setIsPopupOpen(true);
	};

	const handlePopupClose = () => {
		setIsPopupOpen(false);
		setSelectedRow(null);
	};

	const handlePopupSave = () => {
		setIsPopupOpen(false);
		handleSave(data[selectedRow], selectedRow); // Reuse the handleSave function for saving changes
	};

	const handleToggleActive = async (index, isActive) => {
		const item = data[index];
		const updatedItem = { ...item, active: isActive };

		// Update in Firestore
		// Check if document has an id
		if (item.id) {
			// Document exists, so update it
			const ref = doc(db, 'AppsScript', item.id);
			await updateDoc(ref, { ...updatedItem });
		} else {
			// No id, so create a new document and get the generated id
			const ref = await addDoc(collection(db, 'AppsScript'), {
				...updatedItem,
			});
			item.id = ref.id; // Get the generated document id
		}

		// Update local state to reflect the change
		setData((prevData) => {
			const newData = [...prevData];
			newData[index] = updatedItem;
			return newData;
		});
	};

	// Function to calculate the next run time based on the last run timestamp
	const calculateNextRunTime = (item) => {
		// Use item's last run timestamp or Unix epoch as fallback
		const lastRun = item.timestamp
			? new Date(item.timestamp.toDate())
			: new Date(0);
		let nextRun = new Date(lastRun); // Start calculation from the last run time

		switch (item.timerType) {
			case 'minutes':
				nextRun.setMinutes(lastRun.getMinutes() + item.minutesInterval);
				break;
			case 'hour':
				nextRun.setHours(lastRun.getHours() + item.hourInterval);
				break;
			case 'day':
				nextRun.setDate(lastRun.getDate() + 1);
				nextRun.setHours(item.hourOfDay, 0, 0, 0);
				break;
			case 'week':
				const daysUntilNextRun = (item.weekDay - lastRun.getDay() + 7) % 7 || 7; // Calculate days until the next scheduled day of the week
				nextRun.setDate(lastRun.getDate() + daysUntilNextRun);
				nextRun.setHours(item.hourOfDay, 0, 0, 0);
				break;
			case 'month':
				nextRun.setMonth(lastRun.getMonth() + 1); // Move to the next month
				nextRun.setDate(item.dayOfMonth); // Set to the selected day of the month
				nextRun.setHours(item.hourOfDay, 0, 0, 0);
				break;
			default:
				return 'N/A';
		}

		// Handle edge cases where the calculated next run time might be in the past
		const now = new Date();
		if (nextRun <= now) {
			// This part may need adjustment based on your specific logic for handling past run times
			nextRun = new Date(now); // Optionally set to now or calculate the next valid future time
		}

		// Format the next run time for display
		return moment(nextRun).format('MMM DD, YYYY HH:mm A');
	};

	//display if loading
	if (loading) {
		return <LoadingSpinner />;
	}

	return (
		<Fragment>
			<Stack
				alignContent={'center'}
				display={'flex'}
				justifyContent={'center'}
				alignItems={'center'}
			>
				{/* title */}
				<Stack
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					mb={5}
					spacing={2}
					width={'50%'}
				>
					<Typography textAlign='center' variant='h5'>
						Apps Script Files
					</Typography>
					<Typography textAlign={'center'}>
						A timed background function will run every 15 minutes, triggering
						the following Apps Script files if they are turned On and the next
						run time is ready to be triggered. If an Apps Script file fails to
						run properly, it will be triggered again on the next background
						function run.
					</Typography>
					<Typography
						textAlign={'center'}
						variant='overline'
						fontWeight={'bold'}
					>
						Each Apps Script must be:
						<ul>
							<li>- shared with admin@ept911.com</li>
							<li>
								- listed as Google Cloud Platform (GCP) Project 461503283931
							</li>
							<li>- deployed as an API Executable</li>
						</ul>
					</Typography>
				</Stack>

				{/* data rows */}
				{data?.map((item, index) => {
					return (
						<Stack key={index} spacing={2} mb={2} width={'100%'}>
							<Stack direction={'row'} spacing={2} alignItems={'center'}>
								{/* Active switch */}
								<FormControlLabel
									control={
										<Switch
											checked={item.active || false}
											onChange={(e) =>
												handleToggleActive(index, e.target.checked)
											}
											name='active'
											color='otherButton'
										/>
									}
									label={item.active ? 'On' : 'Off'}
								/>

								{/* script title */}
								<TextField
									fullWidth
									disabled
									id='scriptTitle'
									label='Script Title'
									variant='outlined'
									margin='dense'
									value={item?.scriptTitle || ''}
								/>

								{/* description */}
								<TextField
									fullWidth
									disabled
									id='description'
									label='Description'
									variant='outlined'
									margin='dense'
									value={item?.description || ''}
								/>

								{/* last run success or failure */}
								<TextField
									fullWidth
									label='Last Run Time'
									variant='outlined'
									InputProps={{
										startAdornment: item.timestamp && (
											<Tooltip
												text={`${item.status ? '' : item.result?.error?.details?.[0]?.errorMessage}`}
											>
												<Chip
													icon={
														item.status ? <CheckCircleIcon /> : <CancelIcon />
													}
													label={item.status ? 'Success' : 'Failure'}
													color={item.status ? 'success' : 'error'}
													variant='outlined'
												/>
											</Tooltip>
										),
									}}
									value={
										item.timestamp
											? moment(item.timestamp.toDate()).format(
													'  MMM DD, YYYY h:mm A'
											  )
											: ''
									}
									// Make the TextField read-only if it shouldn't be editable
									InputLabelProps={{
										shrink: true, // This ensures the label is positioned correctly with an adornment
									}}
									disabled // This makes the TextField not editable, acting as a display
								/>

								{/* next run time*/}
								<TextField
									fullWidth
									id='nextRunTime'
									label='Next Run Time'
									variant='outlined'
									margin='dense'
									value={
										item.active
											? item?.nextRunTime
												? moment(item?.nextRunTime?.toDate()).format(
														'MMM DD, YYYY h:mm A'
												  )
												: ''
											: ''
									}
									InputLabelProps={{
										shrink: true,
									}}
									disabled
								/>

								{/* edit button */}
								<Button
									onClick={() => handleRowSelect(index)}
									variant='contained'
								>
									Edit
								</Button>
							</Stack>
						</Stack>
					);
				})}
			</Stack>
			{/* Add Row Button */}
			<Container sx={{ display: 'flex', justifyContent: 'center' }}>
				<Button
					color='secondary' // Or any other color
					variant='outlined'
					onClick={handleAddRow}
					sx={{ mt: 2 }} // Adjust spacing as needed
				>
					Add Row
				</Button>
			</Container>

			{/* pop up dialog box */}
			<Dialog
				open={isPopupOpen}
				onClose={handlePopupClose}
				PaperProps={{
					style: {
						maxWidth: '90%', // Sets the maximum width of the dialog
						width: 'fit-content', // Sets the width of the dialog to fit its content, up to the maxWidth
						height: 'auto', // Adjust height based on content, you can also set a specific height if needed
					},
				}}
			>
				<DialogTitle align='center'>Edit Apps Script Details</DialogTitle>
				<DialogContent>
					<Stack>
						{/* script title */}
						<TextField
							fullWidth
							disabled={!claimsCtx?.claims?.admin || saving}
							id='scriptTitle'
							label='Script Title'
							variant='outlined'
							margin='dense'
							value={
								data && selectedRow !== null
									? data[selectedRow].scriptTitle || ''
									: ''
							}
							onChange={(e) => {
								handleChange(e.target.value, selectedRow, 'scriptTitle');
							}}
						/>

						{/* description */}
						<TextField
							fullWidth
							disabled={!claimsCtx?.claims?.admin || saving}
							id='description'
							label='Description'
							variant='outlined'
							margin='dense'
							value={
								data && selectedRow !== null
									? data[selectedRow].description || ''
									: ''
							}
							onChange={(e) => {
								handleChange(e.target.value, selectedRow, 'description');
							}}
						/>

						{/* functionName */}
						<TextField
							disabled={!claimsCtx?.claims?.admin || saving}
							id='functionName'
							label='Function Name'
							variant='outlined'
							margin='dense'
							value={
								data && selectedRow !== null
									? data[selectedRow].functionName || ''
									: ''
							}
							onChange={(e) => {
								handleChange(e.target.value, selectedRow, 'functionName');
							}}
							sx={{ width: '70rem' }}
						/>

						{/* scriptId */}
						<TextField
							disabled={!claimsCtx?.claims?.admin || saving}
							id='scriptId'
							label='Script Id'
							variant='outlined'
							margin='dense'
							value={
								data && selectedRow !== null
									? data[selectedRow].scriptId || ''
									: ''
							}
							onChange={(e) => {
								handleChange(e.target.value, selectedRow, 'scriptId');
							}}
						/>

						{/* timer type */}
						<FormControl fullWidth margin='dense'>
							<InputLabel>Timer Type</InputLabel>
							<Select
								value={
									data && selectedRow !== null
										? data[selectedRow].timerType || ''
										: ''
								}
								onChange={(e) =>
									handleChange(e.target.value, selectedRow, 'timerType')
								}
								label='Timer Type'
							>
								{timerTypeOptions.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						{/* minutes interval */}
						{data &&
							selectedRow !== null &&
							data[selectedRow].timerType === 'minutes' && (
								<FormControl fullWidth margin='normal'>
									<InputLabel>Minutes Interval</InputLabel>
									<Select
										value={
											data && selectedRow !== null
												? data[selectedRow].minutesInterval || ''
												: ''
										}
										onChange={(e) =>
											handleChange(
												e.target.value,
												selectedRow,
												'minutesInterval'
											)
										}
										label='Minutes Interval'
										disabled={!claimsCtx?.claims?.admin || saving}
									>
										{minutesOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}

						{/* Week day selection */}
						{data &&
							selectedRow !== null &&
							data[selectedRow].timerType === 'week' && (
								<FormControl fullWidth margin='normal'>
									<InputLabel>Day of the Week</InputLabel>
									<Select
										value={
											data &&
											selectedRow !== null &&
											data[selectedRow].weekDay !== undefined
												? data[selectedRow].weekDay
												: ''
										}
										onChange={(e) =>
											handleChange(e.target.value, selectedRow, 'weekDay')
										}
										label='Day of the Week'
									>
										{weekOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}

						{/* Day of Month selection */}
						{data &&
							selectedRow !== null &&
							data[selectedRow].timerType === 'month' && (
								<FormControl fullWidth margin='normal'>
									<InputLabel>Day of Month</InputLabel>
									<Select
										value={data[selectedRow].dayOfMonth || ''}
										onChange={(e) =>
											handleChange(e.target.value, selectedRow, 'dayOfMonth')
										}
										label='Day of Month'
									>
										{monthDayOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}

						{/* hour of day */}
						{data &&
							selectedRow !== null &&
							(data[selectedRow].timerType === 'day' ||
								data[selectedRow].timerType === 'week' ||
								data[selectedRow].timerType === 'month') && (
								<FormControl fullWidth margin='normal'>
									<InputLabel>Hour of Day</InputLabel>
									<Select
										value={
											data && selectedRow !== null
												? data[selectedRow].hourOfDay || ''
												: ''
										}
										onChange={(e) =>
											handleChange(e.target.value, selectedRow, 'hourOfDay')
										}
										label='Hour of Day'
										disabled={!claimsCtx?.claims?.admin || saving}
									>
										{dayOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}

						{/* hours interval */}
						{data &&
							selectedRow !== null &&
							data[selectedRow].timerType === 'hour' && (
								<FormControl fullWidth margin='normal'>
									<InputLabel>Hour Interval</InputLabel>
									<Select
										value={
											data && selectedRow !== null
												? data[selectedRow].hourInterval || ''
												: ''
										}
										onChange={(e) =>
											handleChange(e.target.value, selectedRow, 'hourInterval')
										}
										label='Hour Interval'
										disabled={!claimsCtx?.claims?.admin || saving}
									>
										{hourOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Box
						sx={{
							flexGrow: 1,
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						{/* Left-aligned part */}
						<Button
							color='error'
							variant='contained'
							onClick={() =>
								handleRemoval(
									data && selectedRow !== null ? data[selectedRow].id : ''
								)
							}
							disabled={
								saving ||
								!claimsCtx?.claims?.admin ||
								(data && selectedRow !== null && !data?.[selectedRow].id)
							}
							sx={{ margin: '1rem' }}
						>
							Delete
						</Button>

						{/* Right-aligned part */}
						<Box>
							<Button
								onClick={handlePopupClose}
								variant='contained'
								color='cancel'
								sx={{ margin: '1rem' }}
							>
								Cancel
							</Button>
							<Button
								onClick={handlePopupSave}
								variant='contained'
								sx={{ margin: '1rem' }}
							>
								Save
							</Button>
						</Box>
					</Box>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}
