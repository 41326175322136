/**
 * formats a string of html to be displayed correctly in Gmail, only needed for content that will ultimately be sent to an EPT Gmail account
 * @param {string} htmlContent - the content to be passed to SendGrid
 * @returns - a string that is formatted for Gmail
 */
export default function formatHTML(htmlContent) {
	// Create a temporary DOM element to manipulate the HTML
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = htmlContent;

	// Select all ordered lists
	const orderedLists = tempDiv.querySelectorAll('ol');

	orderedLists.forEach((ol) => {
		// Iterate over each list item in the ordered list
		ol.querySelectorAll('li').forEach((li) => {
			// Check if there's already a <br> inside
			if (li.innerHTML.includes('<br>') && !li.innerHTML.includes('<br><br>')) {
				// Replace single <br> with double <br><br>
				li.innerHTML = li.innerHTML.replace(/<br>/g, '<br><br>');
			}
		});
	});

	// Add margin-bottom to all <p> tags to ensure space between paragraphs
	tempDiv.querySelectorAll('p').forEach((p) => {
		p.style.marginBottom = '10px'; // Adjust the value as needed for spacing
	});

	// Return the adjusted HTML as a string
	return tempDiv.innerHTML;
}
