import React, { useMemo, useEffect, useState, useContext } from 'react';

import {MaterialReactTable} from 'material-react-table';
import { Box, Typography, Stack, Select, MenuItem } from '@mui/material';

import moment from 'moment';
import * as d3 from 'd3';

import Tooltip from '../ui/Tooltip';
import LoadingSpinner from '../ui/LoadingSpinner';
import Dashboard_Query from './Dashboard_Query';
import AlertContext from '../ui/AlertContext';

export default function Chart_Table({ chart, dashboard, onRendered }) {
	const alertCtx = useContext(AlertContext);
	const [loading, setLoading] = useState(null);
	const [data, setData] = useState(null);
	const [groupedData, setGroupedData] = useState(null);
	const [reportDates, setReportDates] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);

	/**
	 * gets the data for the dashboard
	 */
	useEffect(() => {
		setLoading(true);

		const awaitQuery = async () => {
			setData(null);
			setGroupedData(null);
			setReportDates(null);
			setSelectedDate(null);

			// console.log('dashboard: ', dashboard);
			// console.log('chart: ', chart);
			const response = await Dashboard_Query(dashboard, chart);
			// console.log('response: ', response);

			/**
			 * if data is empty, then display alert
			 * also stop loading
			 */
			if (response.error) {
				if (response.error === 'Firebase Composite Index')
					alertCtx.setMessage(`${response.message} <a href='${response.url}' target='_blank'>Link</a>`);
				else alertCtx.setMessage(response.message);
				alertCtx.setSeverity('error');
				alertCtx.setTitle('Data Query');
				alertCtx.setActive(true);
				setLoading(false);
				return;
			} else if (!response.data) {
				console.log('No Data Returned');
				alertCtx.setMessage('No data returned.');
				alertCtx.setSeverity('warning');
				alertCtx.setTitle('Data Query');
				alertCtx.setActive(true);
				setLoading(false);
				return;
			} else if (response.data) {
				setData(response.data);
				// console.log('data', response.data);
			}
		};
		awaitQuery();
	}, [chart, dashboard]);

	/**
	 * processes the dates based on the table settings
	 */
	useEffect(() => {
		if (data) {
			// console.log('data: ', data);

			//gets the dates for the menu
			const dateItems = [];
			data.forEach((item) => {
				dateItems.push({ date: item['Report Date'] });
			});

			const datesData = Array.from(
				d3.group(dateItems, (d) => d.date),
				([date]) => ({ date })
			);
			// console.log('datesData: ', datesData);

			/**
			 * display only the Most Recent date
			 */
			if (chart.displayDateRange === 'Most Recent') {
				// Convert the string dates to Date objects for comparison
				const dateObjects = datesData.map((dateItem) => new Date(dateItem.date));

				// Find the latest date
				const latestDate = new Date(Math.max.apply(null, dateObjects));

				// Set selectedDate to the latest date
				setSelectedDate(latestDate.toISOString());
			}

			/**
			 * display All Available Dates
			 */
			if (chart.displayDateRange === 'All Available') {
				setReportDates(datesData);

				// Convert the string dates to Date objects for comparison
				const dateObjects = datesData.map((dateItem) => new Date(dateItem.date));

				// Find the latest date
				const latestDate = new Date(Math.max.apply(null, dateObjects));

				// Set selectedDate to the latest date
				setSelectedDate(latestDate.toISOString());
			}
		}
	}, [data]);

	/**
	 * groups data by
	 */
	useEffect(() => {
		if (selectedDate) {
			// Define an array to hold key functions
			const keyFunctions = [];

			// Conditionally add key functions based on chart settings
			if (chart.tableGroupDataByFirst) {
				keyFunctions.push((d) => d[chart.tableGroupDataByFirst]);
			}
			if (chart.tableGroupDataBySecond) {
				keyFunctions.push((d) => d[chart.tableGroupDataBySecond]);
			}

			// Use d3.rollup with dynamic key functions
			const tempGroupedData = Array.from(
				d3.rollup(
					data,
					(D) => D.length,
					...keyFunctions // Spread the array into arguments
				),
				([key, value]) => ({ key, value })
			);

			/**
			 * counts how many times the item was over the threshold
			 */
			chart.tableSummaryColumnThreshold &&
				tempGroupedData.map((item) => {
					Array.from(item.value, ([key, value]) => ({ key, value })).map((subItem) => {
						if (subItem.value > chart.tableThreshold) {
							item.count ? (item.count += 1) : (item.count = 1);
						}
					});
				});

			// console.log('tempGroupedData:', tempGroupedData);

			//removes people with no reports for selectedDate
			const filteredData = d3.filter(tempGroupedData, (x) => x.value.get(new Date(selectedDate)) > 0);
			// console.log('filteredData', filteredData);
			setGroupedData(filteredData);
			setLoading(false);
		}
	}, [selectedDate]);

	/**
	 * sends feedback if the component is rendered
	 * used for Dashboard_SendEmail component
	 */
	useEffect(() => {
		if (onRendered && loading === false) {
			onRendered(false); // Pass the loading state back
		}
	}, [loading]);

	const columnsWithSummary = useMemo(
		() => [
			{
				accessorKey: 'key',
				header: chart.tableGroupDataByFirst,
			},
			{
				accessorFn: (row) => row.value.get(new Date(selectedDate)),
				header: chart.title,
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
				Cell: ({ cell }) => {
					return (
						<div
							style={{
								color: Number.isNaN(chart.tableThreshold)
									? 'black'
									: cell.getValue() > chart.tableThreshold
									? 'red'
									: 'black',
							}}
						>
							{cell.getValue()}
						</div>
					);
				},
			},
			{
				accessorFn: (row) => row.count,
				header: (
					<Stack direction='row' alignItems='center'>
						{chart.tableSummaryColumnTitle}
						{chart.tableSummaryColumnTooltip && chart.tableSummaryColumnTooltip.length > 0 && (
							<Tooltip text={chart.tableSummaryColumnTooltip} />
						)}
					</Stack>
				),
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
				Cell: ({ cell }) => {
					return (
						<div
							style={{
								color: Number.isNaN(chart.tableSummaryColumnThreshold)
									? 'black'
									: cell.getValue() > chart.tableSummaryColumnThreshold
									? 'red'
									: 'black',
							}}
						>
							{cell.getValue()}
						</div>
					);
				},
			},
		],
		[selectedDate]
	);

	const columnsWithoutSummary = useMemo(
		() => [
			{
				accessorKey: 'key',
				header: chart.tableGroupDataByFirst,
			},
			{
				accessorFn: (row) => row.value.get(new Date(selectedDate)),
				header: chart.title,
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
				Cell: ({ cell }) => {
					return (
						<div style={{ color: cell.getValue() > chart.tableThreshold || false ? 'red' : 'black' }}>
							{cell.getValue()}
						</div>
					);
				},
			},
		],
		[selectedDate]
	);

	if (loading) return <LoadingSpinner />;

	return (
		<>
			{groupedData && (
				<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<Stack>
						<Typography variant='h6' align='center' mb={1}>
							{chart.dashboardName}
						</Typography>

						{/* don't display if there are multiple dates (reportDate) */}
						{!reportDates && (
							<Typography variant='h7' align='center' mb={1}>
								{moment(selectedDate).format('MMMM D, YYYY')}
							</Typography>
						)}

						{/* display if there are multiple dates (reportDate) */}
						{selectedDate && reportDates && (
							<Box sx={{ marginBottom: 2 }} display='flex' justifyContent='center'>
								<Select
									labelId='select-report-date-label'
									id='select-report-date'
									value={selectedDate}
									onChange={(e) => setSelectedDate(e.target.value)}
								>
									{reportDates.map((item, index) => (
										<MenuItem key={index} value={new Date(item.date).toISOString()}>
											{moment(item.date).format('MMMM D, YYYY')}
										</MenuItem>
									))}
								</Select>
							</Box>
						)}

						<MaterialReactTable
							muiTablePaperProps={{
								elevation: 0, //change the mui box shadow
								sx: {
									'& tr:nth-of-type(even)': {
										backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
									},
								},
							}}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									muiTableHeadCellProps: {
										align: 'center',
									},
									size: 200,
								},
							}}
							columns={chart.tableSummaryColumn || false ? columnsWithSummary : columnsWithoutSummary}
							data={groupedData}
							enablePagination={false}
							initialState={{
								density: 'compact',
								expanded: true, //expand all groups by default
								sorting: [{ id: 'key', asc: true }], //sort by group by default
							}}
						/>
					</Stack>
				</Box>
			)}
		</>
	);
}
