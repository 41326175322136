import React, { Fragment, useState, useContext } from 'react';

import { Button, Box } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import * as Excel from 'exceljs/dist/exceljs.min.js';

import Tooltip from '../ui/Tooltip';
import DashboardModal_Preview from './DashboardModal_Preview';
import LoadingSpinner from '../ui/LoadingSpinner';
import AlertContext from '../ui/AlertContext';

export default function DashboardSettings_UploadHandler(props) {
	const alertCtx = useContext(AlertContext);
	const [displayPreviewModal, setDisplayPreviewModal] = useState(false);
	const [sheets, setSheets] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [dashboard, setDashboard] = useState(props.dashboard);
	const [dataTypes, setDataTypes] = useState(props.dataTypes);

	//cancels the modals
	const cancelModal = () => {
		setDisplayPreviewModal(false);
		props.refreshDashboard(props.dashboard.id);
	};

	//handles upload
	const uploadHandler = async (e) => {
		setUploading(true);
		if (!e.target.files) {
			setUploading(false);
			return;
		}

		// console.log(dashboard);

		const file = e.target.files[0];
		// console.log(file);
		const workbook = new Excel.Workbook();

		var fileReader = new FileReader();
		fileReader.onload = async (e) => {
			const buffer = e.target.result;
			let noError = true;
			// console.log(buffer);

			await workbook.xlsx
				.load(buffer)
				.then(async (workbook) => {
					const tempSheets = [];
					//for each worksheet (ws)
					workbook.eachSheet(async function (worksheet, sheetId) {
						const ws = workbook.getWorksheet(worksheet.name);
						// console.log(ws.name);

						//gets the sheet headers
						const headers = [];
						const sheetColumns = [];
						ws.getRow(1)
							.values.slice(1)
							.forEach((item, i) => {
								sheetColumns.push({ id: i, name: item.trim() });
							});

						let expectedSheetName = '';
						let expectedSheetIndex = '';
						//for each expectedSheet, see if it matches the ws
						let expectedSheetNotFound = true;
						let calculatedColumns = [];
						dashboard.expectedSheets.forEach((expectedSheet, sheetIndex) => {
							//if ws name matches the expectedSheet name
							if (ws.name === expectedSheet.name) {
								calculatedColumns = expectedSheet?.calculatedColumns || null;
								expectedSheetNotFound = false;
								expectedSheetName = expectedSheet.name;
								expectedSheetIndex = sheetIndex;

								//then go through the sheetColumns and see if they match the expectedFields of the expectedSheet, if they match then assign expectedField variables to the headers
								sheetColumns.forEach((sheetColumn) => {
									let expectedFieldNotFound = true;
									dashboard.expectedSheets[sheetIndex].expectedFields.forEach((field) => {
										if (sheetColumn.name === field.name) {
											expectedFieldNotFound = false;
											headers.push({
												id: headers.length,
												name: field.name,
												sheetColumn: sheetColumn.name,
												type: field.type,
												phi: field.phi,
												phiKey: field.phiKey,
												include: true,
											});
										}
									});

									//if it has not matched an expectedField with a sheetColumn, add the expectedField to the headers
									if (expectedFieldNotFound) {
										headers.push({
											id: headers.length,
											name: '',
											sheetColumn: sheetColumn.name,
											type: '',
											phi: '',
											phiKey: '',
											include: false,
										});
									}
								});
							}
						});

						//if ws name does not match an expectedSheet name display error and stop function
						if (expectedSheetNotFound) {
							noError = false;
							alertCtx.setSeverity('error');
							alertCtx.setMessage(
								`In the spreadsheet that was uploaded, <strong>${ws.name}</strong> is the name of a sheet.  The sheet <strong>${ws.name}</strong> does not match any of the expected sheets.  Rename this sheet in the file being uploaded and then try again.`
							);
							alertCtx.setActive(true);
						}
						// console.log('headers', headers);

						//gets the sheet data
						const sheetData = [];
						await ws.eachRow(function (row, rowNumber) {
							/**
							 * 	removes the first item using slice, as the first item from excelJS is always empty to account of 0 order computations
							 * only extends through the length of the sheetColumns so if there is extra data on the sheet (without header) then it will not be included
							 * trims strings of whitespace
							 */
							const tempRowData = [];
							for (const item of row.values.slice(1, sheetColumns.length + 1)) {
								if (typeof item === 'string') {
									tempRowData.push(item.trim());
								} else tempRowData.push(item);
							}
							sheetData.push(tempRowData);
						});
						tempSheets.push({
							sheetName: worksheet.name,
							data: sheetData,
							headers: headers,
							include: true,
							expectedSheetName: expectedSheetName,
							expectedSheetIndex: expectedSheetIndex,
							calculatedColumns: calculatedColumns,
						});
					});

					// console.log(tempSheets);
					setSheets(tempSheets);
				})
				.catch((error) => {
					// console.log('readFile fail', error);

					alertCtx.setSeverity('error');
					alertCtx.setMessage(`readFile fail: ${error}`);
					alertCtx.setActive(true);

					setUploading(false);
				});
			if (noError) {
				setDisplayPreviewModal(true);
				setUploading(false);
			} else {
				setDisplayPreviewModal(null);
				setUploading(false);
			}
		};

		fileReader.readAsArrayBuffer(file);
	};

	return (
		<Fragment>
			{/* displays the modal for each dashboards settings */}
			{displayPreviewModal && (
				<DashboardModal_Preview
					cancel={cancelModal}
					open={displayPreviewModal}
					sheets={sheets}
					dashboard={dashboard}
					dataTypes={dataTypes}
				/>
			)}

			{/* upload button */}
			<Box mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
				{uploading !== true && (
					<Tooltip text={`Import the newest file for ${dashboard.name}`} placement='right'>
						<Button
							variant='contained'
							component='label'
							disabled={props.disabled}
							startIcon={<FileUploadIcon />}
							color='otherButton'
						>
							UPLOAD DATA FILE
							<input type='file' hidden onChange={uploadHandler} />
						</Button>
					</Tooltip>
				)}
				{uploading === true && <LoadingSpinner />}
			</Box>
		</Fragment>
	);
}
