import React, { useEffect } from 'react';

import { Box, Stepper, Step, StepButton, Button, Typography } from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import LoadingSpinner from './LoadingSpinner';
import { theme } from '../../App';

/**
 *  Add to parent file:
 *
 * 	const [activeStep, setActiveStep] = useState(null);
 *
 * 	const handleActiveStepChange = (step) => {
 * 		setActiveStep(step);
 * 	};
 *
 * Step each step component relative to the steps using 0 based counting; such as {activeStep === 0 && ...
 *
 * @param {array} steps - an array of strings naming the steps
 * @param {*} handleActiveStepChange - function in parent file
 * @param {boolean} saving - if the file is saving
 * @param {string} saveLocation - which Firebase Firestore file to save the edits to
 * @param {function} saveFunction - the function that saves the file
 * @param {boolean} equal - if the original item and the new item are equal or not
 * @returns a horizontal non-linear stepper
 */
export default function StepperUI({
	steps,
	children,
	handleActiveStepChange,
	equal,
	saveFunction,
	saveLocation,
	saving,
}) {
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});

	/**
	 *
	 * @returns activeStep to the parent component
	 */
	useEffect(() => {
		handleActiveStepChange(activeStep);
	}, [activeStep]);

	const totalSteps = () => {
		return steps.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? // It's the last step, but not all steps have been completed,
				  // find the first step that has been completed
				  steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	return (
		<Box
			p={1}
			sx={{
				width: '100%',
				minHeight: '50vh',
				backgroundColor: 'white',
				borderRadius: '8px',
				border: '1px solid lightgrey',
			}}
		>
			<Stepper alternativeLabel nonLinear activeStep={activeStep}>
				{steps.map((label, index) => (
					<Step key={label} completed={completed[index]}>
						<StepButton color='inherit' onClick={handleStep(index)}>
							<Typography
								fontSize={'inherit'}
								fontWeight={activeStep === index ? 'bold' : 'inherit'}
								color={activeStep === index ? 'inherit' : 'gray'}
							>
								{label}
							</Typography>
						</StepButton>
					</Step>
				))}
			</Stepper>
			<Box p={1}>
				<Box mb={4} sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
					<Button variant='outlined' color='info' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
						Back
					</Button>
					<Box sx={{ flex: '1 1 auto' }} />
					{/* Save Button */}
					{saving ? (
						<LoadingSpinner />
					) : (
						<Button
							disabled={equal || saving}
							size='small'
							variant='contained'
							color={'info'}
							startIcon={<SaveRoundedIcon />}
							onClick={() => {
								saveFunction(saveLocation);
							}}
						>
							SAVE EDITS
						</Button>
					)}
					<Box sx={{ flex: '1 1 auto' }} />
					<Button
						variant='outlined'
						color='info'
						onClick={handleNext}
						disabled={activeStep === steps.length - 1}
						sx={{ mr: 1 }}
					>
						Next
					</Button>
				</Box>
				{children}
			</Box>
		</Box>
	);
}
