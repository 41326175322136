import React, { Fragment, useEffect, useRef, useState, useContext } from 'react';
import { addDoc, doc, deleteDoc, getDoc, collection, getDocs, updateDoc, writeBatch } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Box,
	Checkbox,
	Chip,
	Collapse,
	Container,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	Stack,
	Typography,
	TextField,
	MenuItem,
	Select,
	InputLabel,
	Radio,
	TableContainer,
	Paper,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Table,
	ToggleButtonGroup,
	ToggleButton,
} from '@mui/material';

import { db } from '../../App';
import LoadingSpinner from '../ui/LoadingSpinner';
import Tooltip from '../ui/Tooltip';
import AreArraysEqual from '../dashboard/AreArraysEqual';
import AlertContext from '../ui/AlertContext';
import AuthContext from '../auth/authContext';

function Row(props) {
	const [open, setOpen] = useState(false);
	const {
		item,
		index,
		handleOrderChange,
		handleFormChange,
		updatingData,
		handleDeleteRow,
		data,
		originalData,
		handleAddDropDownChoice,
		handleDeleteDropDownChoice,
		authCtx,
	} = props;

	if (item) {
		return (
			<React.Fragment>
				{/* header row */}
				<TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: index % 2 !== 0 ? '#f9f9f9' : '' }}>
					<TableCell>
						<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell align='center'>
						<ToggleButtonGroup orientation='vertical'>
							<ToggleButton
								aria-label='up'
								value='up'
								onClick={() => {
									if (item.Order !== 0) handleOrderChange(item.Order - 1, index, 'Order', index - 1, item.Order);
								}}
							>
								<ArrowUpwardRoundedIcon fontSize='small' />
							</ToggleButton>
							<ToggleButton
								aria-label='down'
								value='up'
								onClick={() => {
									if (item.Order !== data.length - 1)
										handleOrderChange(item.Order + 1, index, 'Order', index + 1, item.Order);
								}}
							>
								<ArrowDownwardRoundedIcon fontSize='small' />
							</ToggleButton>
						</ToggleButtonGroup>
					</TableCell>
					<TableCell align='center'>
						<TextField
							size='small'
							margin='dense'
							id={`Label-${item.ID}`}
							placeholder='Label'
							hiddenLabel
							variant='filled'
							color='secondary'
							value={item.Label}
							onChange={(e) => {
								handleFormChange(e.target.value, index, 'Label');
							}}
							disabled={updatingData}
						/>
					</TableCell>
					<TableCell align='center'>
						<Checkbox
							size='small'
							checked={item.DisplayOnDirectory}
							onChange={(e) => {
								handleFormChange(!item.DisplayOnDirectory, index, 'DisplayOnDirectory');
							}}
							inputProps={{ 'aria-label': 'controlled' }}
							disabled={updatingData}
						/>
					</TableCell>
					<TableCell align='center'>
						<Checkbox
							size='small'
							checked={item.EditableOnDirectory}
							onChange={(e) => {
								handleFormChange(!item.EditableOnDirectory, index, 'EditableOnDirectory');
							}}
							inputProps={{ 'aria-label': 'controlled' }}
							disabled={updatingData}
						/>
					</TableCell>
					<TableCell align='center'>
						<Checkbox
							size='small'
							checked={item.DisplayOnMyInfo}
							onChange={(e) => {
								handleFormChange(!item.DisplayOnMyInfo, index, 'DisplayOnMyInfo');
							}}
							inputProps={{ 'aria-label': 'controlled' }}
							disabled={updatingData}
						/>
					</TableCell>
					<TableCell align='center'>
						<Checkbox
							size='small'
							checked={item.EditableOnMyInfo}
							onChange={(e) => {
								handleFormChange(!item.EditableOnMyInfo, index, 'EditableOnMyInfo');
							}}
							inputProps={{ 'aria-label': 'controlled' }}
							disabled={updatingData}
						/>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
						<Collapse in={open} timeout='auto' unmountOnExit>
							<Box sx={{ margin: 0 }}>
								{/* top subtable */}
								<Table>
									<TableHead sx={{ backgroundColor: '#f1f1f1' }}>
										<TableRow>
											<TableCell align='center'>Schema</TableCell>
											<TableCell align='center'>Field Name</TableCell>
											{authCtx.isSuperAdmin && <TableCell align='center'>Sub-Value</TableCell>}
											{authCtx.isSuperAdmin && <TableCell align='center'>Array</TableCell>}
											{authCtx.isSuperAdmin && <TableCell align='center'>Type</TableCell>}
										</TableRow>
									</TableHead>
									<TableBody sx={{ backgroundColor: '#ffffff' }}>
										<TableRow sx={{ verticalAlign: 'top' }}>
											{/* custom schema */}
											<TableCell align='center'>
												<FormControl fullWidth margin='dense'>
													<InputLabel id={`Schema_${item.ID}`}>Schema</InputLabel>
													<Select
														disabled
														color='secondary'
														labelId='inputType'
														id='inputType'
														value={item.CustomSchema || ''}
														label='Schema'
														onChange={(e) => {
															handleFormChange(e.target.value, index, 'CustomSchema');
														}}
													>
														<MenuItem value={'EPT_Details'}>EPT Details</MenuItem>
														<MenuItem value={'PHI_Keys'}>PHI Keys</MenuItem>
													</Select>
												</FormControl>
											</TableCell>

											{/* field name */}
											<TableCell align='center'>
												<TextField
													size='small'
													margin='dense'
													id={`Field_Name_${item.ID}`}
													placeholder='Field Name'
													hiddenLabel
													variant='filled'
													color='secondary'
													value={item.Value || ''}
													onChange={(e) => {
														handleFormChange(e.target.value, index, 'Value');
													}}
													disabled
												/>
											</TableCell>

											{/* subValue */}
											{authCtx.isSuperAdmin && (
												<TableCell align='center'>
													<TextField
														size='small'
														margin='dense'
														id={`SubValue-${item.ID}`}
														placeholder='Sub-Value'
														hiddenLabel
														variant='filled'
														color='secondary'
														value={item.SubValue || ''}
														onChange={(e) => {
															handleFormChange(e.target.value, index, 'SubValue');
														}}
														disabled={updatingData || !item.Value}
													/>
												</TableCell>
											)}

											{/* Array */}
											{authCtx.isSuperAdmin && (
												<TableCell align='center'>
													<Checkbox
														size='small'
														checked={item.Array}
														onChange={() => {
															handleFormChange(!item.Array, index, 'Array');
														}}
														inputProps={{ 'aria-label': 'controlled' }}
														disabled={updatingData}
													/>
												</TableCell>
											)}

											{/* Type */}
											{authCtx.isSuperAdmin && (
												<TableCell align='center'>
													<TextField
														size='small'
														margin='dense'
														id={`Type-${item.ID}`}
														placeholder='Type'
														hiddenLabel
														variant='filled'
														color='secondary'
														value={item.Type || ''}
														onChange={(e) => {
															handleFormChange(e.target.value, index, 'Type');
														}}
														disabled={updatingData || !item.Array}
													/>
												</TableCell>
											)}
										</TableRow>
									</TableBody>
								</Table>

								{/* bottom subtable */}
								<Table>
									<TableHead sx={{ backgroundColor: '#f1f1f1' }}>
										<TableRow>
											<TableCell align='center'>Input Type</TableCell>
											<TableCell align='center'>
												<Stack
													direction='row'
													sx={{
														display: 'flex',
														justifyContent: 'center',
													}}
												>
													Selection Choices
													<AddCircleOutlineRoundedIcon
														color='info'
														onClick={() => {
															handleAddDropDownChoice(index, 'DropDownChoices');
														}}
													/>
												</Stack>
											</TableCell>

											{authCtx.isSuperAdmin && <TableCell align='center'>ID</TableCell>}
											{authCtx.isSuperAdmin && (
												<TableCell align='center'>
													<Stack
														direction='row'
														sx={{
															display: 'flex',
															justifyContent: 'center',
														}}
													>
														Delete Row
														<Tooltip text='Delete this item.  This cannot be undone.' placement='bottom' />
													</Stack>
												</TableCell>
											)}
										</TableRow>
									</TableHead>
									<TableBody sx={{ backgroundColor: '#ffffff' }}>
										<TableRow sx={{ verticalAlign: 'top' }}>
											{/* input type */}
											<TableCell align='center'>
												<FormControl fullWidth margin='dense'>
													<InputLabel id='inputType'>Input Type</InputLabel>
													<Select
														color='secondary'
														labelId='inputType'
														id='inputType'
														value={item.InputType || ''}
														label='Input Type'
														onChange={(e) => {
															handleFormChange(e.target.value, index, 'InputType');

															let FieldType = e.target.value;
															if (FieldType === 'SELECTION') FieldType = 'STRING';
															handleFormChange(FieldType, index, 'FieldType');
														}}
													>
														<MenuItem value={'BOOL'}>Boolean</MenuItem>
														<MenuItem value={'DATE'}>Date</MenuItem>
														<MenuItem value={'SELECTION'}>Selection</MenuItem>
														<MenuItem value={'PHONE'}>Phone Number</MenuItem>
														<MenuItem value={'STRING'}>Text</MenuItem>
													</Select>
												</FormControl>
											</TableCell>

											{/* selection choices */}
											<TableCell align='center'>
												<Stack>
													{item.DropDownChoices && item.DropDownChoices.length > 0 ? (
														item.DropDownChoices.map((choice, choiceIndex) => (
															<Stack
																key={choiceIndex}
																direction='row'
																sx={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																}}
															>
																<TextField
																	size='small'
																	margin='dense'
																	id={`dropDownChoice-${item.ID}-${choiceIndex}`}
																	placeholder='Selection Choice'
																	hiddenLabel
																	disabled={item.InputType !== 'SELECTION'}
																	variant='filled'
																	color='secondary'
																	value={choice || ''}
																	onChange={(e) => {
																		handleFormChange(e.target.value, index, 'DropDownChoices', choiceIndex);
																	}}
																/>
																<RemoveCircleOutlineIcon
																	color='error'
																	onClick={() => {
																		handleDeleteDropDownChoice(index, 'DropDownChoices', choiceIndex);
																	}}
																/>
															</Stack>
														))
													) : (
														<TextField
															key={0}
															size='small'
															margin='dense'
															id={`dropDownChoice-${item.ID}-0`}
															placeholder='Drop Down Choice'
															hiddenLabel
															disabled={item.InputType !== 'Drop Down'}
															variant='filled'
															color='secondary'
															value={''}
															onChange={(e) => {
																handleFormChange(e.target.value, index, 'DropDownChoices', 0);
															}}
														/>
													)}
												</Stack>
											</TableCell>

											{/* ID */}
											{authCtx.isSuperAdmin && (
												<TableCell align='center'>
													<TextField
														size='small'
														margin='dense'
														id={`ID-${item.ID}`}
														placeholder='ID'
														hiddenLabel
														disabled
														variant='filled'
														color='secondary'
														value={item.ID || ''}
													/>
												</TableCell>
											)}

											{/* Delete button */}
											{authCtx.isSuperAdmin && (
												<TableCell align='center'>
													<IconButton
														aria-label='delete'
														color='error'
														onClick={() => {
															handleDeleteRow(item);
														}}
													>
														<DeleteIcon />
													</IconButton>
												</TableCell>
											)}
										</TableRow>
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			</React.Fragment>
		);
	}
}

function BuildSchema(item) {
	// console.log(item)
	return {
		fieldType: item.FieldType || 'STRING',
		fieldName: item.Value,
		displayName: item.Label,
		multiValued: item.Array,
		readAccessType: item.DisplayOnDirectory ? 'ALL_DOMAIN_USERS' : 'ADMINS_AND_SELF',
	};
}

export default function DirectorySettings() {
	const authCtx = useContext(AuthContext);
	const alertCtx = useContext(AlertContext);
	const [loading, setLoading] = useState(false);
	const [updatingData, setUpdatingData] = useState(false);
	const [data, setData] = useState(null);
	const [originalData, setOriginalData] = useState(null);
	const [same, setSame] = useState(null);
	const tableRef = useRef(null);
	const [modalOpen, setModalOpen] = useState(null);
	const [newField, setNewField] = useState({});

	// useEffect(() => {
	// 	if (data && originalData) {
	// 		console.log(data);
	// 		// console.log(originalData);
	// 	}
	// }, [data]);

	//sets data
	useEffect(() => {
		async function getData() {
			setLoading(true);
			const tempData = [];
			const querySnapshot = await getDocs(collection(db, 'DirectorySettings'));
			querySnapshot.forEach((doc) => {
				tempData.push({ ...doc.data(), ID: doc.id });
			});
			const sortedData = [...tempData].sort((a, b) => a.Order - b.Order);
			setData(sortedData);
			setOriginalData(JSON.parse(JSON.stringify(sortedData)));
		}
		getData();
	}, []);

	//stops loading
	useEffect(() => {
		if (data && originalData) setLoading(false);
	}, [data]);

	const handleFormChange = (e, index, field, subIndex) => {
		setUpdatingData(true);
		setData((prevData) => {
			const newData = [...prevData];
			if (newData[index][field] === undefined) {
				console.log('not found');
				newData[index] = {
					...newData[index],
					[field]: null,
				};
			}
			if (subIndex === undefined) newData[index][field] = e;
			else {
				if (subIndex === 0) newData[index][field] = [e];
				else newData[index][field][subIndex] = e;
			}
			return newData;
		});
		setUpdatingData(false);
	};

	const handleAddDropDownChoice = (index, field) => {
		setUpdatingData(true);
		setData((prevData) => {
			const newData = [...prevData];
			if (!newData[index][field]) {
				newData[index] = {
					...newData[index],
					[field]: null,
				};
			}
			newData[index][field].push('');
			return newData;
		});
		setUpdatingData(false);
	};

	const handleDeleteDropDownChoice = (index, field, choiceIndex) => {
		setUpdatingData(true);
		setData((prevData) => {
			const newData = [...prevData];
			newData[index][field].splice(choiceIndex, 1);
			return newData;
		});
		setUpdatingData(false);
	};

	const handleOrderChange = (e, index, field, adjacentIndexToAdjust, eAdjacentIndexToAdjust) => {
		setUpdatingData(true);
		setData((prevData) => {
			const newData = [...prevData];
			newData[index][field] = e;
			newData[adjacentIndexToAdjust][field] = eAdjacentIndexToAdjust;
			const sortedData = [...newData].sort((a, b) => a.Order - b.Order);
			return sortedData;
		});
		setUpdatingData(false);
	};

	const handleAddRow = async () => {
		setModalOpen(null);
		setUpdatingData(true);

		const docRef = await addDoc(collection(db, 'DirectorySettings'), {
			Order: data.length,
			Label: newField.DisplayName,
			CustomSchema: newField.CustomSchema,
			Value: newField.Value,
			SubValue: '',
			Array: false,
			Type: '',
			InputType: '',
			Format: '',
			DropDownChoices: [],
			FieldType: '',
			DisplayOnDirectory: true,
			EditableOnDirectory: false,
			DisplayOnMyInfo: true,
			EditableOnMyInfo: false,
		});

		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			setData((prevData) => {
				const newData = [...prevData];
				newData.push({
					...docSnap.data(),
					ID: docRef.id,
				});
				return newData;
			});

			setTimeout(() => {
				const tableBody = tableRef.current.querySelector('tbody');
				if (tableBody) {
					const lastRow = tableBody.lastElementChild;
					if (lastRow) {
						lastRow.scrollIntoView({ behavior: 'smooth' });
					}
				}
			}, 0);
		} else {
			alertCtx.setMessage('There was an error.  Reload the page and try again.');
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error');
			alertCtx.setActive(true);
		}
		setNewField({});
		setUpdatingData(false);
	};

	const handleDeleteRow = async (item) => {
		setUpdatingData(true);

		await deleteDoc(doc(db, 'DirectorySettings', item.ID))
			.then(() => {
				alertCtx.setMessage('Successfully deleted row.');
				alertCtx.setSeverity('success');
				alertCtx.setTimer(10000);
				alertCtx.setActive(true);
			})
			.catch((error) => {
				alertCtx.setMessage(`There was an error: ${error}.  Reload the page and try again.`);
				alertCtx.setSeverity('error');
				alertCtx.setActive(true);
			});

		const tempData = [];
		const querySnapshot = await getDocs(collection(db, 'DirectorySettings'));
		querySnapshot.forEach((doc) => {
			tempData.push({ ...doc.data(), ID: doc.id });
		});
		const sortedData = [...tempData].sort((a, b) => a.Order - b.Order);
		setData(sortedData);
		setOriginalData(JSON.parse(JSON.stringify(sortedData)));

		setUpdatingData(false);
	};

	const handleSaveData = async () => {
		setUpdatingData(true);

		// Get a new write batch
		const batch = writeBatch(db);

		const Schema_EPT_Details = { schemaName: 'EPT_Details', fields: [] };
		const Schema_PHI_Keys = { schemaName: 'PHI_Keys', fields: [] };

		for (const item of data) {
			// Update the data
			const ref = doc(db, 'DirectorySettings', item.ID);
			const obj = item;
			delete obj.ID;
			batch.update(ref, { ...obj });

			//parse out the custom schemas allowed
			switch (item.CustomSchema) {
				case 'EPT_Details':
					Schema_EPT_Details.fields.push(BuildSchema(item));
					break;
				case 'PHI_Keys':
					Schema_PHI_Keys.fields.push(BuildSchema(item));
					break;
			}
		}

		// Commit the batch
		await batch.commit();

		//List the custom schemas
		// console.log('EPT_Details', Schema_EPT_Details);
		// console.log('PHI_Keys', Schema_PHI_Keys);

		//Update the custom schemas with Admin SDK
		const functions = getFunctions();
		const updateDirectorySchemas = httpsCallable(functions, 'updateDirectorySchemas');
		await updateDirectorySchemas({ Schemas: [Schema_EPT_Details, Schema_PHI_Keys] })
			.then((res) => {
				// console.log(res);
				if (res.data.code === 200) {
					alertCtx.setMessage(res.data.message);
					alertCtx.setSeverity(res.data.status);
					alertCtx.setTitle('Directory Schemas');
					alertCtx.setTimer(10000);
					alertCtx.setActive(true);
				} else {
					alertCtx.setMessage(res.data.message);
					alertCtx.setSeverity(res.data.status);
					alertCtx.setTitle('Error');
					alertCtx.setActive(true);
				}
			})
			.catch((err) => {
				alertCtx.setMessage(err);
				alertCtx.setSeverity('error');
				alertCtx.setTitle('Error');
				alertCtx.setActive(true);
			});

		//download the newest data from Firestore
		const tempData = [];
		const querySnapshot = await getDocs(collection(db, 'DirectorySettings'));
		querySnapshot.forEach((doc) => {
			tempData.push({ ...doc.data(), ID: doc.id });
		});
		const sortedData = [...tempData].sort((a, b) => a.Order - b.Order);
		setData(sortedData);
		setOriginalData(JSON.parse(JSON.stringify(sortedData)));

		setUpdatingData(false);
	};

	//closes modal
	const handleClose = () => {
		setNewField({});
		setModalOpen(null);
	};

	//checks equivalency
	useEffect(() => {
		if (data && originalData) setSame(AreArraysEqual(data, originalData));
	}, [data, originalData]);

	if (loading)
		return (
			<Grid xs={12}>
				<LoadingSpinner />
			</Grid>
		);
	//modal for setting up a new field for the directory
	else if (modalOpen) {
		return (
			<Dialog open={modalOpen} onClose={handleClose} fullWidth>
				<Box sx={{ padding: '5px' }}>
					<DialogTitle textAlign={'center'}>Add a Row to the Directory</DialogTitle>
					<DialogContent>
						<Box mt={1}>
							{/* display name */}
							<TextField
								fullWidth
								// size='small'
								margin='dense'
								id={`Display_Name`}
								placeholder='Display Name'
								hiddenLabel
								variant='outlined'
								color='secondary'
								value={newField?.DisplayName || ''}
								onChange={(e) => {
									setNewField((prevData) => {
										const newData = { ...prevData };
										newData['DisplayName'] = e.target.value;
										newData['Value'] = e.target.value.replace(/ /g, '_');
										return newData;
									});
								}}
							/>

							{/* custom schema */}
							<FormControl fullWidth margin='dense'>
								<InputLabel id={`Schema`}>Schema</InputLabel>
								<Select
									color='secondary'
									labelId='CustomSchema'
									id='CustomSchema'
									value={newField?.CustomSchema || ''}
									label='Schema'
									onChange={(e) => {
										setNewField((prevData) => {
											const newData = { ...prevData };
											newData['CustomSchema'] = e.target.value;
											return newData;
										});
									}}
								>
									<MenuItem value={'EPT_Details'}>EPT Details</MenuItem>
									<MenuItem value={'PHI_Keys'}>PHI Keys</MenuItem>
								</Select>
							</FormControl>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button variant='contained' onClick={handleClose} color='cancel'>
							Cancel
						</Button>
						<Button
							variant='contained'
							onClick={handleAddRow}
							disabled={!newField?.CustomSchema?.length > 0 || !newField?.DisplayName?.length > 0}
						>
							Save
						</Button>
					</DialogActions>
				</Box>
			</Dialog>
		);
	}

	return (
		<Grid xs={12}>
			<Stack direction='row' spacing={5} mb={1}>
				<Button
					variant='contained'
					color='info'
					startIcon={<AddCircleRoundedIcon />}
					onClick={() => setModalOpen(true)}
					disabled={updatingData}
				>
					Add Row
				</Button>

				{!same && (
					<Button
						variant='contained'
						color='saveButton'
						startIcon={<SaveRoundedIcon />}
						onClick={handleSaveData}
						disabled={updatingData}
					>
						Save Changes
					</Button>
				)}
			</Stack>

			<TableContainer sx={{ maxHeight: '60vh', marginBottom: '2rem' }} ref={tableRef}>
				<Table stickyHeader>
					<TableHead>
						<TableRow sx={{ backgroundColor: '#f3f3f3' }}>
							<TableCell align='center' />
							<TableCell align='center'>
								<Stack
									direction='row'
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									Order
									<Tooltip
										text='The order in which the row will appear on the Directory and My Info.'
										placement='bottom'
									/>
								</Stack>
							</TableCell>

							<TableCell align='center'>
								<Stack
									direction='row'
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									Display Name
									<Tooltip text='The title of the input.' placement='bottom' />
								</Stack>
							</TableCell>
							<TableCell align='center'>
								<Stack
									direction='row'
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									Display on Directory
									<Tooltip
										text='Select if the row should be displayed to all users on the Directory.'
										placement='bottom'
									/>
								</Stack>
							</TableCell>
							<TableCell align='center'>
								<Stack
									direction='row'
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									Editable on Directory
									<Tooltip
										text="Select if the row should be editable by Admin's on the Directory."
										placement='bottom'
									/>
								</Stack>
							</TableCell>
							<TableCell align='center'>
								<Stack
									direction='row'
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									Display on My Info
									<Tooltip
										text='Select if the row should be displayed to the user on their My Info page.'
										placement='bottom'
									/>
								</Stack>
							</TableCell>
							<TableCell align='center'>
								<Stack
									direction='row'
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									Editable on My Info
									<Tooltip
										text='Select if the row should be editable by the user on their My Info page.'
										placement='bottom'
									/>
								</Stack>
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{data &&
							data.map((item, index) => {
								return (
									<Row
										key={index}
										item={item}
										index={index}
										handleOrderChange={handleOrderChange}
										handleFormChange={handleFormChange}
										updatingData={updatingData}
										handleDeleteRow={handleDeleteRow}
										data={data}
										originalData={originalData}
										handleAddDropDownChoice={handleAddDropDownChoice}
										handleDeleteDropDownChoice={handleDeleteDropDownChoice}
										authCtx={authCtx}
									/>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	);
}
