import React, { useState, useEffect, useMemo } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';

import { Bar } from 'react-chartjs-2';

import {
	MaterialReactTable,
	useMaterialReactTable,
} from 'material-react-table';
import moment from 'moment';

import { db } from '../../App';
import LoadingSpinner from '../ui/LoadingSpinner';
import PageCards from '../ui/PageCards';
import { Box, Typography } from '@mui/material';

const UserVerificationStats = () => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [userTypeCounts, setUserTypeCounts] = useState({});

	const fetchUsersWithVerification = async () => {
		try {
			const q1 = query(
				collection(db, 'Users'),
				where('UserVerification.UserType', '==', null)
			);
			const q2 = query(
				collection(db, 'Users'),
				where('UserVerification.UserType', '!=', null)
			);
			const querySnapshot1 = await getDocs(q1);
			const querySnapshot2 = await getDocs(q2);

			const userList = [];
			querySnapshot1.docs.forEach((doc) =>
				userList.push({
					id: doc.id,
					...doc.data().UserVerification,
				})
			);
			querySnapshot2.docs.forEach((doc) =>
				userList.push({
					id: doc.id,
					...doc.data().UserVerification,
				})
			);
			const sortedList = userList.sort((a, b) => {
				if (a.UserName < b.UserName) {
					return -1;
				}
				if (a.UserName > b.UserName) {
					return 1;
				}
				return 0;
			});
			setUsers(sortedList);
		} catch (error) {
			console.error('Error fetching user data:', error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchUsersWithVerification();
	}, []);

	// Aggregate data into two subgroups for each UserType
	useEffect(() => {
		const now = new Date();
		const overDue = new Date(now.getTime() - 6 * 30 * 24 * 60 * 60 * 1000);

		const counts = {};
		users.forEach((user) => {
			const type = user.UserType || 'Unknown';
			if (!counts[type]) {
				counts[type] = { UpToDate: 0, OverDue: 0 };
			}

			if (user.UserType === 'Cloud Identity or Other')
				counts[type].UpToDate += 1;
			else if (user?.VerificationTime === null) counts[type].OverDue += 1;
			else if (user?.VerificationTime?.toDate() > overDue) {
				counts[type].UpToDate += 1;
			} else {
				counts[type].OverDue += 1;
			}
		});

		// Convert counts object to an array, sort it, and convert back to object if needed
		const sortedUserTypeCounts = Object.entries(counts).sort((a, b) =>
			a[0].localeCompare(b[0])
		);
		const sortedCounts = {};
		sortedUserTypeCounts.forEach(([key, value]) => {
			sortedCounts[key] = value;
		});

		setUserTypeCounts(sortedCounts);
	}, [users]);

	//chart.js data
	const data = {
		labels: Object.keys(userTypeCounts),
		datasets: [
			{
				label: 'Up To Date',
				data: Object.values(userTypeCounts).map((type) => type.UpToDate),
				backgroundColor: 'rgba(75, 192, 192, 0.2)',
				borderColor: 'rgba(75, 192, 192, 1)',
				borderWidth: 1,
			},
			{
				label: 'Over Due',
				data: Object.values(userTypeCounts).map((type) => type.OverDue),
				backgroundColor: 'rgba(255, 99, 132, 0.2)',
				borderColor: 'rgba(255, 99, 132, 1)',
				borderWidth: 1,
			},
		],
	};

	//chart.js options
	const options = {
		scales: {
			x: {
				ticks: {
					font: {
						size: 12,
					},
				},
			},
			y: {
				beginAtZero: true,
				ticks: {
					stepSize: 1,
					autoSkip: true, // Chart.js will automatically skip ticks to prevent label overlapping
					maxTicksLimit: 20, // Adjust based on your aesthetic needs
				},
			},
		},
		responsive: true,
		aspectRatio: 1, // Adjust to fit your needs
		plugins: {
			legend: {
				display: true,
				position: 'top',
			},
			title: {
				display: true,
				text: 'User Type Distribution',
				fullSize: true,
				font: {
					size: 20,
				},
			},
		},
	};

	//table columns
	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => `${row.UserName}`,
				// accessorKey: 'UserName',
				header: 'Name',
				id: 'UserName',
			},
			{
				accessorFn: (row) =>
					row.UserType === 'Cloud Identity or Other'
						? 'Not Applicable'
						: row.VerificationTime !== null
						? moment(row.VerificationTime.toDate()).format(
								'MMMM D, YYYY @ h:mm:ss A'
						  )
						: '',
				header: 'Verification Time',
				id: 'VerificationTime',
			},
			{
				accessorKey: 'UserType',
				header: 'User Type',
			},
		],
		[]
	);

	const table = useMaterialReactTable({
		columns,
		data: users,
		globalFilterFn: 'contains',
		getRowProps: (row) => ({
			style: {},
		}),
		initialState: {
			// showProgressBars: loading,
			// expanded: true, //expand all groups by default
			// columnVisibility: {},
			density: 'compact',
			sorting: [{ id: 'UserName', desc: false }],
			// pagination: { pageIndex: 0, pageSize: 25 },
			showGlobalFilter: true,
		},
		muiTablePaperProps: {
			elevation: 0,
		},
		muiTableBodyRowProps: ({ row }) => {
			// Calculate the difference in days from now if VerificationTime exists
			const isOldOrMissing =
				row.original.UserType === 'Cloud Identity or Other'
					? false
					: row.original.VerificationTime === null ||
					  moment(new Date()).diff(
							moment(row.original.VerificationTime.toDate()),
							'days'
					  ) > 180;

			const rowColor = row.index % 2 === 0 ? '#f5f5f5' : '#ffffff';

			return {
				sx: {
					// Conditionally apply styles
					backgroundColor: isOldOrMissing ? '#FEB5B8' : rowColor,
				},
			};
		},
		// enableGrouping,
		// autoResetPageIndex,
		enablePagination: false,
	});

	if (loading)
		return (
			<PageCards>
				<LoadingSpinner />
			</PageCards>
		);

	return (
		<PageCards>
			<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
				User Verification Stats
			</Typography>

			<Box
				width={'100%'}
				height={400}
				display={'flex'}
				justifyContent={'center'}
			>
				<Bar data={data} options={options} />
			</Box>

			<MaterialReactTable table={table} />
		</PageCards>
	);
};

export default UserVerificationStats;
