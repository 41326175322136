import React, { useState, useEffect, useMemo, useContext } from 'react';
import { collection, query, getDocs, doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image'; // icon for images
import DescriptionIcon from '@mui/icons-material/Description'; // Generic icon for documents
import ArticleIcon from '@mui/icons-material/Article'; // Another option for documents
import TableViewIcon from '@mui/icons-material/TableView'; // For Excel and CSV

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import moment from 'moment';

import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import ThumbsUpDownRoundedIcon from '@mui/icons-material/ThumbsUpDownRounded';
import {
	Button,
	Box,
	Chip,
	Container,
	Stack,
	Modal,
	Typography,
	TextField,
	FormControl,
	MenuItem,
	Select,
	Grid,
	InputLabel,
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
	Tabs,
	Tab,
} from '@mui/material';

import { db } from '../../App';
import LoadingSpinner from '../ui/LoadingSpinner';
import PageCards from '../ui/PageCards';
import GetUserData from '../auth/GetUserData';
import AlertContext from '../ui/AlertContext';
import AuthContext from '../auth/authContext';
import Tooltip from '../ui/Tooltip';

const Voting = () => {
	const auth = getAuth();
	const alertCtx = useContext(AlertContext);
	const authCtx = useContext(AuthContext);
	const [votes, setVotes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [voting, setVoting] = useState(false);
	const [motionSubmitting, setMotionSubmitting] = useState(false);
	const [votingGroups, setVotingGroups] = useState([]);
	const [openModal, setOpenModal] = useState(false); // State to handle modal visibility
	const [motionDetails, setMotionDetails] = useState({ Urgent: false });
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [openMotions, setOpenMotions] = useState([]);
	const [selectedTab, setSelectedTab] = useState(0);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	//gets the groups the user can vote with
	useEffect(() => {
		const getUserData = async () => {
			const tempData = await GetUserData('VotingGroups');
			// console.log(tempData);
			setVotingGroups(tempData);
		};
		if (votingGroups.length === 0) getUserData();
	}, [votingGroups]);

	// Fetch openMotions
	useEffect(() => {
		const getUserData = async () => {
			const tempData = (await GetUserData('Voting')) || {};
			const tempArray = [];
			const keys = Object.keys(tempData);
			for (const key of keys) {
				const docRef = doc(db, 'Voting', key);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					tempArray.push({ ...docSnap.data(), id: key, links: tempData[key] });
				}
				// else {
				// 	console.error('No such document!');
				// }
			}
			if (tempArray.length > 0) setOpenMotions(tempArray);
			else setOpenMotions(null);
		};
		if (openMotions && openMotions.length === 0) getUserData();
	}, [openMotions]);

	// useEffect(() => {
	// 	console.log(openMotions);
	// }, [openMotions]);

	const fetchVotes = async () => {
		try {
			const q = query(collection(db, 'Voting'));
			const querySnapshot = await getDocs(q);

			const tempVotes = [];
			querySnapshot.docs.forEach((doc) =>
				tempVotes.push({
					ID: doc.id,
					...doc.data(),
				})
			);

			// console.log(tempVotes);
			setVotes(tempVotes);
		} catch (error) {
			alertCtx.setMessage(`Error fetching motes - ${error}`);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error Getting Motions');
			alertCtx.setActive(true);
		}
		setLoading(false);
	};

	//gets the votes
	useEffect(() => {
		fetchVotes();
	}, []);

	// Extract unique 'Group' values from the votes array
	const uniqueGroups = [...new Set(votes.map((vote) => vote.Group.GroupName))].sort();

	// Button for submitting a vote
	const handleSubmitVoteButton = async () => {
		handleOpen();
	};

	/**
	 * sets the details for motionDetails
	 * @param {string} field the field to update
	 * @param {*} value value of the field to update
	 */
	const handleSetMotionDetails = (field, value) => {
		setMotionDetails((prevState) => ({
			...prevState,
			[field]: value,
		}));
	};

	// Function to handle removing a file
	const handleRemoveFile = (indexToRemove) => {
		if (!selectedFiles) return;

		const filteredFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
		setSelectedFiles(filteredFiles);
	};

	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);
		setSelectedFiles((prevState) => [...prevState, ...files]);
	};

	const handleVote = async (voteAction, Motion) => {
		try {
			setVoting(true);
			const voteRef = doc(db, 'Users', auth.currentUser.uid);
			const tempData = (await getDoc(voteRef)).data().Voting;
			const newData = {
				...tempData,
				[Motion.id]: {
					...Motion.links,
					vote: voteAction,
					voteTime: serverTimestamp(),
				},
			};
			await updateDoc(voteRef, { Voting: newData });

			setOpenMotions([]);

			alertCtx.setMessage('Vote Cast Successfully');
			alertCtx.setSeverity('success');
			alertCtx.setTimer(10000);
			alertCtx.setTitle('Vote Cast');
			alertCtx.setActive(true);
		} catch (error) {
			console.error('Error recording vote:', error);
			alertCtx.setMessage('Error recording vote:', error);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Vote Cast');
			alertCtx.setActive(true);
		}
		setVoting(false);
	};

	const handleSubmitVote = async () => {
		setMotionSubmitting(true);
		const storage = getStorage();
		const uploadedFiles = [];
		const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

		/* upload files */
		try {
			for (let file of selectedFiles) {
				const storageRef = ref(storage, `Votes/${motionDetails.Group.GroupName}/${today}/${file.name}`);
				await uploadBytes(storageRef, file);
				const downloadURL = await getDownloadURL(storageRef);
				uploadedFiles.push({ name: file.name, url: downloadURL, mimeType: file.type });
			}
		} catch (err) {
			alertCtx.setMessage(`Error uploading files. Please try again. - ${err}`);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error');
			alertCtx.setActive(true);
			setMotionSubmitting(false);
			handleClose();
			return;
		}

		/* upload to firestore via function */
		try {
			const voteData = {
				...motionDetails,
				Attachments: uploadedFiles,
				Submitter: {
					name: authCtx.userName,
					email: authCtx.email,
				},
				Closed: false,
			};
			// console.log(voteData);

			const functions = getFunctions();
			const votingNewMotion = httpsCallable(functions, 'votingNewMotion');
			await votingNewMotion({ Motion: voteData }).then((result) => {
				// console.log(result);
				if (result.data.code === 200) {
					alertCtx.setMessage(result.data.message);
					alertCtx.setSeverity('success');
					alertCtx.setTitle('Motion Submitted Successfully');
					alertCtx.setTimer(10000);
					alertCtx.setActive(true);

					setLoading(true);
					fetchVotes();

					setMotionDetails({ Urgent: false });
					setSelectedFiles([]);
					setOpenMotions([]);
				} else {
					alertCtx.setMessage(result.data.message);
					alertCtx.setSeverity('error');
					alertCtx.setTitle('Error Submitting Motion');
					alertCtx.setActive(true);
				}
			});
		} catch (err) {
			alertCtx.setMessage(`Error submitting vote - ${err}`);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error');
			alertCtx.setActive(true);
		}

		handleClose();
		setMotionSubmitting(false);
	};

	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);

	/* new motion modal */
	const modalBody = (
		<Box
			sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				width: '75%',
				bgcolor: 'background.paper',
				boxShadow: 24,
				p: 4,
			}}
		>
			<Typography id='modal-modal-title' variant='h6' component='h2' textAlign={'center'}>
				Submit a Motion
			</Typography>
			<Box component='form' noValidate sx={{ mt: 2 }}>
				<Stack spacing={2} m={2}>
					{/* voting group */}
					<Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
						<Tooltip text='Select the group to send the motion to.' />
						<FormControl fullWidth>
							<InputLabel id='demo-simple-select-label'>Group</InputLabel>
							<Select
								labelId='votingGroup-label'
								id='votingGroup'
								value={motionDetails?.Group?.GroupName || ''} // Display GroupName in the select
								label='Group'
								onChange={(e) =>
									handleSetMotionDetails(
										'Group',
										votingGroups.find((g) => g.GroupName === e.target.value)
									)
								}
							>
								{votingGroups.map((group) => (
									<MenuItem key={group.GroupName} value={group.GroupName}>
										{group.GroupName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>

					{/* cc email addresses */}
					<Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
						<Tooltip text="These email addresses will be CC'd on the vote emails." />
						<TextField
							disabled
							fullWidth
							label={'CC Email Addresses'}
							id='CCEmailAddresses'
							value={motionDetails?.Group?.CCEmailAddresses || ''}
						/>
					</Box>

					{/* motion */}
					<Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
						<Tooltip text='Enter the motion to be considered.' />
						<TextField
							fullWidth
							label={'Motion'}
							id='Motion'
							multiline
							minRows={3}
							value={motionDetails?.Motion || ''}
							onChange={(e) => handleSetMotionDetails('Motion', e.target.value)}
						/>
					</Box>

					{/* link */}
					<Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
						<Tooltip text='If needed, paste any link that should accompany the vote.' />
						<TextField
							fullWidth
							label={'Link'}
							id='Link'
							value={motionDetails?.Link || ''}
							onChange={(e) => handleSetMotionDetails('Link', e.target.value)}
						/>
					</Box>

					{/* urgent */}
					<Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
						<Tooltip text='If set to True, then the vote will only remain open for 1 day.' />
						<Stack direction={'row'} spacing={2} alignItems={'center'}>
							<FormLabel component='legend'>Urgent:</FormLabel>
							<RadioGroup
								row
								aria-label='urgent'
								name='urgent'
								value={motionDetails?.Urgent !== undefined ? JSON.stringify(motionDetails?.Urgent) : ''}
								onChange={(e) => handleSetMotionDetails('Urgent', e.target.value === 'true' ? true : false)}
							>
								<FormControlLabel value='true' control={<Radio />} label='True' />
								<FormControlLabel value='false' control={<Radio />} label='False' />
							</RadioGroup>
						</Stack>
					</Box>

					{/* voting days */}
					<Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
						<Tooltip text='The number of days the vote will remain open. This field is auto-calculated and is not editable.' />
						<TextField
							disabled
							fullWidth
							label={'Voting Days'}
							id='Voting Days'
							value={motionDetails?.Urgent ? '1' : motionDetails?.Group?.VotingDays || ''}
						/>
					</Box>

					{/* attachments */}
					<Box display={'flex'} justifyContent={'center'}>
						<Stack spacing={2} width={'100%'} alignItems={'center'}>
							<Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
								<Tooltip text='Add any attachments that are needed for the vote.' />
								<Button variant='outlined' component='label'>
									{selectedFiles?.length === 0 ? 'Upload Files' : 'Add Files'}
									<input
										type='file'
										hidden
										multiple // Allows multiple file selection
										onChange={handleFileChange}
									/>
								</Button>
							</Box>

							{/* display attachments */}
							{selectedFiles &&
								selectedFiles.map((file, index) => (
									<Chip
										key={index}
										label={file.name}
										onDelete={() => handleRemoveFile(index)}
										color='info'
										variant='outlined'
									/>
								))}
						</Stack>
					</Box>
				</Stack>

				<Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'center'}>
					{motionSubmitting && <LoadingSpinner />}
					{!motionSubmitting && (
						<Button
							variant='contained'
							onClick={handleSubmitVote}
							disabled={
								!motionDetails?.Motion ||
								motionDetails?.Motion?.length === 0 ||
								typeof motionDetails?.Urgent !== 'boolean' ||
								!motionDetails?.Group
							}
						>
							Submit Vote
						</Button>
					)}
					<Button variant='outlined' onClick={handleClose}>
						Cancel
					</Button>
				</Stack>
			</Box>
		</Box>
	);

	if (loading)
		return (
			<PageCards>
				<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
					Voting
				</Typography>
				<LoadingSpinner />
			</PageCards>
		);

	return (
		<>
			{/* title */}
			<PageCards>
				<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
					Voting
				</Typography>
				<Box display={'flex'} justifyContent={'right'} sx={{ width: '100%' }} paddingRight={5}>
					<Button
						variant='contained'
						color='info'
						onClick={handleSubmitVoteButton}
						disabled={votingGroups?.length === 0}
					>
						Submit a Motion
					</Button>
				</Box>
			</PageCards>

			{/* new motion modal */}
			<Modal
				open={openModal}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				{modalBody}
			</Modal>

			<PageCards>
				{/* voting tabs */}
				<Box sx={{ color: 'info.main' }} display={'flex'} justifyContent={'center'}>
					<Tabs
						textColor='inherit'
						indicatorColor='secondary'
						value={selectedTab}
						onChange={handleTabChange}
						variant='scrollable'
						scrollButtons='auto'
						allowScrollButtonsMobile
					>
						{openMotions && openMotions.length > 0 && (
							<Tab
								label='Open Motions'
								sx={{ fontSize: '1.5rem', color: selectedTab === 0 ? 'inherit' : 'grey.500', fontWeight: 'bold' }}
							/>
						)}
						{uniqueGroups.map((group, index) => (
							<Tab
								key={index}
								label={group}
								sx={{
									fontSize: '1.5rem',
									color:
										selectedTab === (openMotions && openMotions.length > 0 ? index + 1 : index)
											? 'inherit'
											: 'grey.500',
								}}
							/>
						))}
					</Tabs>
				</Box>

				{/* voting tables for each group and open motions */}
				{openMotions && openMotions.length > 0 && (
					<TabPanel value={selectedTab} index={0}>
						<Grid container spacing={2} display={'flex'} justifyContent={'center'}>
							{openMotions.map((Motion, index) => (
								<Grid item key={index} xs={12} sm={6} md={6} lg={4}>
									<Stack key={index} borderRadius={4} p={2} spacing={2} sx={{ backgroundColor: '#f4f6f6' }}>
										{/* user's current vote */}
										{Motion.links.vote && (
											<Stack direction={'row'} spacing={1} alignContent={'center'} alignItems={'center'}>
												<Typography fontWeight={'bold'}>My Vote:</Typography>
												<Button
													color={
														Motion.links.vote === 'Approve'
															? 'voteApprove'
															: Motion.links.vote === 'Do Not Approve'
															? 'voteDoNotApprove'
															: Motion.links.vote === 'Abstain'
															? 'voteAbstain'
															: Motion.links.vote === 'Request In-Person Meeting'
															? 'voteRequestInPersonMeeting'
															: 'primary'
													}
													variant='contained'
													sx={{
														pointerEvents: 'none', // Disable pointer events to make the button unclickable
													}}
												>
													{Motion.links.vote}
												</Button>
											</Stack>
										)}

										{/* motion */}
										<Stack>
											<Typography fontWeight={'bold'}>Motion:</Typography>
											<Typography>{Motion.Motion}</Typography>
										</Stack>

										{/* link */}
										{Motion.Link && (
											<Stack direction={'row'} spacing={1}>
												<Typography fontWeight={'bold'}>Link:</Typography>
												<Typography>
													<a href={Motion.Link} target='_blank' rel='noreferrer'>
														Click Here
													</a>
												</Typography>
											</Stack>
										)}

										{/* attachments */}
										{Motion.Attachments && Motion.Attachments.length > 0 && (
											<Stack>
												<Typography fontWeight={'bold'}>Attachments:</Typography>
												<Stack spacing={1}>
													{Motion.Attachments.map((Attachment, attachmentIndex) => (
														<a key={attachmentIndex} href={Attachment.url} target='_blank' rel='noreferrer'>
															<Chip
																size='large'
																label={Attachment.name}
																icon={getIconFromMimeType(Attachment.mimeType)}
																color='primary'
																variant='outlined'
															/>
														</a>
													))}
												</Stack>
											</Stack>
										)}

										{/* close date */}
										<Stack>
											<Typography fontWeight={'bold'}>Close Date:</Typography>
											<Typography>{moment(Motion.CloseDate.toDate()).format('MMM D, YYYY h:mm A')}</Typography>
										</Stack>

										{/* voting buttons */}
										<Stack>
											<Typography fontWeight={'bold'}>Voting Options:</Typography>
											<Grid item key={index} xs={12} sm='auto'>
												{!voting && (
													<Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={2}>
														<Button
															variant='outlined'
															color='voteApprove'
															sx={{ backgroundColor: 'voteApprove.light' }}
															onClick={() => handleVote('Approve', Motion)}
														>
															Approve
														</Button>
														<Button
															variant='outlined'
															color='voteDoNotApprove'
															sx={{ backgroundColor: 'voteDoNotApprove.light' }}
															onClick={() => handleVote('Do Not Approve', Motion)}
														>
															Do Not Approve
														</Button>
														<Button
															variant='outlined'
															color='voteAbstain'
															sx={{ backgroundColor: 'voteAbstain.light' }}
															onClick={() => handleVote('Abstain', Motion)}
														>
															Abstain
														</Button>
														<Button
															variant='outlined'
															color='voteRequestInPersonMeeting'
															sx={{ backgroundColor: 'voteRequestInPersonMeeting.light' }}
															onClick={() => handleVote('Request In-Person Meeting', Motion)}
														>
															Request In-Person Meeting
														</Button>
													</Stack>
												)}
											</Grid>
										</Stack>
									</Stack>
								</Grid>
							))}
						</Grid>
					</TabPanel>
				)}

				{uniqueGroups.map((group, index) => (
					<TabPanel key={index} value={selectedTab} index={openMotions && openMotions.length > 0 ? index + 1 : index}>
						<GroupVotingTable votes={votes} currentGroup={group} />
					</TabPanel>
				))}
			</PageCards>
		</>
	);
};
export default Voting;

const GroupVotingTable = ({ votes, currentGroup }) => {
	const filteredVotes = useMemo(
		() => votes.filter((vote) => vote.Group.GroupName === currentGroup),
		[votes, currentGroup]
	); // Filter the votes for only the current group

	// Define the columns for the table
	const columns = useMemo(
		() => [
			{ accessorKey: 'ID', header: 'ID' },
			// { accessorKey: 'Group.GroupName', header: 'Group' },
			{
				header: 'Status',
				id: 'Status',
				accessorFn: (row) => (!row.Closed ? 'Voting Open' : row.VoteResult),
				Cell: ({ cell }) =>
					cell.getValue() === 'Voting Open' ? (
						<Button
							variant='outlined'
							color='voteAbstain'
							sx={{ borderRadius: '16px', backgroundColor: 'voteAbstain.light' }}
						>
							Voting Open
						</Button>
					) : (
						<Button
							color={
								cell.getValue() === 'Motion passed'
									? 'voteApprove'
									: cell.getValue() === 'Motion did not pass'
									? 'voteDoNotApprove'
									: 'voteRequestInPersonMeeting'
							}
							sx={{
								borderRadius: '16px',
								backgroundColor:
									cell.getValue() === 'Motion passed'
										? 'voteApprove.light'
										: cell.getValue() === 'Motion did not pass'
										? 'voteDoNotApprove.light'
										: 'voteRequestInPersonMeeting.light',
							}}
							variant='outlined'
						>
							{cell.getValue()}
						</Button>
					),
			},
			{ accessorKey: 'Motion', header: 'Motion', minSize: 300 },
			{
				header: 'Link',
				accessorFn: (row) =>
					row.Link && (
						<a href={row.Link} target='_blank' rel='noreferrer'>
							Link
						</a>
					),
			},
			{
				header: 'Attachments',
				accessorFn: (row) => (
					<Stack spacing={1}>
						{row.Attachments &&
							row.Attachments.map((attachment, index) => (
								<a key={index} href={attachment.url} target='_blank' rel='noreferrer'>
									<Chip
										size='large'
										label={attachment.name}
										icon={getIconFromMimeType(attachment.mimeType)}
										color='primary'
										variant='outlined'
									/>
								</a>
							))}
					</Stack>
				),
			},
			{
				header: 'Close Date',
				id: 'CloseDate',
				accessorFn: (row) =>
					row.CloseDate?.toDate() ? moment(row.CloseDate?.toDate()).format('YYYY-MM-DD hh:mm:ss A') : '',
				Cell: ({ cell }) =>
					cell.getValue() && cell.getValue() !== ''
						? moment(cell.getValue(), 'YYYY-MM-DD hh:mm:ss A').format('MMM D, YYYY h:mm A')
						: '',
			},
			{
				header: 'Open Date',
				id: 'OpenDate',
				accessorFn: (row) =>
					row.OpenDate?.toDate() ? moment(row.OpenDate?.toDate()).format('YYYY-MM-DD hh:mm:ss A') : '',
				Cell: ({ cell }) =>
					cell.getValue() && cell.getValue() !== ''
						? moment(cell.getValue(), 'YYYY-MM-DD hh:mm:ss A').format('MMM D, YYYY h:mm A')
						: '',
			},
			{ header: 'Urgent', accessorFn: (row) => (row.Urgent ? 'True' : '') },
			{ header: 'Submitter', accessorKey: 'Submitter.name' },
		],
		[]
	);

	const table = useMaterialReactTable({
		columns: columns,
		data: filteredVotes,
		getRowId: (row) => row.ID,
		muiTablePaperProps: {
			elevation: 0,
		},
		// enableExpanding: true, // Ensure this is true to allow row expansion
		enableExpandAll: false,
		paginationDisplayMode: 'pages',
		initialState: {
			sorting: [
				// { id: 'Status', asc: true },
				{ id: 'CloseDate', desc: true },
			],
			columnVisibility: { ID: false, Group: false },
		},
		// enablePagination: false,
		muiDetailPanelProps: () => ({
			sx: (theme) => ({
				backgroundColor: theme.palette.mode === 'dark' ? '#f9f9f9' : '#f9f9f9',
			}),
		}),
		muiExpandButtonProps: ({ row, table }) => ({
			onClick: () => {
				if (row.original.Closed) {
					// Only allow expansion if the row is closed
					table.setExpanded({ [row.id]: !row.getIsExpanded() });
				}
			},
			sx: {
				// Hide the button by making it transparent and unclickable when not closed
				opacity: row.original.Closed ? 1 : 0,
				pointerEvents: row.original.Closed ? 'auto' : 'none',
				transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
				transition: 'transform 0.2s',
				cursor: row.original.Closed ? 'pointer' : 'default',
			},
		}),
		renderDetailPanel: ({ row }) => {
			let sortedVotes;
			if (row.original.Closed && row.original.Votes) {
				const votesArray = Object.entries(row.original.Votes).map(([key, value]) => ({
					...value,
				}));

				sortedVotes = votesArray.sort((a, b) => {
					if (a.Name < b.Name) return -1;
					if (a.Name > b.Name) return 1;
					return 0;
				});
			}

			return (
				<Container p={2}>
					<Typography variant='h5' mb={2} fontWeight={'bold'} textAlign={'center'}>
						Vote Details
					</Typography>

					{/* votes */}
					{row.original.Closed && row.original.Votes && (
						<Stack spacing={1}>
							<Typography component='div' fontWeight={'bold'}>
								<Stack direction={'row'} spacing={5}>
									<Box width={'33%'}>Name</Box>
									<Box width={'33%'}>Vote</Box>
									<Box width={'33%'}>Timestamp</Box>
								</Stack>
							</Typography>
							<Stack>
								<Typography component='div' variant='subtitle2'>
									{sortedVotes.map((vote, index) => {
										const getVoteIcon = (voteType) => {
											switch (voteType) {
												case 'Approve':
													return <ThumbUpAltRoundedIcon color='success' />;
												case 'Do Not Approve':
													return <ThumbDownRoundedIcon color='error' />;
												case 'Abstain':
													return <ThumbsUpDownRoundedIcon color='otherButton' />;
												case 'Request In-Person Meeting':
													return <HowToRegRoundedIcon color='info' />;
												default:
													return;
											}
										};

										const icon = getVoteIcon(vote.vote);

										return (
											<Stack direction={'row'} border={'1px solid black'} p={1} alignItems={'center'} key={index}>
												<Box width={'33%'}>{vote.name}</Box>
												<Box width={'33%'}>
													<Chip icon={icon} label={vote.vote} />
												</Box>
												<Box width={'33%'}>
													{vote?.voteTime ? moment(vote.voteTime.toDate()).format('YYYY-MM-DD h:mm:ss A') : ''}
												</Box>
											</Stack>
										);
									})}
								</Typography>
							</Stack>
						</Stack>
					)}
				</Container>
			);
		},
	});

	if (columns && table) return <MaterialReactTable table={table} />;
};

const getIconFromMimeType = (mimeType) => {
	switch (mimeType) {
		case 'application/pdf':
			return <PictureAsPdfIcon />;
		case 'application/msword':
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			return <DescriptionIcon />;
		case 'application/vnd.ms-excel':
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
		case 'text/csv':
			return <TableViewIcon />;
		case 'image/jpeg':
		case 'image/png':
		case 'image/gif':
		case 'image/tiff':
		case 'image/bmp':
			return <ImageIcon />;
		default:
			return <ArticleIcon />;
	}
};

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
};
