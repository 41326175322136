/**
 *
 * @param {string} phoneNumber as a string
 * @returns formatted phone number
 */
export default function formatPhoneNumber(phoneNumber) {
	let cleaned = ('' + phoneNumber).replace(/\D/g, '');

	if (cleaned.length === 0) return '';
	else if (cleaned.length < 4) return '(' + cleaned.slice(0, 3);
	else if (cleaned.length < 7) return '(' + cleaned.slice(0, 3) + ') ' + cleaned.slice(3, 6);
	else return '(' + cleaned.slice(0, 3) + ') ' + cleaned.slice(3, 6) + '-' + cleaned.slice(6, 10);
}
