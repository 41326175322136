import React, { useContext } from 'react';
import AuthContext from './authContext';
import RefreshToken from './RefreshToken';

export default function CheckToken(props) {
	const authCtx = useContext(AuthContext);

	//refresh token if needed
	if (!authCtx.token || new Date(authCtx.tokenExpiration) < new Date()) {
		return <RefreshToken />;
	}
	return <div>{props.children}</div>;
}
