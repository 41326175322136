import React from 'react';
import AvatarInitials from './AvatarInitials';
import classes from './UserImage.module.css';

/**
 * @param {string} photoURL - a url of the image to be displayed
 * @param {string} name - the name of the person to display initials if no image can be displayed
 * @param {number} size - the size of the image in pixels, defaults to 100 if not supplied
 * @returns
 */
export default function UserImage(props) {
	let size = props.size;
	if (props.size === undefined) {
		size = 100;
	}

	if (props.photoURL !== null && props.photoURL !== undefined && props.photoURL !== '') {
		return (
			<img
				className={classes.userImage}
				src={props.photoURL}
				alt={props.name}
				referrerPolicy='no-referrer'
				style={{ height: size, width: size }}
			/>
		);
	} else {
		return <AvatarInitials name={props.name} size={size} />;
	}
}
