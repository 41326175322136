/**
 * gets the value of a directory item
 * @param {*} data
 * @param {*} item
 */
export default function getDirectoryValueFromArray(data, item) {
	if (item && item.CustomSchema && item.CustomSchema.trim().length > 0) {
		if (
			data &&
			data.customSchemas &&
			data.customSchemas[item.CustomSchema] &&
			data.customSchemas[item.CustomSchema][item.Value]
		) {
			let itemFound;
			data.customSchemas[item.CustomSchema][item.Value].forEach((eachItem, index) => {
				if (eachItem.type === item.Type) {
					itemFound = { value: eachItem[item.SubValue], index: index };
				}
			});
			if (itemFound) return itemFound;
			else return { value: '', index: null };
		} else return { value: '', index: null };
	} else {
		if (data && data[item.Value]) {
			let itemFound;
			data[item.Value].forEach((eachItem, index) => {
				if (eachItem.type === item.Type) {
					itemFound = { value: eachItem[item.SubValue], index: index };
				}
			});
			if (itemFound) return itemFound;
			else return { value: '', index: null };
		} else return { value: '', index: null };
	}
}
