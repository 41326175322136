import React, { useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import AuthContext from './authContext';

export default function SignOut(props) {
	const auth = getAuth();
	const authCtx = useContext(AuthContext);

	const signOutHandler = () => {
		signOut(auth)
			.then(() => {
				authCtx.logout();
			})
			.catch((error) => {
				console.log(error);
				// An error happened.
			});
	};

	return <div onClick={signOutHandler}>{props.children}</div>;
}
