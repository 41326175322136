import { API_KEY } from '../auth/SignIn';
import { gapi } from 'gapi-script';

const GetDirectory = (token, leadershipPositions) => {
	return new Promise((succeeded) => {
		const initClient = async () => {
			gapi.client.setToken({ access_token: token });

			let pageToken = '';
			const newArray = [];
			do {
				await gapi.client
					.request({
						method: 'GET',
						path:
							pageToken !== ''
								? `https://people.googleapis.com/v1/people:listDirectoryPeople?mergeSources=DIRECTORY_MERGE_SOURCE_TYPE_CONTACT&pageSize=1000&pageToken=${pageToken}&readMask=names%2CemailAddresses%2Corganizations%2CphoneNumbers%2Cphotos&sources=DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE&key=${API_KEY}`
								: `https://people.googleapis.com/v1/people:listDirectoryPeople?mergeSources=DIRECTORY_MERGE_SOURCE_TYPE_CONTACT&pageSize=100&readMask=names%2CemailAddresses%2Corganizations%2CphoneNumbers%2Cphotos&sources=DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE&key=${API_KEY}`,
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then((response) => {
						if (response) {
							if (response.result.nextPageToken) {
								pageToken = response.result.nextPageToken;
							} else {
								pageToken = '';
							}

							response.result.people.forEach((person) => {
								if (person.emailAddresses !== undefined) {
									// if (person.emailAddresses[0]['value'] === 'acourtney@ept911.com') console.log(person);
									newArray.push({
										email: person.emailAddresses[0]['value'],
										name: person.names[0].displayName,
										givenName: person.names[0].givenName,
										familyName: person.names[0].familyName,
										memberType: ((person.organizations ?? [])[0] ?? [])['type'],
										mobile: phoneNumber(person, 'mobile'),
										photo: person.photos ? person.photos[0].url : '',
										officePosition:
											((person.organizations ?? [])[0] ?? [])['type'] === 'Office'
												? goThroughLeadershipPositions(person.emailAddresses[0]['value'], leadershipPositions)
												: '', //finds the position of the office based person
									});
								}
							});
						}
					});
			} while (pageToken !== '');
			const sortArray = newArray.sort((a, b) =>
				a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
			);
			succeeded(sortArray);
		};
		gapi.load('client', initClient);
	});
};

export default GetDirectory;

function phoneNumber(user, phoneType) {
	if (user && user.phoneNumbers) {
		for (let i = 0; i < user.phoneNumbers.map((x) => x.type).length; i++) {
			if (user.phoneNumbers.map((x) => x.type)[i] === phoneType) {
				return user.phoneNumbers.map((x) => x.value)[i];
			}
		} //end for loop
	} //end if statement

	return ''; //returns nothing if no phone listed
}

const goThroughLeadershipPositions = (email, leadershipPositions) => {
	for (let i = 0; i < leadershipPositions.length; i++) {
		if (leadershipPositions[i].currentPerson.email === email) {
			return leadershipPositions[i].positionTitle;
		}
	}

	return '';
};
