import { getFunctions, httpsCallable } from 'firebase/functions';

const DeleteUserAccount = (submitterEmail, submitterName, newData) => {
	return new Promise((resolve) => {
		const item_key = {
			givenName: {
				name: 'First Name',
			},
			lastName: {
				name: 'Last Name',
			},
			memberType: {
				name: 'Member Type',
			},
			homeAddress: {
				name: 'Home Address',
			},
			mobilePhone: {
				name: 'Mobile Phone',
			},
			homePhone: {
				name: 'Home Phone',
			},
			eptEmail: {
				name: 'EPT Email Address',
			},
			recoveryEmail: {
				name: 'Recovery Email',
			},
			credentials: {
				name: 'Credentials',
			},
			position1: {
				name: 'Position',
			},
			position2: {
				name: 'Position',
			},
			position3: {
				name: 'Position',
			},
			position4: {
				name: 'Position',
			},
			legalFirstName: {
				name: 'Legal First Name',
			},
			legalMI: {
				name: 'Legal Middle Initial',
			},
			dob: {
				name: 'Date of Birth',
			},
			employeeID: {
				name: 'Employee ID',
			},
			acepID: {
				name: 'ACEP ID',
			},
			npi: {
				name: 'NPI',
			},
			vaMedicalLicense: {
				name: 'VA Medical License',
			},
			dea: {
				name: 'DEA',
			},
			fellowshipStartDate: {
				name: 'Fellowship Start Date',
			},
			fellowshipEndDate: {
				name: 'Fellowship End Date',
			},
			employeeStartDate: {
				name: 'Employee Start Date',
			},
			partnerDate: {
				name: 'Partner Date',
			},
			termDate: {
				name: 'Resignation Date',
			},
			suspended: {
				name: 'Suspended',
			},
		};
		const functions = getFunctions();
		const deleteUserAccount = httpsCallable(functions, 'deleteUserAccount');
		deleteUserAccount({
			submitterEmail: submitterEmail,
			submitterName: submitterName,
			newData: newData,
			item_key: item_key,
		}).then((result) => {
			// console.log(result);
			if (result === 200) {
				resolve(true);
			} else {
				resolve(result); //can return an error with error message this way
			}
		});
	});
};

export default DeleteUserAccount;
