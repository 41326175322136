import { gapi } from 'gapi-script';

const GetOrgUnits = (token) => {
	return new Promise((resolve) => {
		const initClient = async () => {
			gapi.client.setToken({
				access_token: token,
			});

			try {
				await gapi.client
					.request({
						method: 'GET',
						path: `https://admin.googleapis.com/admin/directory/v1/customer/my_customer/orgunits?type=all`,
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then((response) => {
						// console.log(response);
						resolve(response);
					});
			} catch (error) {
				console.log('Error: ' + error.result.error.message);
				resolve({ title: 'Error', message: error.result.error.message });
			}
		};

		gapi.load('client', initClient);
	});
};

export default GetOrgUnits;
