/**
 * updates an item in the originalArray with the newItem
 * @param {Array} originalArray
 * @param {Object} newItem
 * @param {String} itemId - the identifier of the items in the originalArray and newItem
 * @returns - the originalArray with the newItem inside of it
 */
export default function UpdateArrayItem(originalArray, newItem, itemId) {
	const index = originalArray.findIndex((item) => item[itemId] === newItem[itemId]);

	if (index === -1) {
		// If the item does not exist in the array, add it.
		return [...originalArray, newItem];
	} else {
		// If the item already exists in the array, replace it.
		const updatedItems = [...originalArray];
		updatedItems[index] = newItem;
		return updatedItems;
	}
}
