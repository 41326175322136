/**
 * returns only the changed data & excludes the history
 * @param {object} newData
 * @param {object} originalData
 * @returns {object} object displays on the changed data
 */
export default function LogOnlyChangedData(newData, originalData) {
	let obj = {};

	const keys = Object.keys(newData);
	keys.forEach((key) => {
		if (newData[key] !== originalData[key] && key !== 'history' && key !== 'tickLogs') {
			obj[key] = newData[key];
		}
	});

	return obj;
}
