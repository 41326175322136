import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Box } from '@mui/material';

import { CLIENT_ID, SCOPES } from './SignIn';
import AuthContext from './authContext';
import Tooltip from '../ui/Tooltip';

export default function RefreshToken() {
	const authCtx = useContext(AuthContext);
	const [tokenClient, setTokenClient] = useState(null);

	const requestToken = () => {
		tokenClient.requestAccessToken();
	};

	useEffect(() => {
		if (!tokenClient) {
			const google = window.google;

			google.accounts.id.initialize({
				client_id: CLIENT_ID,
			});

			setTokenClient(
				google.accounts.oauth2.initTokenClient({
					client_id: CLIENT_ID,
					scope: SCOPES,
					hd: 'ept911.com',
					prompt: 'select_account',
					callback: (tokenResponse) => {
						const expirationTime = new Date().getTime() + tokenResponse.expires_in - 1000 * 30; //set to expire 30 seconds before

						authCtx.updateAccessToken(tokenResponse.access_token, expirationTime);
					},
				})
			);
		}
	}, []);

	return (
		<Box sx={{display:'flex', justifyContent:'center'}}>
			<Tooltip text='Click to re-authorize Google to access this feature.'>
				<Button variant='contained' onClick={requestToken}>
					Authorize Google
				</Button>
			</Tooltip>
		</Box>
	);
}
