import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import LoadingSpinner from '../ui/LoadingSpinner';
import Tooltip from '../ui/Tooltip';

export default function EditLOASabbatical(props) {
	const [loading, setLoading] = useState(null);
	const [error, setError] = useState(null);
	const [newEntry, setNewEntry] = useState({ ...props.rowData });
	const [peopleArray, setPeopleArray] = useState([]);

	//gets list of people
	useEffect(() => {
		setLoading(true);
		if (props.allPeople.title === 'Error') {
			setError(props.allPeople.message);
		} else {
			setPeopleArray(props.allPeople);
		}
		setLoading(null);
	}, [props]);

	const handleCurrentPersonChange = (e) => {
		if (e === null) {
			setNewEntry((prevState) => ({
				...prevState,
				name: prevState.name,
				email: prevState.email,
			}));
		} else {
			setNewEntry((prevState) => ({
				...prevState,
				name: e.label,
				email: e.email,
			}));
		}
	};

	const handleCurrentPersonDateChange = (e) => {
		setNewEntry((prevState) => ({
			...prevState,
			[e.id]: e.value,
		}));
	};

	//handles changes
	const handleChange = (e) => {
		setNewEntry((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}));
	};

	//submits the newPosition
	const onSubmit = () => {
		props.submit(newEntry);
	};

	if (loading) {
		return (
			<Dialog open={props.open} onClose={props.cancel} fullWidth>
				<DialogTitle>Edit LOA or Sabbatical</DialogTitle>
				<DialogContent>
					<LoadingSpinner />
				</DialogContent>
				<DialogActions>
					<Button variant='contained' onClick={props.cancel} color='cancel'>
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Dialog
			open={props.open}
			onClose={props.cancel}
			fullWidth
			scroll='body'
			PaperProps={{ sx: { minWidth: '60vw', minHeight: '30vh', padding: '1.5rem' } }}
		>
			<DialogTitle>Edit LOA or Sabbatical</DialogTitle>

			<Box sx={{ width: '100%' }}>
				<DialogContent>
					{/* loa or sabbatical */}
					<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
						<Tooltip text='Select if you are entering a Leave of Absence (LOA) or a Sabbatical.' />
						<span>&nbsp;</span>
						<Autocomplete
							id='LOAorSabbatical'
							options={['LOA', 'Sabbatical']}
							value={newEntry.LOAorSabbatical}
							isOptionEqualToValue={(option, value) => option.key === value.key}
							onChange={(e, newValue) => {
								const event = {
									target: {
										value: newValue,
										id: 'LOAorSabbatical',
									},
								};

								handleChange(event);
							}}
							sx={{ width: '100%' }}
							renderInput={(params) => <TextField {...params} label='LOA or Sabbatical' />}
						/>
					</Box>

					<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
						<Tooltip text='Select the person who will have a LOA or Sabbatical.' />
						<span>&nbsp;</span>
						<Autocomplete
							fullWidth
							ListboxProps={{ style: { maxHeight: '20rem' } }}
							id='currentPerson'
							options={peopleArray}
							sx={{ mt: '0.5rem', mb: '0.75rem' }}
							renderInput={(params) => <TextField {...params} label='Person' />}
							value={newEntry.name === '' ? '' : newEntry.name}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							onChange={(e, newValue) => {
								handleCurrentPersonChange(newValue);
							}}
						/>
					</Box>

					{/* start date & end date*/}
					<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
						<Tooltip text='Enter and Start Date and/or End Date if known.' />
						<span>&nbsp;</span>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label='Start Date'
								value={newEntry.startDate ? dayjs(newEntry.startDate) : null}
								onChange={(newValue) => {
									const event = {
										value: newValue === null ? null : newValue.$d,
										id: 'startDate',
									};
									handleCurrentPersonDateChange(event);
								}}
								sx={{
									width: '100%',
								}}
								components={{
									TextField: TextField,
								}}
								componentsProps={{
									textField: {
										label: 'Start Date',
									},
								}}
							/>
						</LocalizationProvider>
						<span>&nbsp;</span>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label='End Date'
								value={newEntry.endDate ? dayjs(newEntry.endDate) : null}
								onChange={(newValue) => {
									const event = {
										value: newValue === null ? null : newValue.$d,
										id: 'endDate',
									};
									handleCurrentPersonDateChange(event);
								}}
								sx={{
									width: '100%',
								}}
								components={{
									TextField: TextField,
								}}
								componentsProps={{
									textField: {
										label: 'End Date',
									},
								}}
							/>
						</LocalizationProvider>
					</Box>

					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Button variant='contained' onClick={props.cancel} color='inherit' sx={{ mr: 1 }}>
							CANCEL
						</Button>

						<Box sx={{ flex: '1 1 auto' }} />

						<Button variant='contained' onClick={onSubmit}>
							SAVE EDITS
						</Button>
					</Box>
				</DialogContent>
			</Box>
		</Dialog>
	);
}
