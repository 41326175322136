import React, { useContext, Fragment } from 'react';
import { Box, Avatar, Menu, MenuItem, ListItemIcon, IconButton, Typography } from '@mui/material';
import Logout from '@mui/icons-material/Logout';

import Tooltip from './Tooltip';
import AuthContext from '../auth/authContext';
import SignOut from '../auth/SignOut';
import { Stack } from '@mui/system';

var pkg = require('../../../package.json');

export default function AvatarMenu(props) {
	const authCtx = useContext(AuthContext);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	function stringToColor(string) {
		let hash = 0;
		let i;

		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.slice(-2);
		}

		return color;
	}

	function stringAvatar(name) {
		return {
			sx: {
				bgcolor: stringToColor(name),
			},
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
		};
	}

	return (
		<Fragment>
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
				<Tooltip text='Account Settings'>
					<IconButton
						onClick={handleClick}
						size='small'
						sx={{ ml: 2 }}
						aria-controls={open ? 'account-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}
					>
						<Avatar
							src={authCtx.photo}
							{...stringAvatar(props.userName)}
							alt={props.userName}
							sx={{ width: 56, height: 56 }}
						/>
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 28,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<SignOut>
					<MenuItem>
						<Stack direction='row'>
							<ListItemIcon>
								<Logout fontSize='small' />
							</ListItemIcon>
							Log Out
						</Stack>
					</MenuItem>
				</SignOut>

				<br />
				<Box sx={{ alignContent: 'center', display: 'flex', justifyContent: 'center' }}>
					<Typography variant='caption'>Version {pkg.version}</Typography>
				</Box>
			</Menu>
		</Fragment>
	);
}
