import React, { useState, useEffect } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

/**
 * @param {string} title - title for the accordian
 * @param {string} subtitle - subtitle for the accordian
 * @param {boolean} parent - true if this is a parent, false if this is a child
 */
export default function AccordionUI(props) {
	const [expanded, setExpanded] = useState(false);
	const [borderBottomSetting, setBorderBottomSetting] = useState('');

	useEffect(() => {
		if (props.parent) {
			setBorderBottomSetting('');
		} else {
			setBorderBottomSetting(
				`'&:not(:last-child)': {
                    borderBottom: 0,
                },}`
			);
		}
	}, [props.parent]);

	//handles accordion panel expansion
	const handleAccordionPanelChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	//closes the accordion panel when closePanel is set to true and panel is already open
	useEffect(() => {
		if (props.closePanel) setExpanded(false);
	}, [props.closePanel]);

	return (
		<Container sx={{ marginBottom: props.parent ? '2rem' : '0rem' }}>
			<Accordion
				square
				disableGutters={true}
				elevation={0}
				expanded={expanded === `accordian`}
				onChange={handleAccordionPanelChange(`accordian`)}
				sx={{
					border: `1px solid lightgrey`,
					borderBottomSetting,
					'&:before': {
						display: 'none',
					},
					margin: '0px !important',
				}}
			>
				<AccordionSummary
					expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
					aria-controls={`content`}
					id={`header`}
					sx={{
						backgroundColor: '#f0f0f0',
						flexDirection: 'row-reverse',
						'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
							transform: 'rotate(90deg)',
						},
						'& .MuiAccordionSummary-content': {
							marginLeft: '1rem',
						},
					}}
				>
					<Typography sx={{ width: '50%', flexShrink: 0 }}>{props.title}</Typography>
					<Typography variant='caption' sx={{ color: 'gray' }}>
						{props.subtitle}
					</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ backgroundColor: props.parent ? '#f9f9f9' : 'white' }}>
					<Container>{props.children}</Container>
				</AccordionDetails>
			</Accordion>
		</Container>
	);
}
