import React from 'react';
import TooltipMUI from '@mui/material/Tooltip';
// import CircleNotificationsSharpIcon from '@mui/icons-material/CircleNotificationsSharp';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';

/**
 * If props.children are passed to Tooltip, then no icon is shown.  If no children are passed, then an icon is displayed.
 * @param {string} text - tooltip's text
 * @param {string} placement - Top (default), Right, Bottom, Left
 * @returns tooltip bubble with an arrow component
 */
export default function Tooltip(props) {
	let placement = 'top';
	if (props.placement) {
		placement = props.placement;
	}

	return (
		<TooltipMUI
			title={props.text}
			placement={placement}
			arrow
			// TransitionComponent={Zoom}
			// enterDelay={200}
			// leaveDelay={100}
			// sx={{ fontSize: '1rem' }}
		>
			<span>{props.children ? props.children : <NotificationsNoneRoundedIcon fontSize='small' color='info' />}</span>
		</TooltipMUI>
	);
}
