//React 18
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import './index.css';
import App from './App';
import { AuthContextProvider } from './components/auth/authContext';
import { AlertContextProvider } from './components/ui/AlertContext';
import { CustomClaimsContextProvider } from './components/auth/CustomClaimsContext';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<AuthContextProvider>
		<CustomClaimsContextProvider>
			<AlertContextProvider>
				<BrowserRouter>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<App />
					</LocalizationProvider>
				</BrowserRouter>
			</AlertContextProvider>
		</CustomClaimsContextProvider>
	</AuthContextProvider>
);
