/**
 * restricted dashboard settings
 */
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
export const restrictedTo = [
	'Admin',
	'Board of Directors',
	'EPT APP',
	'EPT Physician',
	'Office',
	'OPT APP',
	'OPT Physician',
	'PBC Committee',
];

export default { MenuProps, restrictedTo };
