import React, { useEffect, useState, Fragment, useMemo, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Box, Typography } from '@mui/material';
import PageCards from '../components/ui/PageCards';
import { MaterialReactTable } from 'material-react-table';
import AlertContext from '../components/ui/AlertContext';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';
import MRT_TABLE from '../components/ui/MRT_TABLE';

export default function UniqueIDs() {
	const claims = useContext(CustomClaimsContext);
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState([]);
	const [globalFilter, setGlobalFilter] = useState('');

	// Define table columns aligned with sample data using accessorFn
	const columns = useMemo(
		() => [
			{
				header: 'Name',
				accessorFn: (row) => row.name?.fullName || '', // Safely access nested property
				id: 'name', // Unique ID for the column
			},
			{
				header: 'Unique ID',
				accessorFn: (row) => row.customSchemas?.EPT_Details?.UniqueID || '', // Safely access nested property
				id: 'uniqueID', // Unique ID for the column
			},
		],
		[]
	);

	useEffect(() => {
		if (data.length === 0) {
			setIsLoading(true);
			const functions = getFunctions();
			const getMemberInfo = httpsCallable(functions, 'getUniqueIDs');
			getMemberInfo({ admin: claims?.claims?.admin })
				.then((result) => {
					if (result.data.code === 200) setData(result.data.data);
					else {
						alertCtx.setSeverity(result.data.status.toLowerCase());
						alertCtx.setMessage(result.data.message);
						alertCtx.setActive(true);
					}
					setIsLoading(false);
				})
				.catch((error) => {
					console.error('Error fetching unique IDs:', error);
					alertCtx.setSeverity('error');
					alertCtx.setMessage('Failed to fetch unique IDs.');
					alertCtx.setActive(true);
					setIsLoading(false);
				});
		}
	}, [claims?.claims?.admin, data, alertCtx]);

	// Memoize data to prevent unnecessary re-renders
	const memoizedData = useMemo(() => data, [data]);

	return (
		<Fragment>
			<PageCards>
				<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
					Unique IDs
				</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center' }} mb={2}>
					<Typography variant='subtitle1' textAlign='center' sx={{ maxWidth: '70%' }}>
						The IDs below are unique and are guaranteed to not be represented elsewhere. These IDs should be used to
						blind a provider when referring to them about sensitive information in documents and minutes.
					</Typography>
				</Box>

				{claims?.claims?.admin && (
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<MRT_TABLE
							columns={columns}
							data={data}
							sortBy={'name'}
							loading={isLoading}
							pageSize={20}
							maxHeight='65vh'
						/>
					</Box>
				)}
			</PageCards>
			<br />
		</Fragment>
	);
}
