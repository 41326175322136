import React, { useContext, useEffect, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import dayjs from 'dayjs';

import { Box, Button, Dialog, DialogActions, DialogContent, Typography, TextField } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import LoadingSpinner from '../ui/LoadingSpinner';
import PreviewTable from './DashboardModal_Preview_Table';
import AlertContext from '../ui/AlertContext';
import Tooltip from '../ui/Tooltip';
import RemoveTimeZone from './RemoveTimeZone';
import { checkCondition, performCalculation } from './checkConditionANDperformCalculation';

function isEmpty(obj) {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 *
 * @param {*} cancel - pass a cancel prop
 * @param {*} open - pass an open prop
 * @returns
 */
export default function DashboardModal_Preview(props) {
	const alertCtx = useContext(AlertContext);
	const [dashboard, setDashboard] = useState(props.dashboard);
	const [sheets, setSheets] = useState(props.sheets);
	const [dataTypes, setDataTypes] = useState(props.dataTypes);
	const [valid, setValid] = useState({ state: null, message: '' });
	const [ReportDate, setReportDate] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [uploaded, setUploaded] = useState({ total: 0, uploaded: 0 });

	//checks validity, if valid enables Save button
	useEffect(() => {
		let tempValid = true;
		let tempMessage = '';

		if (!ReportDate) {
			tempValid = false;
			tempMessage = 'A date must be selected for the Data Creation Date.';
		}

		//only keep validating if not already set to false
		if (tempValid) {
			sheets.map((sheet, sheetIndex) => {
				//if sheet included
				if (sheet.include) {
					//check expectedSheetName
					if (sheet.expectedSheetName === '') {
						tempValid = false;
						tempMessage = 'Each included sheet must have a sheet name set.';
					}

					//only keep validating if not already set to false
					if (tempValid) {
						sheet.headers.map((header, headerIndex) => {
							//if column included
							if (header.include) {
								//check if it has an assigned an name (expected field)
								if (header.name === '') {
									tempValid = false;
									tempMessage = 'Each column must be an Expected Field.';
								}
							}
						});
					}
				}
			});
			//make sure no two sheets have the same expectedSheetName
			for (let i = 0; i < sheets.length - 1; i++) {
				for (let j = i + 1; j < sheets.length; j++) {
					if (sheets[i].expectedSheetName === sheets[j].expectedSheetName) {
						tempValid = false;
						tempMessage = 'No two sheets can have the same Expected Sheet Name.';
					}
				}
			}
		}

		setValid({ state: tempValid, message: tempMessage });
		// console.log(tempValid);
	}, [sheets, ReportDate]);

	//handles data creation ReportDate change
	const handleDateChange = (newDate) => {
		setReportDate(new Date(new Date(newDate).getTime() + new Date(newDate).getTimezoneOffset() * 60000));
	};

	//handles changes to the sheet name, which is part of the headers
	const handleExpectedSheetNameChange = (sheetIndex, expectedSheetName, expectedSheetIndex) => {
		setSheets((prevData) => {
			const newData = [...prevData];
			const updatedSheet = {
				...newData[sheetIndex],
				expectedSheetName: expectedSheetName,
				expectedSheetIndex: expectedSheetIndex,
			};
			newData[sheetIndex] = updatedSheet;
			return newData;
		});
	};

	//handles changes to the sheet switch, which are part of the headers
	const handleSheetSwitchChange = (sheetIndex) => {
		setSheets((prevData) => {
			const newData = [...prevData];
			const newSwitchState = {
				...newData[sheetIndex],
				include: !newData[sheetIndex].include,
			};
			newData[sheetIndex] = newSwitchState;
			return newData;
		});
	};

	//handles changes to the headers
	const handleHeaderChangeName = (newName, headerIndex, sheetIndex) => {
		setSheets((prevData) => {
			const newData = [...prevData];
			const newHeaderType = {
				...newData[sheetIndex].headers[headerIndex],
				name: newName,
			};
			newData[sheetIndex].headers[headerIndex] = newHeaderType;
			return newData;
		});
	};

	//handles changes to the header switches, which are part of the headers
	const handleHeaderSwitchChange = (headerIndex, sheetIndex) => {
		setSheets((prevData) => {
			const newData = [...prevData];
			const newSwitchState = {
				...newData[sheetIndex].headers[headerIndex],
				include: !newData[sheetIndex].headers[headerIndex].include,
			};
			newData[sheetIndex].headers[headerIndex] = newSwitchState;
			return newData;
		});
	};

	//handles save button
	const handleSubmit = async () => {
		setUploading(true);

		const data = [];
		const data_PHI = [];

		//loop through all data of each sheet
		sheets.map((sheet) => {
			if (sheet.include) {
				sheet.data.map((row, rowIndex) => {
					/**
					 * go through each row of data
					 * exclude header row
					 */
					if (rowIndex !== 0) {
						const dataObj = {};
						const dataObjPHI = {};
						let PHI_Exists = false;

						/**
						 * No PHI Data
						 */
						sheet.headers.forEach((header, headerIndex) => {
							//check headers to see if column is included
							if (header.include) {
								for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
									if (headerIndex === cellIndex) {
										//if no phi, include data
										if (!header.phi) {
											if (header.type === 'Date') dataObj[header.name] = RemoveTimeZone(row[cellIndex]);
											else dataObj[header.name] = row[cellIndex];
										}
										if (header.phi) PHI_Exists = true; //sets to true if there is PHI to trigger next section
									}
								}
							}
						});

						/**
						 * PHI Data - if any headers have PHI, log all data
						 */
						if (PHI_Exists) {
							sheet.headers.forEach((header, headerIndex) => {
								//check headers to see if column is included
								if (header.include) {
									for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
										if (headerIndex === cellIndex) {
											if (header.type === 'Date') dataObjPHI[header.name] = RemoveTimeZone(row[cellIndex]);
											else dataObjPHI[header.name] = row[cellIndex];
											if (header.phiKey) dataObjPHI.Key = row[cellIndex]; //save phiKey as 'key'
										}
									}
								}
							});
						}

						/**
						 * perform custom calculations on each row of data
						 * only performs calculations if data exists
						 */
						sheet?.calculatedColumns?.map((column, columnIndex) => {
							/**
							 * dynamic rule format for each calculatedColumn
							 * { ruleName: "Rule1",
							 * conditions: [{
							 * conditionName: "Sheet",
							 * conditionType: "EQUALS",
							 * conditionValue: "SpecificString",
							 * }],
							 * calculation: {
							 * calculationType: "SUBTRACT",
							 * calculationColumn1: 'STRING',
							 * calculationColumn2: "ReportDate",
							 * comparisonOperator: '>'
							 * },
							 * editable: true,
							 * }
							 */
							if (!isEmpty(dataObj)) {
								if (!column?.conditions?.[0]?.conditionType || checkCondition(column.conditions, dataObj)) {
									dataObj[column.ruleName] = performCalculation(
										column.calculation,
										dataObj,
										sheet.sheetName,
										ReportDate
									);
								}
							}
							if (!isEmpty(dataObjPHI)) {
								if (!column?.conditions?.[0]?.conditionType || checkCondition(column.conditions, dataObjPHI)) {
									dataObjPHI[column.ruleName] = performCalculation(
										column.calculation,
										dataObjPHI,
										sheet.sheetName,
										ReportDate
									);
								}
							}
						});

						/**
						 * push to dataObj and dataObjPHI to upload
						 */
						if (!isEmpty(dataObj))
							data.push({ data: { ...dataObj }, path: `dashboards/${dashboard.id}/${dashboard.id}-Data/` });
						if (!isEmpty(dataObjPHI))
							data_PHI.push({
								data: { ...dataObjPHI },
								path: `dashboards/${dashboard.id}/${dashboard.id}-Data-PHI/`,
							});
					}
				});
			}
		});

		// console.log('data', data);
		// console.log('data_PHI', data_PHI);

		const functions = getFunctions();
		const dashboardDataUpload = httpsCallable(functions, 'dashboardDataUploadV3');
		setUploaded({ total: data.length + data_PHI.length, uploaded: 0 });
		let count = 0;
		let dataUploaded = true;
		let tempNonPHIData = [];
		let tempPHIData = [];

		/**
		 * upload non-PHI data via function
		 */
		for (const item of data) {
			if (count < 500) {
				tempNonPHIData.push(item);
				count += 1;
			} else {
				await dashboardDataUpload({ data: tempNonPHIData }).then((result) => {
					if (result.data.status !== 200) dataUploaded = false;
					setUploaded((prevData) => {
						return { total: prevData.total, uploaded: prevData.uploaded + 500 };
					});
					count = 0;
					tempNonPHIData = [];
				});
			}
		}
		/**
		 * uploads remaining non-PHI data via function
		 */
		await dashboardDataUpload({ data: tempNonPHIData }).then((result) => {
			if (result.data.status !== 200) dataUploaded = false;
			setUploaded((prevData) => {
				return { total: prevData.total, uploaded: prevData.uploaded + tempNonPHIData.length };
			});
		});

		/**
		 * upload PHI data via function
		 */
		count = 0;
		for (const item of data_PHI) {
			if (count < 500) {
				tempPHIData.push(item);
				count += 1;
			} else {
				await dashboardDataUpload({ data: tempPHIData }).then((result) => {
					if (result.data.status !== 200) dataUploaded = false;
					setUploaded((prevData) => {
						return { total: prevData.total, uploaded: prevData.uploaded + 500 };
					});
					count = 0;
					tempPHIData = [];
				});
			}
		}
		/**
		 * uploads remaining PHI data via function
		 */
		await dashboardDataUpload({ data: tempPHIData }).then((result) => {
			if (result.data.status !== 200) dataUploaded = false;
			setUploaded((prevData) => {
				return { total: prevData.total, uploaded: prevData.uploaded + tempPHIData.length };
			});
		});

		/**
		 * sets alerts if data was uploaded or not
		 */
		if (dataUploaded) {
			alertCtx.setActive(true);
			alertCtx.setSeverity('success');
			alertCtx.setMessage('Data file was successfully uploaded.');
			alertCtx.setTimer(10000);
			setUploading(false);
			props.cancel();
		} else {
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage('Data file was <strong>not uploaded</strong> correctly.  Refresh the page and try again.');
			setUploading(false);
			props.cancel();
		}
	};

	return (
		<Dialog
			open={props.open}
			onClose={props.cancel}
			fullWidth
			scroll='paper'
			PaperProps={{ sx: { minHeight: '90%', minWidth: '90%' } }}
		>
			<Typography variant='h4' fontWeight='bold' textAlign='center' mt={2} color='primary'>
				{dashboard.name} Dashboard
			</Typography>
			<Typography variant='h5' fontWeight='normal' textAlign='center' color='grey'>
				Preview Data to be Uploaded
			</Typography>

			{/* if not uploading - i.e. displaying preview */}
			{!uploading && (
				<DialogContent>
					{/* creation ReportDate picker */}
					<Box mt={1} mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label='Data Creation Date'
								value={ReportDate ? dayjs(ReportDate) : null}
								onChange={(newValue) => {
									const newDate = `${newValue.$y}-${('0' + (newValue.$M + 1)).slice(-2)}-${('0' + newValue.$D).slice(
										-2
									)}`;
									handleDateChange(newDate);
								}}
								sx={{
									width: '100%',
								}}
								components={{
									TextField: TextField,
								}}
								componentsProps={{
									textField: {
										label: 'Data Creation Date',
									},
								}}
							/>
						</LocalizationProvider>
					</Box>

					{/* sheet preview table */}
					<Box alignContent='center' alignItems='center'>
						{sheets &&
							sheets.map((sheet, sheetIndex) => (
								<PreviewTable
									sheet={sheet}
									sheetIndex={sheetIndex}
									dataTypes={dataTypes}
									key={sheetIndex}
									dashboard={dashboard}
									onHeaderChangeName={handleHeaderChangeName}
									onHeaderSwitchChange={handleHeaderSwitchChange}
									onSheetSwitchChange={handleSheetSwitchChange}
									onExpectedSheetNameChange={handleExpectedSheetNameChange}
									ReportDate={ReportDate}
								/>
							))}
					</Box>
				</DialogContent>
			)}

			{/* if uploading */}
			{uploading && (
				<DialogContent>
					<Box mt={20}>
						<Typography variant='h5' textAlign='center' mt={2} color='secondary'>
							Uploaded {uploaded.uploaded} of {uploaded.total} entries...
						</Typography>{' '}
						<LoadingSpinner />
					</Box>
				</DialogContent>
			)}

			<DialogActions sx={{ backgroundColor: 'lightgrey' }}>
				{/* display messages from valid.message if one exists */}
				{!valid.state && (
					<Box mr={3} sx={{ display: 'flex', justifyContent: 'right' }}>
						<Tooltip text={valid.message} placement='left'>
							<ErrorIcon color='error' />
						</Tooltip>
					</Box>
				)}
				<Button variant='contained' onClick={props.cancel} color='cancel'>
					Cancel
				</Button>
				<Button variant='contained' onClick={handleSubmit} disabled={!valid.state || uploading}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
