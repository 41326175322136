import React, { useContext, useEffect } from 'react';
import AuthContext from './components/auth/authContext';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export default function ProtectedRoutes() {
	const authCtx = useContext(AuthContext);

	const useAuth = () => {
		return authCtx?.isLoggedIn;
	};

	const location = useLocation();
	const isLoggedIn = useAuth();

	const fromLocation = { from: location };

	useEffect(() => {
		localStorage.setItem('fromLocation', JSON.stringify(fromLocation));
	}, [fromLocation]);

	return isLoggedIn ? <Outlet /> : <Navigate to='/login' state={fromLocation} replace={true} />;
}
