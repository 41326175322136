import React from 'react';
import { Stack, Chip, Box } from '@mui/material';

export default function CATStipendColumnsTotalYearly(props) {
	const row = props.row;
	const firstYearMonth = props.firstYearMonth;
	const secondYearMonth = props.secondYearMonth;
	const thirdYearMonth = props.thirdYearMonth;
	const fourthYearMonth = props.fourthYearMonth;
	const fifthYearMonth = props.fifthYearMonth;
	const sixthYearMonth = props.sixthYearMonth;

	const monthArray = [firstYearMonth, secondYearMonth, thirdYearMonth, fourthYearMonth, fifthYearMonth, sixthYearMonth];

	let totalHours = 0;
	let allottedHours = 0;
	for (const month of monthArray) {
		if (row.original.tickLogs[`${month}`] && row.original.tickLogs[`${month}`].length > 0) {
			for (const log of row.original.tickLogs[`${month}`]) {
				totalHours += Number(log.totalHours);
				allottedHours += Number(log.allottedHours);
			}
		}
	}

	totalHours = Math.round(totalHours * 10) / 10;
	allottedHours = Math.round(allottedHours * 10) / 10;

	return (
		<Box display='flex' justifyContent='center'>
			<Stack direction='row' spacing={1}>
				<Chip
					label={`${
						allottedHours === 0
							? 'N/A'
							: `${(totalHours * 10) / 10}  of
							 ${(allottedHours * 10) / 10} hours`
					}`}
					variant={(totalHours / allottedHours) * 100 > 100 ? 'filled' : 'outlined'}
					color={(totalHours / allottedHours) * 100 > 100 ? 'otherButton' : 'primary'}
					sx={{
						display: 'flex',
						minHeight: '2rem',
						height: 'auto',
						justifyContent: 'center',
						textAlign: 'center',
						alignItems: 'center',
						'& .MuiChip-label': {
							display: 'flex',
							wordWrap: 'normal',
							whiteSpace: 'normal',
							textOverflow: 'clip',
							textAlign: 'center',
							justifyContent: 'center',
							width: '100px',
						},
						padding: '2px',
					}}
				/>
			</Stack>
		</Box>
	);
}
