/**
 * Helper function to evaluate conditions
 * @param {array} conditions - an array of objects with keys of conditionName, conditionType and conditionValue
 * @param {object} dataObj - the object to check conditions on
 * @returns default value of false, otherwise returns if value compared to conditionValue is true or false
 */
export function checkCondition(conditions, dataObj) {
	// Check for OR conditions (any condition is met)
	return conditions.some((condition) => {
		switch (condition.conditionType) {
			case 'EQUALS':
				return dataObj[condition.conditionName] === condition.conditionValue;
			case 'GREATER_THAN':
				return dataObj[condition.conditionName] > condition.conditionValue;
			case 'LESS_THAN':
				return dataObj[condition.conditionName] < condition.conditionValue;
			case 'NOT_EQUALS':
				return dataObj[condition.conditionName] !== condition.conditionValue;
			// ... add more condition types as needed
			case 'BOOLEAN':
				return Boolean(dataObj[condition.conditionName]);
			default:
				return false;
		}
	});
}

/**
 * Helper function to perform calculations
 * @param {object} calculation -  object with keys of calculationType, calculationColumn1 and calculationColumn2
 * @param {object} dataObj - the object to check conditions on
 * @param {string} sheetName - the name of the sheet
 * @param {date} ReportDate - the date the report was created
 * @returns default value is null, otherwise returns result of calculationType applied to calculationColumn1 and calculationColumn2
 */
export function performCalculation(calculation, dataObj, sheetName, ReportDate) {
	// console.log('Provided ReportDate:', ReportDate);
	// console.log('Calculation:', calculation);
	// console.log('dataObj:', dataObj);
	// console.log('Report Date:', ReportDate);

	const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
	const a = isNaN(calculation.calculationColumn1)
		? dataObj[calculation.calculationColumn1]
		: calculation.calculationColumn1;
	const b = isNaN(calculation.calculationColumn2)
		? dataObj[calculation.calculationColumn2]
		: calculation.calculationColumn2;
	let differenceInMilliseconds, date;

	switch (calculation.calculationType) {
		case 'ADD':
			return a + b;
		case 'SUBTRACT':
			return a - b;
		case 'MULTIPLY':
			return a * b;
		case 'DIVIDE':
			return b !== 0 ? a / b : null; // Avoid division by zero
		case 'BOOLEAN_CONDITION':
			switch (calculation.comparisonOperator) {
				case '>':
					return a > b;
				case '<':
					return a < b;
				case '>=':
					return a >= b;
				case '<=':
					return a <= b;
				case '===':
					return a === b;
				case '!==':
					return a !== b;
				default:
					return null; // or throw an error for an unsupported operator
			}
		case 'REPORT_DATE':
			return new Date(ReportDate);
		case 'SHEET':
			return sheetName;
		case 'DATE_ADD':
			// Assuming value and calculationValue are both date strings
			differenceInMilliseconds = a + b;
			return differenceInMilliseconds / MILLISECONDS_IN_A_DAY;
		case 'DATE_SUBTRACT_WITH_TIME':
			// Use date components for subtraction
			// Does not ignore time differences such as Daylight Savings, which will impact the calculation

			differenceInMilliseconds = a - b;
			return differenceInMilliseconds / MILLISECONDS_IN_A_DAY;
		case 'DATE_SUBTRACT_WITHOUT_TIME':
			// Use UTC date components for subtraction, ignoring time differences such as Daylight Savings
			// Convert a and b to Date objects if they're strings

			const date1 = typeof a === 'string' ? new Date(a) : a;
			const date2 = typeof b === 'string' ? new Date(b) : b;

			const date1UTC = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
			const date2UTC = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

			differenceInMilliseconds = date1UTC - date2UTC;
			return differenceInMilliseconds / MILLISECONDS_IN_A_DAY;
		case 'ADD_DAYS':
			// Assuming value is a date string and calculationValue is a number of days
			date = new Date(a);
			date.setDate(a.getDate() + b);
			return date;
		case 'SUBTRACT_DAYS':
			// Assuming value is a date string and calculationValue is a number of days
			date = new Date(a);
			date.setDate(a.getDate() - b);
			return date;
		// ... add more calculation types as needed
		default:
			return null;
	}
}
