/**
 * deals with contact phone numbers
 * deals with multiple phone numbers
 * @param {object} user The user object.
 * @param {string} phoneType The type of phone to look for.
 * @return The user phone number, or null if no phone number
 */
function phoneNumber(user, phoneType) {
	if (user && user.phones) {
		for (let i = 0; i < user.phones.map((x) => x.type).length; i++) {
			if (user.phones.map((x) => x.type)[i] === phoneType) {
				return user.phones.map((x) => x.value)[i];
			}
		} //end for loop
	} //end if statement

	return null; //returns nothing if no phone listed
}

export default phoneNumber;
