export default function AreArraysEqual(array1, array2) {
    if (!array1 || !array2 || array1.length !== array2.length) {
        return false;
    }

    for (let i = 0; i < array1.length; i++) {
        const item1 = array1[i];
        const item2 = array2[i];

        if (Array.isArray(item1) && Array.isArray(item2)) {
            if (!AreArraysEqual(item1, item2)) {
                return false;
            }
        } else if (item1 && typeof item1 === 'object' && item2 && typeof item2 === 'object') {
            if (!areObjectsEqual(item1, item2)) {
                return false;
            }
        } else if (item1 !== item2) {
            return false;
        }
    }

    return true;
}

function areObjectsEqual(object1, object2) {
    if (!object1 || !object2 || Object.keys(object1).length !== Object.keys(object2).length) {
        return false;
    }

    for (const key in object1) {
        const value1 = object1[key];
        const value2 = object2[key];

        if (Array.isArray(value1) && Array.isArray(value2)) {
            if (!AreArraysEqual(value1, value2)) {
                return false;
            }
        } else if (value1 && typeof value1 === 'object' && value2 && typeof value2 === 'object') {
            if (!areObjectsEqual(value1, value2)) {
                return false;
            }
        } else if (value1 !== value2) {
            return false;
        }
    }

    return true;
}
