import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { signOut, GoogleAuthProvider } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { onSnapshot, doc } from 'firebase/firestore';

import { SCOPES } from './SignIn';
import { auth } from '../../App';
import { db } from '../../App';

const provider = new GoogleAuthProvider();
for (const scope of SCOPES) provider.addScope(scope);
// provider.addScope(SCOPES);
provider.setCustomParameters({
	hd: 'ept911.com',
});

const AuthContext = React.createContext({
	token: null,
	tokenExpiration: '',
	isLoggedIn: false,
	memberType: undefined,
	isEPT: false,
	isOPT: false,
	phiKeys: null,
	login: (token) => {},
	logout: () => {},
	updateAccessToken: (token, tokenExpiration) => {},
	photo: '',
	userName: '',
	email: '',
	dataRetrieved: false,
});

/**
 * calculates the remaining time before the token expires
 * @param {number} expirationTime
 * @returns the time remaining in milliseconds
 */
const calculateRemainingTime = (expirationTime) => {
	expirationTime = Number(expirationTime);

	const adjustedExpirationTime = new Date(expirationTime);
	const expirationDate = new Date(adjustedExpirationTime);

	const remainingDuration = expirationDate - new Date();
	// const remainingDuration = 10 * 1000;
	// console.log(remainingDuration / 1000);

	return remainingDuration;
};

//retrieves the login data if stored locally
const RetrieveStoredToken = () => {
	const storedToken = localStorage.getItem('token');
	const storedTokenExpiration = localStorage.getItem('tokenExpiration');

	//do nothing if no storedToken
	if (storedToken !== null && localStorage.getItem('logoutTime') !== null) {
		const storedExpirationDate = localStorage.getItem('logoutTime');
		const remainingDuration = calculateRemainingTime(storedExpirationDate);
		const storedPhoto = localStorage.getItem('photo');
		const storedUserName = localStorage.getItem('userName');
		const storedEmail = localStorage.getItem('email');
		const storedMemberType = localStorage.getItem('memberType');

		let storedIsEPT = localStorage.getItem('isEPT');
		if (storedIsEPT === 'true') storedIsEPT = true;
		else storedIsEPT = false;

		let storedIsOPT = localStorage.getItem('isOPT');
		if (storedIsOPT === 'true') storedIsOPT = true;
		else storedIsOPT = false;

		let storedDataRetrieved = localStorage.getItem('dataRetrieved');
		if (storedDataRetrieved === 'true') storedDataRetrieved = true;
		else storedDataRetrieved = false;

		let storedPHIKeys = JSON.parse(localStorage.getItem('phiKeys'));

		//logout if storedExpirationDate has expired
		if (new Date(Number(storedExpirationDate)) < new Date()) {
			return {
				token: storedToken,
				tokenExpiration: storedTokenExpiration,
				remainingDuration: remainingDuration,
				photo: storedPhoto,
				userName: storedUserName,
				email: storedEmail,
				memberType: storedMemberType,
				isEPT: storedIsEPT,
				isOPT: storedIsOPT,
				phiKeys: storedPHIKeys,
				refresh: false,
				resetTimer: false,
				logout: true,
				dataRetrieved: storedDataRetrieved,
			};
		}

		//refresh if remainingDuration < 60 seconds
		if (remainingDuration < 60 * 1000) {
			// if (remainingDuration > 0 && remainingDuration < 10 * 1000) {
			// console.log('attempt refresh');
			return {
				token: storedToken,
				tokenExpiration: storedTokenExpiration,
				remainingDuration: remainingDuration,
				photo: storedPhoto,
				userName: storedUserName,
				email: storedEmail,
				memberType: storedMemberType,
				isEPT: storedIsEPT,
				isOPT: storedIsOPT,
				phiKeys: storedPHIKeys,
				refresh: true,
				resetTimer: false,
				logout: false,
				dataRetrieved: storedDataRetrieved,
			};
		}
		//else return data and resetTimer
		else {
			return {
				token: storedToken,
				tokenExpiration: storedTokenExpiration,
				remainingDuration: remainingDuration,
				photo: storedPhoto,
				userName: storedUserName,
				email: storedEmail,
				memberType: storedMemberType,
				isEPT: storedIsEPT,
				isOPT: storedIsOPT,
				phiKeys: storedPHIKeys,
				refresh: false,
				resetTimer: true,
				logout: false,
				dataRetrieved: storedDataRetrieved,
			};
		}
	}
};

//authContext main function
export const AuthContextProvider = (props) => {
	//checks if data is stored locally first
	const tokenData = useRef(useMemo(() => RetrieveStoredToken(), []));

	// let tokenData = RetrieveStoredToken();
	const refreshTimes = useRef(0);
	if (localStorage.getItem('refreshTimes') !== null) {
		refreshTimes.current = Number(localStorage.getItem('refreshTimes'));
	}
	const refreshTimer = useRef('');

	const initialToken = tokenData?.current?.token || null;
	const initialTokenExpiration = tokenData?.current?.tokenExpiration || null;
	const initialPhoto = tokenData?.current?.photo || null;
	const initialUserName = tokenData?.current?.userName || null;
	const initialEmail = tokenData?.current?.email || null;
	const initialMemberType = tokenData?.current?.memberType || null;
	const initialIsEPT = tokenData?.current?.isEPT || null;
	const initialIsOPT = tokenData?.current?.isOPT || null;
	const initialPHIKeys = tokenData?.current?.phiKeys || null;
	const initialDataRetrieved = tokenData?.current?.dataRetrieved || null;

	const [token, setToken] = useState(initialToken);
	const [tokenExpiration, setTokenExpiration] = useState(initialTokenExpiration);
	const [photo, setPhoto] = useState(initialPhoto);
	const [userName, setUserName] = useState(initialUserName);
	const [email, setEmail] = useState(initialEmail);
	const [memberType, setMemberType] = useState(initialMemberType);
	const [isEPT, setIsEPT] = useState(initialIsEPT);
	const [isOPT, setIsOPT] = useState(initialIsOPT);
	const [phiKeys, setPHIKeys] = useState(initialPHIKeys);
	const [dataRetrieved, setDataRetrieved] = useState(initialDataRetrieved);
	const userIsLoggedIn = !!token;

	//logs user out and resets all localStorage and state
	const logoutHandler = useCallback(() => {
		// sign user out
		signOut(auth)
			.then(() => {
				// console.log('logged out');
				setToken(null);
				setTokenExpiration(null);
				setPhoto(null);
				setUserName(null);
				clearTimeout(refreshTimer.current);
				localStorage.clear();
				tokenData.current = null;
				refreshTimes.current = '';
			})
			.catch((error) => {
				console.error(error);
				setToken(null);
				setTokenExpiration(null);
				setPhoto(null);
				setUserName(null);
				clearTimeout(refreshTimer.current);
				localStorage.clear();
				tokenData.current = null;
				refreshTimes.current = '';
				logoutHandler();
			});
	}, [auth]);

	const refreshToken = async () => {
		if (refreshTimes.current < 12) {
			// console.log(refreshTimes.current);
			try {
				const user = auth.currentUser;
				if (user) {
					const tokenResult = await user.getIdTokenResult(true);
					const expirationTime = tokenResult.expirationTime;
					const photo = user.photoURL;
					const userName = user.displayName;
					const email = user.email;

					const functions = getFunctions();
					const verifyEPTEmailWithCustomClaimsV2 = httpsCallable(functions, 'verifyEPTEmailWithCustomClaimsV2');
					verifyEPTEmailWithCustomClaimsV2({}).then((result) => {
						const verified = result.data.verified;

						if (verified) {
							loginHandler(expirationTime, photo, userName, email);
							refreshTimes.current = refreshTimes.current + 1;
						} else {
							logoutHandler();
						}
					});
				}
			} catch (error) {
				console.log(error);
				logoutHandler();
			}
		}
		// sign user out
		else {
			logoutHandler();
		}
	};

	//stores accessToken
	const updateAccessToken = (accessToken, accessTokenExpirationTime) => {
		setToken(accessToken);
		setTokenExpiration(accessTokenExpirationTime);
		localStorage.setItem('token', accessToken);
		localStorage.setItem('tokenExpiration', accessTokenExpirationTime);
	};

	//logs user in
	const loginHandler = (expirationTime, photo, userName, email) => {
		// console.log('logging in');
		setPhoto(photo);
		setUserName(userName);
		setEmail(email);

		clearTimeout(refreshTimer.current);
		const remainingTime = calculateRemainingTime(expirationTime - 1000 * 30);
		// console.log(remainingTime);
		refreshTimer.current = setTimeout(refreshToken, remainingTime);

		localStorage.setItem('photo', photo);
		localStorage.setItem('userName', userName);
		localStorage.setItem('email', email);
		localStorage.setItem('logoutTime', new Date().getTime() + remainingTime);
	};

	//sets custom claims
	let unsub = () => {};
	const [user, setUser] = useState(null);
	const previousToken = useRef(null);
	auth.onAuthStateChanged((user) => {
		setUser(user);
	});

	if (user && auth?.currentUser?.uid) {
		// console.log('dataRetrieved:', dataRetrieved);
		unsub = onSnapshot(doc(db, `Users`, auth.currentUser.uid), async (doc) => {
			// console.log('doc', doc.data());
			const newToken = await user.getIdTokenResult(true);

			if (newToken.token !== previousToken.current) {
				// console.log(newToken.claims);

				//sets memberType
				setMemberType(newToken.claims.memberType);
				localStorage.setItem('memberType', newToken.claims.memberType);

				//sets isEPT
				if (!!newToken.claims.isEPT) {
					setIsEPT(true);
					localStorage.setItem('isEPT', true);
				} else {
					setIsEPT(false);
					localStorage.setItem('isEPT', false);
				}

				//sets isOPT
				if (!!newToken.claims.isOPT) {
					setIsOPT(true);
					localStorage.setItem('isOPT', true);
				} else {
					setIsOPT(false);
					localStorage.setItem('isOPT', false);
				}

				//sets phiKeys
				if (!!newToken.claims.phiKeys) {
					const tempObj = {};
					for (let key in newToken.claims.phiKeys) {
						tempObj[key] = newToken.claims.phiKeys[key];
					}
					setPHIKeys(tempObj);
					localStorage.setItem('phiKeys', JSON.stringify(tempObj));
				} else {
					setPHIKeys(null);
					localStorage.removeItem('phiKeys');
				}

				previousToken.current = newToken.token;
			}

			setDataRetrieved(true);
			localStorage.setItem('dataRetrieved', true);
		});
	}

	if (unsub && dataRetrieved) {
		// Only unsubscribe if data has been retrieved
		// console.log('Unsubscribing...');
		unsub();
	}

	//actions based on tokenData
	useEffect(() => {
		if (tokenData.current !== null && tokenData.current !== undefined) {
			// resets refreshTimer
			if (tokenData.current.resetTimer) {
				refreshTimer.current = setTimeout(refreshToken, tokenData.current.remainingDuration);
				tokenData.current.resetTimer = false;
			}

			// attempts to refresh token
			if (tokenData.current.refresh) {
				refreshToken();
				tokenData.current.refresh = false;
			}

			// logs out user
			if (tokenData.current.logout) {
				logoutHandler();
			}
		}
	}, [
		tokenData.current, // access the current value of the tokenData ref
		tokenData.current?.remainingDuration,
		tokenData.current?.resetTimer,
		tokenData.current?.refresh,
		tokenData.current?.logout,
		logoutHandler,
		refreshToken,
	]);

	const contextValue = {
		token: token,
		tokenExpiration: tokenExpiration,
		isLoggedIn: userIsLoggedIn,
		memberType: memberType,
		isEPT: isEPT,
		isOPT: isOPT,
		phiKeys: phiKeys,
		login: loginHandler,
		logout: logoutHandler,
		updateAccessToken: updateAccessToken,
		photo: photo,
		userName: userName,
		email: email,
		dataRetrieved: dataRetrieved,
	};

	return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
