import React, { useContext, useState } from 'react';

import { GoogleAuthProvider, getRedirectResult, onAuthStateChanged, signInWithRedirect } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

import AuthContext from './authContext';
import LoadingSpinner from '../ui/LoadingSpinner';
import AlertContext from '../ui/AlertContext';
import { auth } from '../../App';
import { Button } from '@mui/material';
import apiKeySettings from '../../apiKey';

export const API_KEY = apiKeySettings.API_KEY;
export const CLIENT_ID = apiKeySettings.CLIENT_ID;
export const SCOPES = [
	'https://www.googleapis.com/auth/directory.readonly',
	'https://www.googleapis.com/auth/admin.directory.user.readonly',
	'https://www.googleapis.com/auth/admin.directory.orgunit.readonly',
	'https://www.googleapis.com/auth/admin.directory.group.readonly',
];

const provider = new GoogleAuthProvider();
for (const scope of SCOPES) provider.addScope(scope);
// provider.addScope(...SCOPES2);
provider.setCustomParameters({
	hd: 'ept911.com',
});

export default function SignIn() {
	const authCtx = useContext(AuthContext);
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(false);

	const handleGoogleSignIn = async () => {
		setIsLoading(true);
		await signInWithRedirect(auth, provider);
	};

	onAuthStateChanged(auth, async (user) => {
		if (user) {
			setIsLoading(true);
			const result = await getRedirectResult(auth);

			if (result) {
				// This gives you a Google Access Token. You can use it to access Google APIs.
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;

				// The signed-in user info.
				const user = result.user;
				const expirationTime = user.stsTokenManager.expirationTime;
				const photo = user?.providerData?.[0]?.photoURL || null;
				const userName = user.displayName;
				const email = user.email;

				const functions = getFunctions();
				const verifyEPTEmailWithCustomClaimsV2 = httpsCallable(functions, 'verifyEPTEmailWithCustomClaimsV2');
				verifyEPTEmailWithCustomClaimsV2({})
					.then((result) => {
						const verified = result.data.verified;

						if (verified) {
							authCtx.login(expirationTime, photo, userName, email);
							authCtx.updateAccessToken(token, new Date().getTime() + 3600000 - 30000);
						} else {
							authCtx.logout();
							alertCtx.setSeverity('error');
							alertCtx.setMessage(
								'An EPT Email account must be used to login. Retry logging in using an EPT Email account.'
							);
							alertCtx.setActive(true);
							alertCtx.setTimer(10000);
						}
					})
					.catch((err) => {
						console.error(err);
					});
				setIsLoading(false); // Set isLoading to false after the process completes
			} else {
				setTimeout(() => {
					setIsLoading(false);
				}, 15000);
			}
		}
	});

	//if loading return loader...
	if (isLoading) return <LoadingSpinner />;

	return (
		<Button variant='contained' onClick={handleGoogleSignIn}>
			Log In
		</Button>
	);
}
