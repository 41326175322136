import React, { useEffect, useState } from 'react';
import '../../App.css';
import GetUserData from '../auth/GetUserData';

import { Box } from '@mui/material';
import LoadingSpinner from '../ui/LoadingSpinner';

const Chart_IFrame = ({ chart, dashboard, onRendered, height = '100%', width = '100%' }) => {
	const [loading, setLoading] = useState(true);
	const [endLink, setEndLink] = useState('');

	useEffect(() => {
		setLoading(true);
		const fetchEndLink = async () => {
			if (chart.UniqueIdentifier) {
				const link = await GetUserData(chart.UniqueIdentifier);
				setEndLink(link);
			} else setEndLink('');

			setLoading(false);
		};

		fetchEndLink();
	}, [chart]);

	if (loading) return <LoadingSpinner />;

	return (
		<Box display={'flex'} justifyContent={'center'} sx={{ height: height, width: width }}>
			<iframe src={`${chart.ExternalLink}${endLink}`} title='Local HTML Page' scrolling='no' />
		</Box>
	);
};

export default Chart_IFrame;
