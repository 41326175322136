import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * 
 * @param {string} to - optional - email address(es)
 * @param {string} cc - optional - email address(es)
 * @param {string} bcc - optional - email address(es)
 * @param {string} replyTo - required - one email address of the group sending the email, should match fromGroup
 * @param {string} emailText - recommended - plain text for email
 * @param {string} template_id - required - id of SendGrid template to use
 * @param {string} emailSubject - required - subject line of email
 * @param {string} emailTitle - required - title to include in email
 * @param {string} emailHTML - required - body of email, can include html markup
 * @param {string} emailTable - optional - table to be included in the html
 * @param {string} fromPerson - required - name of who the email is from
 * @param {string} fromGroup - required - name of the group the email is from, group should match replyTo
 * @param {array[object]} attachments - optional - an array of objects containing content (string), type (e.g., “text/plain” or “text/html”), filename
 (string), disposition (string - “attachment” vs “inline”), and content_id (string - used when disposition set to “inline”)
 */
export default async function sendGrid({
	to,
	cc,
	bcc,
	replyTo,
	plainText,
	template_id,
	emailSubject,
	emailTitle,
	emailHTML,
	emailTable,
	fromPerson,
	fromGroup,
	attachments,
}) {
	const functions = getFunctions();
	const sendGridV2 = httpsCallable(functions, 'sendGridV2');

	return await sendGridV2({
		replyTo: replyTo,
		to: to,
		cc: cc,
		bcc: bcc,
		from: {
			email: 'admin@ept911.com',
			name: 'EPT',
		},
		html: emailHTML,
		text: plainText,
		template_id: template_id,
		dynamic_template_data: {
			emailSubject: emailSubject,
			emailTitle: emailTitle,
			emailBody: emailHTML,
			emailTable: emailTable,
			fromPerson: fromPerson,
			fromGroup: fromGroup,
		},
		attachments: attachments,
	})
		.then((result) => {
			// console.log(result);
			return result;
		})
		.catch((error) => {
			console.error(error);
			return error
		});
}
