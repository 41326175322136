import React, { Fragment, useEffect, useState, useContext } from 'react';

import { Box, Divider, IconButton, Typography, Stack } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

import classes from './OrgChartCard.module.css';
import ModifyPositionModal from './ModifyPositionModal';
import Tooltip from '../ui/Tooltip';
import AuthContext from '../auth/authContext';
import PersonCard from './PersonCard';
import PersonCardSinglePosition from './PersonCardSinglePosition';
import CustomClaimsContext from '../auth/CustomClaimsContext';

export default function OrgChartCard(props) {
	const authCtx = useContext(AuthContext);
	const claimsCtx = useContext(CustomClaimsContext);
	const position = props.position;
	const [open, setOpen] = useState(false);
	const [displayModifyPositionModal, setDisplayModifyPositionModal] = useState(null);
	const [chairMembers, setChairMembers] = useState([]);
	const [votingMembers, setVotingMembers] = useState([]);
	const [nonVotingMembers, setNonVotingMembers] = useState([]);
	const [exOfficioMembers, setExOfficioMembers] = useState([]);
	const [singlePositionMembers, setSinglePositionMembers] = useState([]);

	const style = {
		width: '100%',
		maxWidth: 360,
		bgcolor: '#f2f2f2',
	};

	//sets members of this group
	useEffect(() => {
		const tempArray = [];

		props.tableData.forEach((item) => {
			if (item.groups !== undefined) {
				item.groups.forEach((group) => {
					if (group.groupKey === position.id) {
						tempArray.push({
							email: item.currentPerson.email,
							name: item.currentPerson.label,
							memberType: group.memberType,
							positionTitle: item.positionTitle,
						});
					}
				});
			}
		});

		const chair = tempArray.filter((member) => member.memberType === 'Chair');
		const voting = tempArray.filter((member) => member.memberType === 'Voting Member');
		const nonVoting = tempArray.filter((member) => member.memberType === 'Non-Voting Member');
		const exOfficio = tempArray.filter((member) => member.memberType === 'Ex-Officio');
		const singlePosition = tempArray.filter((member) => member.memberType === 'Single Position');

		setChairMembers(chair);
		setVotingMembers(voting);
		setNonVotingMembers(nonVoting);
		setExOfficioMembers(exOfficio);
		setSinglePositionMembers(singlePosition);

		// setMembers(tempArray);
	}, [props.tableData, position.id]);

	//button for adding a position or a group
	const handleDisplayModalButton = () => {
		setDisplayModifyPositionModal(true);
	};

	//cancels the modal
	const cancelModal = () => {
		setDisplayModifyPositionModal(null);
	};

	//handles the page reloading when a change is made; passed down from LeadershipItem.js
	const handleReload = () => {
		props.handleReload();
	};

	//opens and closes the card
	const handleClick = () => {
		if (open) {
			setOpen(false);
		} else {
			setOpen(true);
		}
	};

	//stops event propogation for clicking on the file attachments in a fileCard
	const stopPropagation = (event) => {
		event.stopPropagation();
	};

	return (
		<Fragment>
			{displayModifyPositionModal && (
				<ModifyPositionModal
					open={displayModifyPositionModal}
					onClose={cancelModal}
					db={props.db}
					onReload={handleReload}
					position={position}
					allPositions={props.allPositions}
				/>
			)}

			<div
				className={`${classes.container} ${open && classes.expand} ${!open && classes.closed} ${
					position.type === 'position' ? classes.position : classes.group
				}`}
				onClick={handleClick}
			>
				{/*upper container*/}
				<div
					className={`${classes.upper} ${open && classes.expand} ${!open && classes.closed} ${
						position.type === 'position' ? classes.position : classes.group
					}`}
				>
					<div
						className={`${classes.titleTag} ${open && classes.expand} ${!open && classes.closed} ${
							position.type === 'position' ? classes.position : classes.group
						}`}
					>
						{position.title}
					</div>
				</div>

				{/*lower container*/}
				<div className={`${classes.lower} ${open && classes.expand} ${!open && classes.closed}`}>
					<Box sx={style}>
						{/* settings */}
						<Box display='flex' justifyContent='flex-end' sx={{ width: '100%' }}>
							{claimsCtx?.claims?.admin && (
								<div onClick={stopPropagation}>
									<Tooltip text='Settings'>
										<IconButton aria-label='settings' onClick={handleDisplayModalButton}>
											<TuneIcon />
										</IconButton>
									</Tooltip>
								</div>
							)}
						</Box>

						{/* single positions */}
						{position.type === 'position' && (
							<Box p={2}>
								{singlePositionMembers.length > 0 && (
									<Box>
										{singlePositionMembers.map((person, key) => {
											return <PersonCardSinglePosition key={key} person={person} token={authCtx.token} />;
										})}
									</Box>
								)}
							</Box>
						)}

						{/* groups */}
						{position.type === 'group' && (
							<Box>
								{chairMembers.length > 0 && (
									<Box sx={{ padding: '0 5px' }}>
										<Stack spacing={4} pt={2} mb={4}>
											<Typography variant='h6' align='center' color='primary'>
												Chair
											</Typography>
											{chairMembers.map((person, key) => {
												return <PersonCard key={key} person={person} token={authCtx.token} />;
											})}
										</Stack>
									</Box>
								)}

								{votingMembers.length > 0 && (
									<Box sx={{ padding: '0 5px' }}>
										<Divider />
										<Stack spacing={4} mt={2} mb={4}>
											<Typography variant='h6' align='center' color='primary'>
												Voting Members
											</Typography>
											{votingMembers.map((person, key) => {
												return <PersonCard key={key} person={person} token={authCtx.token} />;
											})}
										</Stack>
									</Box>
								)}

								{nonVotingMembers.length > 0 && (
									<Box sx={{ padding: '0 5px' }}>
										<Divider />
										<Stack spacing={4} mt={2} mb={4}>
											<Typography variant='h6' align='center' color='primary'>
												Non-Voting Members
											</Typography>
											{nonVotingMembers.map((person, key) => {
												return <PersonCard key={key} person={person} token={authCtx.token} />;
											})}
										</Stack>
									</Box>
								)}

								{exOfficioMembers.length > 0 && (
									<Box sx={{ padding: '0 5px' }}>
										<Divider />
										<Stack spacing={4} mt={2} mb={4}>
											<Typography variant='h6' align='center' color='primary'>
												Ex-Officio
											</Typography>
											{exOfficioMembers.map((person, key) => {
												return <PersonCard key={key} person={person} token={authCtx.token} />;
											})}
										</Stack>
									</Box>
								)}
							</Box>
						)}

						{/* lower fill box */}
						<Box display='flex' justifyContent='flex-end' sx={{ width: '100%' }}></Box>
					</Box>
				</div>
			</div>
		</Fragment>
	);
}
