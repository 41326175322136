import React, { useContext } from 'react';
import { Fragment, useState, useEffect } from 'react';

import { collection, getDoc, getDocs, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { Box, Stack, Typography, TextField, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import DeleteIcon from '@mui/icons-material/Delete';

import LoadingSpinner from '../components/ui/LoadingSpinner';
import AreObjectsEqual from '../components/localFunctions/AreObjectsEqual';
import { db } from '../App';
import Tooltip from '../components/ui/Tooltip';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';

export default function SiteSettings() {
	const claimsCtx = useContext(CustomClaimsContext);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [data, setData] = useState(null);
	const [originalData, setOriginalData] = useState(null);

	//get Settings from Firestore
	useEffect(() => {
		if (db) {
			const getData = async () => {
				setLoading(true);

				const tempData = {};
				const querySnapshot = await getDocs(collection(db, 'settings'));
				querySnapshot.forEach((document) => {
					let data = document.data();
					tempData[document.id] = { ...data };
					// console.log(data);
				});

				setData(tempData);
				setOriginalData(JSON.parse(JSON.stringify(tempData)));
				// console.log(tempData['Last TickLog Download'].date.toDate());

				setLoading(false);
			};

			getData();
		}
	}, [db]);

	// useEffect(() => {
	// 	if (data !== null) console.log(data);
	// }, [data]);

	/**
	 * @param {string} e - value of item being updated
	 * @param {string} document - the name of the document to be updated
	 * @param {string} field - the field to be updated (i.e. hourlyRate)
	 * @param {string} fieldIndex - an index if one exists for the field (i.e. a field with an array)
	 */
	const handleChange = (e, document, field, fieldIndex) => {
		setData((prevData) => {
			const newData = { ...prevData };
			if (fieldIndex !== null) {
				newData[document][field][fieldIndex] = e;
			} else newData[document][field] = e;
			return newData;
		});
	};

	/**
	 * removes item
	 * @param {string} ID - the ID of the thing to be updated
	 * @param {string} field - the field to be updated (i.e. groups)
	 * @param {string} index - a index of the field for the item to be remove
	 */
	const handleRemoval = (ID, item, index) => {
		setData((prevData) => {
			const newData = { ...prevData };
			newData[ID][item].splice([index], 1);
			return newData;
		});
	};

	const handleSave = async (ID, Document) => {
		setSaving(true);

		const ref = doc(db, 'settings', ID);
		await updateDoc(ref, { ...Document });

		const docRef = doc(db, 'settings', ID);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setData((prevData) => {
				const newData = { ...prevData };
				newData[ID] = { ...docSnap.data() };
				return newData;
			});
			setOriginalData((prevData) => {
				const newData = { ...prevData };
				newData[ID] = { ...docSnap.data() };
				return JSON.parse(JSON.stringify(newData));
			});
		}

		setSaving(false);
	};

	//display if loading
	if (loading) {
		return <LoadingSpinner />;
	}

	return (
		<Fragment>
			<Grid2 container spacing={5}>
				{/* IT Contact */}
				<Grid2 sm={12} md={6} lg={4}>
					<Stack>
						<Stack direction='row' sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography textAlign='center'>IT Contact</Typography>
							<Tooltip text='Background functions will contact this person when errors occur or a setting needs to be adjusted, such as when giving someone access to the correct Tick Log project.' />
						</Stack>
						<TextField
							disabled={!claimsCtx?.claims?.admin || saving}
							id='IT Contact'
							label='Name'
							variant='outlined'
							margin='dense'
							value={data['IT Contact'].name || ''}
							onChange={(e) => {
								handleChange(e.target.value, 'IT Contact', 'name', null);
							}}
						/>
						<TextField
							disabled={!claimsCtx?.claims?.admin || saving}
							id='IT Contact'
							label='Email Address'
							variant='outlined'
							margin='dense'
							value={data['IT Contact'].email || ''}
							onChange={(e) => {
								handleChange(e.target.value, 'IT Contact', 'email', null);
							}}
						/>
						<Button
							color='saveButton'
							variant='contained'
							startIcon={<SaveRoundedIcon />}
							disabled={
								saving || !claimsCtx?.claims?.admin || AreObjectsEqual(data['IT Contact'], originalData['IT Contact'])
							}
							onClick={() => {
								handleSave('IT Contact', data['IT Contact']);
							}}
						>
							SAVE
						</Button>
					</Stack>
				</Grid2>

				{/* Admins */}
				<Grid2 sm={12} md={6} lg={4}>
					<Stack>
						<Stack direction='row' sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography textAlign='center'>Site Administrators</Typography>
							<Tooltip text='Group email addresses (and the individuals who are part of that group) and individual user email addresses listed here will have Admin access to this Portal website.' />
						</Stack>

						{/* Group Admins */}
						<Stack spacing={2}>
							{data['admins'].groups &&
								data['admins'].groups.map((group, index) => {
									if (index < originalData['admins']['groups'].length)
										return (
											<Stack key={index} direction='row'>
												<TextField
													disabled={!claimsCtx?.claims?.admin || saving}
													id='Group Admin'
													label='Group Admin'
													variant='outlined'
													value={group || ''}
													onChange={(e) => {
														handleChange(e.target.value, 'admins', 'groups', index);
													}}
													sx={{ width: '85%' }}
												/>
												<Button
													disabled={!claimsCtx?.claims?.admin || saving}
													onClick={() => {
														handleRemoval('admins', 'groups', index);
													}}
												>
													<Box sx={{ width: '15%' }}>
														<DeleteIcon color='error' />
													</Box>
												</Button>
											</Stack>
										);
								})}

							<TextField
								disabled={!claimsCtx?.claims?.admin || saving}
								id='Group Admin'
								label='Group Admin'
								variant='outlined'
								value={data['admins']['groups'][originalData['admins']['groups'].length] || ''}
								onChange={(e) => {
									handleChange(e.target.value, 'admins', 'groups', originalData['admins']['groups'].length || 0);
								}}
								sx={{ width: '85%' }}
							/>

							{/* Individual Admins */}
							{data['admins'].individuals &&
								data['admins'].individuals.map((individual, index) => {
									if (index < originalData['admins']['individuals'].length)
										return (
											<Stack key={index} direction='row'>
												<TextField
													key={index}
													disabled={!claimsCtx?.claims?.admin || saving}
													id='Individual Admin'
													label='Individual Admin'
													variant='outlined'
													value={individual || ''}
													onChange={(e) => {
														handleChange(e.target.value, 'admins', 'individuals', index);
													}}
													sx={{ width: '85%' }}
												/>
												<Button
													disabled={!claimsCtx?.claims?.admin || saving}
													onClick={() => {
														handleRemoval('admins', 'individuals', index);
													}}
												>
													<Box sx={{ width: '15%' }}>
														<DeleteIcon color='error' />
													</Box>
												</Button>
											</Stack>
										);
								})}

							<TextField
								disabled={!claimsCtx?.claims?.admin || saving}
								id='Individual Admin'
								label='Individual Admin'
								variant='outlined'
								value={data['admins']['individuals'][originalData['admins']['individuals'].length] || ''}
								onChange={(e) => {
									handleChange(
										e.target.value,
										'admins',
										'individuals',
										originalData['admins']['individuals'].length || 0
									);
								}}
								sx={{ width: '85%' }}
							/>

							<Button
								color='saveButton'
								variant='contained'
								startIcon={<SaveRoundedIcon />}
								disabled={
									saving || !claimsCtx?.claims?.admin || AreObjectsEqual(data['admins'], originalData['admins'])
								}
								onClick={() => {
									handleSave('admins', data['admins']);
								}}
							>
								SAVE
							</Button>
						</Stack>
					</Stack>
				</Grid2>

				{/* Data Types */}
				<Grid2 sm={12} md={6} lg={4}>
					<Stack>
						<Stack direction='row' sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography textAlign='center'>Dashboard Data Types</Typography>
							<Tooltip text='A list of data types used by the Dashboard section.' />
						</Stack>

						<Stack spacing={2}>
							{data['Data Types']['Data Types'] &&
								data['Data Types']['Data Types'].map((type, index) => {
									if (index < originalData['Data Types']['Data Types'].length)
										return (
											<Stack key={index} direction='row' spacing={2} sx={{ alignItems: 'center' }}>
												<TextField
													disabled
													id='Data Type ID'
													label='ID'
													variant='outlined'
													margin='dense'
													value={type.id || ''}
													onChange={(e) => {
														handleChange(e.target.value, 'Data Type', 'Data Type', index);
													}}
													sx={{ width: '10%' }}
												/>
												<TextField
													disabled={!claimsCtx?.claims?.admin || saving}
													id='Data Type Name'
													label='Name'
													variant='outlined'
													margin='dense'
													value={type.name || ''}
													onChange={(e) => {
														const result = { id: `${index}`, name: e.target.value };
														handleChange(result, 'Data Type', 'Data Type', index);
													}}
													sx={{ width: '75%' }}
												/>
												<Button
													disabled={!claimsCtx?.claims?.admin || saving}
													onClick={() => {
														handleRemoval('Data Types', 'Data Types', index);
													}}
												>
													<Box sx={{ width: '15%' }}>
														<DeleteIcon color='error' />
													</Box>
												</Button>
											</Stack>
										);
								})}

							<Stack direction='row' spacing={2} sx={{ alignItems: 'center' }}>
								<TextField
									disabled
									id='Data Type ID'
									label='ID'
									variant='outlined'
									margin='dense'
									value={
										(data['Data Types']['Data Types'][originalData['Data Types']['Data Types'].length] &&
											data['Data Types']['Data Types'][originalData['Data Types']['Data Types'].length].id) ||
										''
									}
									sx={{ width: '10%' }}
								/>
								<TextField
									disabled={!claimsCtx?.claims?.admin || saving}
									id='Data Type Name'
									label='Name'
									variant='outlined'
									margin='dense'
									value={
										(data['Data Types']['Data Types'][originalData['Data Types']['Data Types'].length] &&
											data['Data Types']['Data Types'][originalData['Data Types']['Data Types'].length].name) ||
										''
									}
									onChange={(e) => {
										const result = {
											id: String(originalData['Data Types']['Data Types'].length),
											name: e.target.value,
										};
										handleChange(
											result,
											'Data Types',
											'Data Types',
											originalData['Data Types']['Data Types'].length || 0
										);
									}}
									sx={{ width: '75%' }}
								/>
								<Button disabled>
									<Box sx={{ width: '15%' }}></Box>
								</Button>
							</Stack>

							<Button
								color='saveButton'
								variant='contained'
								startIcon={<SaveRoundedIcon />}
								disabled={
									saving || !claimsCtx?.claims?.admin || AreObjectsEqual(data['Data Types'], originalData['Data Types'])
								}
								onClick={() => {
									handleSave('Data Types', data['Data Types']);
								}}
							>
								SAVE
							</Button>
						</Stack>
					</Stack>
				</Grid2>

				{/* Send Email As */}
				<Grid2 sm={12} md={6} lg={4}>
					<Stack>
						<Stack direction='row' sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography textAlign='center'>Send Email As</Typography>
							<Tooltip text='List groups which members of each group can send emails from.' />
						</Stack>

						<Stack spacing={2}>
							{data.SendEmailAs.groupEmails &&
								data.SendEmailAs.groupEmails.map((group, index) => {
									if (index < originalData['SendEmailAs']['groupEmails'].length)
										return (
											<Stack key={index} direction='row'>
												<TextField
													disabled={!claimsCtx?.claims?.admin || saving}
													id='Group Email'
													label='Group Email'
													variant='outlined'
													value={group || ''}
													onChange={(e) => {
														handleChange(e.target.value, 'SendEmailAs', 'groupEmails', index);
													}}
													sx={{ width: '85%' }}
												/>
												<Button
													disabled={!claimsCtx?.claims?.admin || saving}
													onClick={() => {
														handleRemoval('SendEmailAs', 'groupEmails', index);
													}}
												>
													<Box sx={{ width: '15%' }}>
														<DeleteIcon color='error' />
													</Box>
												</Button>
											</Stack>
										);
								})}

							<TextField
								disabled={!claimsCtx?.claims?.admin || saving}
								id='Group Email'
								label='Group Email'
								variant='outlined'
								value={data['SendEmailAs']['groupEmails'][originalData['SendEmailAs']['groupEmails'].length] || ''}
								onChange={(e) => {
									handleChange(
										e.target.value,
										'SendEmailAs',
										'groupEmails',
										originalData['SendEmailAs']['groupEmails'].length || 0
									);
								}}
								sx={{ width: '85%' }}
							/>

							<Button
								color='saveButton'
								variant='contained'
								startIcon={<SaveRoundedIcon />}
								disabled={
									saving ||
									!claimsCtx?.claims?.admin ||
									AreObjectsEqual(data['SendEmailAs'], originalData['SendEmailAs'])
								}
								onClick={() => {
									handleSave('SendEmailAs', data['SendEmailAs']);
								}}
							>
								SAVE
							</Button>
						</Stack>
					</Stack>
				</Grid2>

				{/* Send Email To */}
				<Grid2 sm={12} md={6} lg={4}>
					<Stack>
						<Stack direction='row' sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography textAlign='center'>Send Email To</Typography>
							<Tooltip text='List default groups that emails can be sent to.  These are just prompts for common groups.' />
						</Stack>

						<Stack spacing={2}>
							{data.SendEmailTo.groupEmails &&
								data.SendEmailTo.groupEmails.map((group, index) => {
									if (index < originalData['SendEmailTo']['groupEmails'].length)
										return (
											<Stack key={index} direction='row'>
												<TextField
													disabled={!claimsCtx?.claims?.admin || saving}
													id='Group Email'
													label='Group Email'
													variant='outlined'
													value={group || ''}
													onChange={(e) => {
														handleChange(e.target.value, 'SendEmailTo', 'groupEmails', index);
													}}
													sx={{ width: '85%' }}
												/>
												<Button
													disabled={!claimsCtx?.claims?.admin || saving}
													onClick={() => {
														handleRemoval('SendEmailTo', 'groupEmails', index);
													}}
												>
													<Box sx={{ width: '15%' }}>
														<DeleteIcon color='error' />
													</Box>
												</Button>
											</Stack>
										);
								})}

							<TextField
								disabled={!claimsCtx?.claims?.admin || saving}
								id='Group Email'
								label='Group Email'
								variant='outlined'
								value={data['SendEmailTo']['groupEmails'][originalData['SendEmailTo']['groupEmails'].length] || ''}
								onChange={(e) => {
									handleChange(
										e.target.value,
										'SendEmailTo',
										'groupEmails',
										originalData['SendEmailTo']['groupEmails'].length || 0
									);
								}}
								sx={{ width: '85%' }}
							/>

							<Button
								color='saveButton'
								variant='contained'
								startIcon={<SaveRoundedIcon />}
								disabled={
									saving ||
									!claimsCtx?.claims?.admin ||
									AreObjectsEqual(data['SendEmailTo'], originalData['SendEmailTo'])
								}
								onClick={() => {
									handleSave('SendEmailTo', data['SendEmailTo']);
								}}
							>
								SAVE
							</Button>
						</Stack>
					</Stack>
				</Grid2>

				{/* max outside US days */}
				<Grid2 sm={12} md={6} lg={4}>
					<Stack>
						<Stack direction='row' sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography textAlign='center'>Max Outside US Days</Typography>
							<Tooltip text='Set the max number of days a user can choose to set their Google Workspace account to be available outside the US.' />
						</Stack>
						<TextField
							disabled={!claimsCtx?.claims?.admin || saving}
							id='MaxDays'
							label='Max Days'
							variant='outlined'
							margin='dense'
							value={data['Outside_US_OU'].MaxDays || ''}
							onChange={(e) => {
								handleChange(e.target.value, 'Outside_US_OU', 'MaxDays', null);
							}}
						/>
						<Button
							color='saveButton'
							variant='contained'
							startIcon={<SaveRoundedIcon />}
							disabled={
								saving || !claimsCtx?.claims?.admin || AreObjectsEqual(data['Outside_US_OU'], originalData['Outside_US_OU'])
							}
							onClick={() => {
								handleSave('Outside_US_OU', data['Outside_US_OU']);
							}}
						>
							SAVE
						</Button>
					</Stack>
				</Grid2>
			</Grid2>
		</Fragment>
	);
}
