import React from 'react';
import { useState, useEffect } from 'react';

import { doc, getDoc, collection, getDocs, updateDoc } from 'firebase/firestore';

import {
	Box,
	Typography,
	TextField,
	Stack,
	FormControlLabel,
	Button,
	Radio,
	Checkbox,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Container,
	IconButton,
} from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';

import AccordionUI from '../ui/AccordionUI';
import Tooltip from '../ui/Tooltip';
import AreObjectsEqual from '../localFunctions/AreObjectsEqual';
import { db } from '../../App';

export default function Dashboard_DataInput(props) {
	const [expectedSheets, setExpectedSheets] = useState(JSON.parse(JSON.stringify(props.expectedSheets || null)));
	const [originalExpectedSheets, setOriginalExpectedSheets] = useState(
		JSON.parse(JSON.stringify(props.expectedSheets || null))
	);
	const [equal, setEqual] = useState(null);
	const [saving, setSaving] = useState(false);

	/**
	 * checks if expectedSheets and originalExpectedSheets are equal
	 * if not equal, then allow save button to be active
	 */
	useEffect(() => {
		setEqual(AreObjectsEqual(expectedSheets, originalExpectedSheets));
	}, [expectedSheets, originalExpectedSheets]);

	/**
	 * updates a sheet's name
	 * @param {number} sheetIndex
	 * @param {*} newItem
	 */
	const updateSheetName = (sheetIndex, newItem) => {
		setExpectedSheets((prevData) => {
			const newData = [...prevData];
			newData[sheetIndex].name = newItem.name;
			return newData;
		});
	};

	/**
	 * updates whether a field contains phi
	 * @param {number} sheetIndex
	 * @param {number} fieldIndex
	 * @param {boolean} checked
	 */
	const updateFieldPHI = (sheetIndex, fieldIndex, checked) => {
		setExpectedSheets((prevData) => {
			const newData = [...prevData];
			newData[sheetIndex].expectedFields[fieldIndex].phi = checked;
			return newData;
		});
	};

	/**
	 * updates a fields phiKey (different that phi)
	 * @param {*} sheetIndex
	 * @param {*} fieldIndex
	 */
	const updatePHIKey = (sheetIndex, fieldIndex) => {
		setExpectedSheets((prevData) => {
			const newData = [...prevData];

			newData[sheetIndex].expectedFields.map((field, index) => {
				if (fieldIndex === index) {
					field.phiKey = true;
				} else {
					field.phiKey = false;
				}
			});
			return newData;
		});
	};

	/**
	 * updates a field's name
	 * @param {number} sheetIndex
	 * @param {number} fieldIndex
	 * @param {*} newItem
	 */
	const updateFieldName = (sheetIndex, fieldIndex, newItem) => {
		setExpectedSheets((prevData) => {
			const newData = [...prevData];
			newData[sheetIndex].expectedFields[fieldIndex].name = newItem.name;
			return newData;
		});
	};

	/**
	 * updates a field's type
	 * @param {number} sheetIndex
	 * @param {number} fieldIndex
	 * @param {*} newItem
	 */
	const updateFieldType = (sheetIndex, fieldIndex, newItem) => {
		setExpectedSheets((prevData) => {
			const newData = [...prevData];
			newData[sheetIndex].expectedFields[fieldIndex].type = newItem.type;
			return newData;
		});
	};

	/**
	 * adds a field to a sheet
	 * @param {number} sheetIndex
	 */
	const addField = (sheetIndex) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			const expectedFields = [...newState[sheetIndex].expectedFields];
			const newField = { id: `${expectedFields.length}`, name: '', type: '' };
			expectedFields.push(newField);
			newState[sheetIndex].expectedFields = expectedFields;
			return newState;
		});
	};

	/**
	 * Adds a new sheet with default calculated columns for 'Report Date' and 'Sheet'.
	 */
	const addSheet = () => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];

			// Default rules that are not editable
			const defaultCalculatedColumns = [
				{
					ruleName: 'Report Date',
					conditions: [], // You can define default conditions if needed
					calculation: {
						calculationType: 'REPORT_DATE',
						calculationColumn1: '',
						calculationColumn2: '',
						comparisonOperator: '',
					},
					editable: false,
					type: 'Date',
				},
				{
					ruleName: 'Sheet',
					conditions: [], // You can define default conditions if needed
					calculation: {
						calculationType: 'SHEET',
						calculationColumn1: '',
						calculationColumn2: '',
						comparisonOperator: '',
					},
					editable: false,
					type: 'String',
				},
			];

			// Construct the new sheet
			const newSheet = {
				id: `${newState.length}`,
				name: '',
				expectedFields: [],
				calculatedColumns: defaultCalculatedColumns,
			};

			// Add the new sheet to the state
			newState.push(newSheet);
			return newState;
		});
	};

	/**
	 * updates a calculated column
	 * @param {number} sheetIndex
	 * @param {*} e
	 */
	const handleCalculationChange = (sheetIndex, calculatedColumnIndex, e, field) => {
		const value = e.target.value;
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			if (!newState[sheetIndex].calculatedColumns[calculatedColumnIndex].calculation) {
				newState[sheetIndex].calculatedColumns[calculatedColumnIndex].calculation = {};
			}
			if (field === 'ruleName') {
				newState[sheetIndex].calculatedColumns[calculatedColumnIndex].ruleName = value;
			} else if (field === 'type') {
				newState[sheetIndex].calculatedColumns[calculatedColumnIndex].type = value;
			} else {
				newState[sheetIndex].calculatedColumns[calculatedColumnIndex].calculation[field] = value;
			}
			return newState;
		});
	};

	/**
	 * Adds a new condition to a specific calculatedColumn in a sheet.
	 * @param {number} sheetIndex - The index of the sheet where the calculatedColumn resides.
	 * @param {number} calculatedColumnIndex - The index of the calculatedColumn to which the condition should be added.
	 */
	const addCondition = (sheetIndex, calculatedColumnIndex) => {
		setExpectedSheets((prevState) => {
			// Deep copy to avoid direct mutations
			const newState = [...prevState];

			// Check if the conditions array exists; if not, initialize it.
			if (!newState[sheetIndex].calculatedColumns[calculatedColumnIndex].conditions) {
				newState[sheetIndex].calculatedColumns[calculatedColumnIndex].conditions = [];
			}

			// Push a new condition object to the conditions array
			newState[sheetIndex].calculatedColumns[calculatedColumnIndex].conditions.push({
				conditionName: null,
				conditionType: null,
				conditionValue: null,
			});

			return newState;
		});
	};

	/**
	 * Deletes a condition from a rule in a sheet.
	 * @param {number} sheetIndex - Index of the sheet in the array.
	 * @param {number} ruleIndex - Index of the rule in the sheet's array.
	 * @param {number} conditionIndex - Index of the condition in the rule's conditions array.
	 */
	const deleteCondition = (sheetIndex, ruleIndex, conditionIndex) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			// Remove the condition from the rule's conditions array
			newState[sheetIndex].calculatedColumns[ruleIndex].conditions.splice(conditionIndex, 1);
			return newState;
		});
	};

	/**
	 * Updates a specific property of a condition in a calculatedColumn of a sheet.
	 * @param {number} sheetIndex - The index of the sheet where the calculatedColumn resides.
	 * @param {number} calculatedColumnIndex - The index of the calculatedColumn containing the condition.
	 * @param {number} conditionIndex - The index of the condition to be updated.
	 * @param {string} field - The property of the condition to be updated (e.g., 'conditionName').
	 * @param {any} value - The new value to be set.
	 */
	const handleConditionChange = (sheetIndex, calculatedColumnIndex, conditionIndex, field, value) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			newState[sheetIndex].calculatedColumns[calculatedColumnIndex].conditions[conditionIndex][field] = value;

			// Check if conditionType is set to BOOLEAN, then set conditionValue to null
			if (field === 'conditionType' && value === 'BOOLEAN') {
				newState[sheetIndex].calculatedColumns[calculatedColumnIndex].conditions[conditionIndex].conditionValue = null;
			}

			return newState;
		});
	};

	/**
	 * Adds a new calculated column to a specific sheet.
	 * @param {number} sheetIndex - The index of the sheet to which the calculated column should be added.
	 */
	const addCalculatedColumn = (sheetIndex) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];

			// Define a new calculated column with default values
			const newCalculatedColumn = {
				ruleName: null,
				conditions: [
					{
						conditionName: null,
						conditionType: null,
						conditionValue: null,
					},
				],
				calculation: {
					calculationType: null,
					calculationColumn1: null,
					calculationColumn2: null,
					comparisonOperator: null,
				},
				editable: true,
				type: null,
			};

			// Check if the sheet already has calculatedColumns and add the new one
			if (newState[sheetIndex].calculatedColumns) {
				newState[sheetIndex].calculatedColumns.push(newCalculatedColumn);
			} else {
				newState[sheetIndex].calculatedColumns = [newCalculatedColumn];
			}

			return newState;
		});
	};

	/**
	 * Deletes a calculated column (rule) from a sheet.
	 * @param {number} sheetIndex - Index of the sheet in the array.
	 * @param {number} ruleIndex - Index of the calculated column (rule) in the sheet's array.
	 */
	const deleteCalculatedColumn = (sheetIndex, ruleIndex) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			// Remove the rule from the sheet's calculatedColumns array
			newState[sheetIndex].calculatedColumns.splice(ruleIndex, 1);
			return newState;
		});
	};

	/**
	 * move calculated column (rule) up
	 * @param {*} sheetIndex
	 * @param {*} calculatedColumnIndex
	 */
	const moveUp = (sheetIndex, calculatedColumnIndex) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			const rules = newState[sheetIndex].calculatedColumns;

			// Check if current rule and the rule above it are both editable before swapping
			if (
				calculatedColumnIndex > 0 &&
				rules[calculatedColumnIndex].editable &&
				rules[calculatedColumnIndex - 1].editable
			) {
				const temp = rules[calculatedColumnIndex];
				rules[calculatedColumnIndex] = rules[calculatedColumnIndex - 1];
				rules[calculatedColumnIndex - 1] = temp;
			}
			return newState;
		});
	};

	/**
	 * move calculated column (rule) down
	 * @param {*} sheetIndex
	 * @param {*} calculatedColumnIndex
	 */
	const moveDown = (sheetIndex, calculatedColumnIndex) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			const rules = newState[sheetIndex].calculatedColumns;

			// Check if current rule and the rule below it are both editable before swapping
			if (
				calculatedColumnIndex < rules.length - 1 &&
				rules[calculatedColumnIndex].editable &&
				rules[calculatedColumnIndex + 1].editable
			) {
				const temp = rules[calculatedColumnIndex];
				rules[calculatedColumnIndex] = rules[calculatedColumnIndex + 1];
				rules[calculatedColumnIndex + 1] = temp;
			}
			return newState;
		});
	};

	/**
	 * saves changes to dashboard.expectedSheets
	 */
	const saveChanges = async () => {
		setSaving(true);
		const dashboardRef = doc(db, 'dashboards', props.dashboardId);
		await updateDoc(dashboardRef, { expectedSheets: expectedSheets });
		setOriginalExpectedSheets(JSON.parse(JSON.stringify(expectedSheets)));
		setSaving(false);
	};

	return (
		<AccordionUI title='Data Inputs' subtitle='Open to adjust data inputs' parent={true}>
			{expectedSheets?.map((sheet, sheetIndex) => (
				<AccordionUI
					title={`Sheet - ${sheet.name}`}
					subtitle='Open to adjust sheet settings'
					parent={false}
					key={sheetIndex}
				>
					{/* Expected Sheet Name */}
					<>
						<Typography variant='subtitle2' align='center'>
							Expected Sheet Name
						</Typography>
						<TextField
							disabled={saving}
							label='Sheet Name'
							variant='outlined'
							size='small'
							margin='normal'
							value={sheet.name || ''}
							onChange={(e) =>
								updateSheetName(sheetIndex, {
									id: sheet.id,
									name: e.target.value,
								})
							}
							color='secondary'
							fullWidth
						/>
					</>

					{/* Expected Data */}
					<Stack spacing={1} mt={3}>
						{sheet?.expectedFields?.map((field, fieldIndex) => (
							<Stack direction='row' key={fieldIndex} display='flex' justifyContent='center' spacing={1}>
								{/* phi key */}
								<Stack alignItems='center' minWidth='5%'>
									{fieldIndex === 0 && (
										<Typography variant='subtitle2' align='center'>
											PHI Key
										</Typography>
									)}
									<Tooltip
										text='Select if this column is the key to identify who can see the PHI.  There can only be one PHI Key.'
										placement='right'
									>
										<Radio
											size='small'
											checked={field?.phiKey || false}
											onChange={(e) => {
												updatePHIKey(sheetIndex, fieldIndex);
											}}
											value={field?.phiKey || false}
											name={field?.name || ''}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									</Tooltip>
								</Stack>

								{/* phi checkboxes */}
								<Stack alignItems='center' minWidth='5%'>
									{fieldIndex === 0 && (
										<Typography variant='subtitle2' align='center'>
											PHI
										</Typography>
									)}
									<Tooltip
										text='Select if this column contains Protected Health Information (PHI).  Do not enter or include any patient names or Social Security Numbers.'
										placement='right'
									>
										<FormControlLabel
											disabled={saving}
											control={
												<Checkbox
													size='small'
													checked={field?.phi || false}
													onChange={(e) => {
														updateFieldPHI(sheetIndex, fieldIndex, e.target.checked);
													}}
													inputProps={{ 'aria-label': 'controlled' }}
												/>
											}
										/>
									</Tooltip>
								</Stack>

								{/*expected fields */}
								<Stack alignItems='center' minWidth='40%'>
									{fieldIndex === 0 && (
										<Typography variant='subtitle2' align='center'>
											Expected Columns
										</Typography>
									)}
									<TextField
										disabled={saving}
										label='Column Name'
										variant='outlined'
										size='small'
										m={2}
										value={field?.name || ''}
										onChange={(e) =>
											updateFieldName(sheetIndex, fieldIndex, {
												id: sheet.id,
												name: e.target.value,
											})
										}
										fullWidth
									/>
								</Stack>

								{/* data types */}
								<Stack alignItems='center' minWidth='40%'>
									{fieldIndex === 0 && (
										<Typography variant='subtitle2' align='center'>
											Data Types
										</Typography>
									)}

									<FormControl size='small' fullWidth>
										<InputLabel id='dataType-select-label'>Data Type</InputLabel>
										<Select
											labelId='dataType'
											id='dataType'
											value={field?.type || ''}
											label='Data Type'
											onChange={(e) =>
												updateFieldType(sheetIndex, fieldIndex, {
													id: sheet.id,
													type: e.target.value,
												})
											}
										>
											{props?.dataTypes?.map((type, i) => {
												return (
													<MenuItem value={type.name || ''} key={i}>
														{type.name || ''}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Stack>
							</Stack>
						))}
					</Stack>

					{/* Add new field */}
					<Box m={3} sx={{ display: 'flex', justifyContent: 'center' }}>
						<Tooltip text='Adds a new field to the Expected Fields' placement='right'>
							<Button
								disabled={saving}
								variant='outlined'
								color='secondary'
								startIcon={<AddCircleOutlineRoundedIcon />}
								sx={{ width: '150px' }}
								onClick={() => {
									addField(sheetIndex);
								}}
							>
								NEW FIELD
							</Button>
						</Tooltip>
					</Box>

					{/* Calculated Columns */}
					<Stack spacing={2} mt={3}>
						{sheet?.calculatedColumns?.map((calculatedColumn, calculatedColumnIndex) => (
							<Stack
								direction='row'
								key={calculatedColumnIndex}
								width='100%'
								p={2}
								sx={{ backgroundColor: '#f1f1f170', borderRadius: '8px', border: '1px solid lightgrey' }}
							>
								{/* Move Rule Up */}
								<IconButton
									variant='outlined'
									color='primary'
									disabled={
										calculatedColumnIndex === 0 ||
										!calculatedColumn.editable ||
										!sheet.calculatedColumns[calculatedColumnIndex - 1]?.editable
									}
									onClick={() => moveUp(sheetIndex, calculatedColumnIndex)}
								>
									<ArrowUpwardIcon />
								</IconButton>

								{/* Move Rule Down */}
								<IconButton
									variant='outlined'
									color='primary'
									disabled={
										calculatedColumnIndex === sheet.calculatedColumns.length - 1 ||
										!calculatedColumn.editable ||
										!sheet.calculatedColumns[calculatedColumnIndex + 1]?.editable
									}
									onClick={() => moveDown(sheetIndex, calculatedColumnIndex)}
								>
									<ArrowDownwardIcon />
								</IconButton>

								<Stack spacing={1} width='100%'>
									<Stack direction='row' spacing={1} width='100%'>
										{/* Rule Name */}
										<TextField
											disabled={saving || !calculatedColumn.editable}
											label='Rule Name'
											value={calculatedColumn?.ruleName || ''}
											onChange={(e) => handleCalculationChange(sheetIndex, calculatedColumnIndex, e, 'ruleName')}
											fullWidth
											margin='normal'
											variant='outlined'
											size='small'
										/>

										{/* Type */}
										<FormControl fullWidth variant='outlined' size='small' margin='normal'>
											<InputLabel>Data Type</InputLabel>
											<Select
												disabled={saving || !calculatedColumn.editable}
												value={calculatedColumn?.type || ''}
												onChange={(e) => handleCalculationChange(sheetIndex, calculatedColumnIndex, e, 'type')}
												label='Data Type'
											>
												<MenuItem value='Boolean'>Boolean</MenuItem>
												<MenuItem value='Date'>Date</MenuItem>
												<MenuItem value='Number'>Number</MenuItem>
												<MenuItem value='String'>String</MenuItem>
											</Select>
										</FormControl>
									</Stack>

									{/* Conditions */}
									<Stack spacing={1} mb={2}>
										{calculatedColumn?.conditions?.map((condition, conditionIndex) => (
											<Stack direction='row' spacing={2} key={conditionIndex}>
												<TextField
													disabled={saving || !calculatedColumn.editable}
													label='Condition Name'
													value={condition?.conditionName || ''}
													onChange={(e) =>
														handleConditionChange(
															sheetIndex,
															calculatedColumnIndex,
															conditionIndex,
															'conditionName',
															e.target.value
														)
													}
													fullWidth
													margin='normal'
													variant='outlined'
													size='small'
												/>
												<FormControl fullWidth variant='outlined' size='small' margin='normal'>
													<InputLabel>Condition Type</InputLabel>
													<Select
														disabled={saving || !calculatedColumn.editable}
														value={condition?.conditionType || ''}
														onChange={(e) =>
															handleConditionChange(
																sheetIndex,
																calculatedColumnIndex,
																conditionIndex,
																'conditionType',
																e.target.value
															)
														}
														label='Condition Type'
													>
														<MenuItem value='EQUALS'>EQUALS</MenuItem>
														<MenuItem value='GREATER_THAN'>GREATER_THAN</MenuItem>
														<MenuItem value='LESS_THAN'>LESS_THAN</MenuItem>
														<MenuItem value='NOT_EQUALS'>NOT_EQUALS</MenuItem>
														<MenuItem value='BOOLEAN'>BOOLEAN</MenuItem>
													</Select>
												</FormControl>
												<TextField
													disabled={saving || !calculatedColumn.editable || condition?.conditionType === 'BOOLEAN'}
													label='Condition Value'
													value={condition?.conditionValue || ''}
													onChange={(e) =>
														handleConditionChange(
															sheetIndex,
															calculatedColumnIndex,
															conditionIndex,
															'conditionValue',
															e.target.value
														)
													}
													fullWidth
													margin='normal'
													variant='outlined'
													size='small'
												/>

												{/* Delete a Condition */}
												<Tooltip text='Delete a condition. Default rules cannot be modified.'>
													<IconButton
														color='error'
														disabled={saving || !calculatedColumn.editable} // only enable deletion for editable rules
														onClick={() => {
															deleteCondition(sheetIndex, calculatedColumnIndex, conditionIndex);
														}}
													>
														<DeleteIcon />
													</IconButton>
												</Tooltip>
											</Stack>
										))}
										{/* ADD CONDITION BUTTON */}
										<Box justifyContent='right' display='flex'>
											<Button
												disabled={saving || !calculatedColumn.editable}
												variant='contained'
												color='secondary'
												onClick={() => addCondition(sheetIndex, calculatedColumnIndex)}
											>
												Add Condition
											</Button>
										</Box>
									</Stack>

									{/* Calculation */}
									<Stack direction='row' spacing={2}>
										<FormControl fullWidth variant='outlined' size='small' margin='normal'>
											<InputLabel>Calculation Type</InputLabel>
											<Select
												disabled={saving || !calculatedColumn.editable}
												value={calculatedColumn?.calculation?.calculationType || ''}
												onChange={(e) =>
													handleCalculationChange(sheetIndex, calculatedColumnIndex, e, 'calculationType')
												}
												label='Calculation Type'
											>
												<MenuItem value='ADD'>ADD</MenuItem>
												<MenuItem value='SUBTRACT'>SUBTRACT</MenuItem>
												<MenuItem value='MULTIPLY'>MULTIPLY</MenuItem>
												<MenuItem value='DIVIDE'>DIVIDE</MenuItem>
												<MenuItem value='BOOLEAN_CONDITION'>BOOLEAN_CONDITION</MenuItem>
												<MenuItem value='REPORT_DATE'>REPORT_DATE</MenuItem>
												<MenuItem value='SHEET'>SHEET</MenuItem>
												<MenuItem value='DATE_ADD'>DATE_ADD</MenuItem>
												<MenuItem value='ADD_DAYS'>ADD_DAYS</MenuItem>
												<MenuItem value='DATE_SUBTRACT_WITH_TIME'>DATE_SUBTRACT_WITH_TIME</MenuItem>
												<MenuItem value='DATE_SUBTRACT_WITHOUT_TIME'>DATE_SUBTRACT_WITHOUT_TIME</MenuItem>
												<MenuItem value='SUBTRACT_DAYS'>SUBTRACT_DAYS</MenuItem>
											</Select>
										</FormControl>

										<TextField
											disabled={saving || !calculatedColumn.editable}
											label='Calculation Column 1'
											value={calculatedColumn?.calculation?.calculationColumn1 || ''}
											onChange={(e) =>
												handleCalculationChange(sheetIndex, calculatedColumnIndex, e, 'calculationColumn1')
											}
											fullWidth
											margin='normal'
											variant='outlined'
											size='small'
										/>
										<TextField
											disabled={saving || !calculatedColumn.editable}
											label='Calculation Column 2'
											value={calculatedColumn?.calculation?.calculationColumn2 || ''}
											onChange={(e) =>
												handleCalculationChange(sheetIndex, calculatedColumnIndex, e, 'calculationColumn2')
											}
											fullWidth
											margin='normal'
											variant='outlined'
											size='small'
										/>
									</Stack>

									{/* Comparison Operators (if BOOLEAN_CONDITION is chosen) */}
									{calculatedColumn?.calculation?.calculationType === 'BOOLEAN_CONDITION' && (
										<FormControl fullWidth variant='outlined' margin='normal'>
											<InputLabel>Comparison Operator</InputLabel>
											<Select
												disabled={saving || !calculatedColumn.editable}
												value={calculatedColumn?.calculation?.comparisonOperator || ''}
												onChange={(e) =>
													handleCalculationChange(sheetIndex, calculatedColumnIndex, e, 'comparisonOperator')
												}
												label='Comparison Operator'
											>
												<MenuItem value='>'>{'>'}</MenuItem>
												<MenuItem value='<'>{'<'}</MenuItem>
												<MenuItem value='>='>{'>='}</MenuItem>
												<MenuItem value='<='>{'<='}</MenuItem>
												<MenuItem value='==='>{'==='}</MenuItem>
												<MenuItem value='!=='>{'!=='}</MenuItem>
											</Select>
										</FormControl>
									)}

									{/* Delete a Calculated Column */}
									<Stack alignItems='center' mt={2}>
										<Tooltip text='Delete a rule. Default rules cannot be deleted.'>
											<Button
												variant='outlined'
												color='error'
												disabled={saving || !calculatedColumn.editable} // only enable deletion for editable rules
												onClick={() => {
													deleteCalculatedColumn(sheetIndex, calculatedColumnIndex);
												}}
												startIcon={<DeleteIcon />}
											>
												Delete Rule
											</Button>
										</Tooltip>
									</Stack>
								</Stack>
							</Stack>
						))}
					</Stack>

					{/* Add new calculated column */}
					<Box mt={3} mb={5} sx={{ display: 'flex', justifyContent: 'center' }}>
						<Tooltip text='Adds a new Calculated Column' placement='right'>
							<Button
								disabled={saving}
								variant='outlined'
								color='secondary'
								startIcon={<AddCircleOutlineRoundedIcon />}
								// sx={{ width: '150px' }}
								onClick={() => {
									addCalculatedColumn(sheetIndex);
								}}
							>
								ADD CALCULATED COLUMN
							</Button>
						</Tooltip>
					</Box>

					{/* Save Button */}
					<Box mb={5} sx={{ display: 'flex', justifyContent: 'center' }}>
						<Tooltip text='Click to Save the Expected Sheets names.' placement='right'>
							<Button
								disabled={saving || equal}
								variant='contained'
								color='info'
								startIcon={<SaveRoundedIcon />}
								onClick={saveChanges}
							>
								SAVE DATA INPUT CHANGES
							</Button>
						</Tooltip>
					</Box>
				</AccordionUI>
			))}

			{/* Add a New Sheet */}
			<Box m={3} sx={{ display: 'flex', justifyContent: 'center' }}>
				<Tooltip text='Adds a new sheet to the Expected Sheets' placement='right'>
					<Button
						disabled={saving}
						variant='outlined'
						color='secondary'
						startIcon={<AddCircleOutlineRoundedIcon />}
						onClick={addSheet}
					>
						NEW SHEET
					</Button>
				</Tooltip>
			</Box>

			{/* Save Button */}
			<Box mb={5} sx={{ display: 'flex', justifyContent: 'center' }}>
				<Tooltip text='Click to Save the Expected Sheets names.' placement='right'>
					<Button
						disabled={saving || equal}
						variant='contained'
						color='info'
						startIcon={<SaveRoundedIcon />}
						onClick={saveChanges}
					>
						SAVE DATA INPUT CHANGES
					</Button>
				</Tooltip>
			</Box>
		</AccordionUI>
	);
}
