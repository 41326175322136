import React, { useMemo, useEffect, useState, useContext } from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

import { Box, Typography, Stack, Select, MenuItem, Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material';

import moment from 'moment';
import * as d3 from 'd3';

import LoadingSpinner from '../ui/LoadingSpinner';
import Dashboard_Query from './Dashboard_Query';
import AlertContext from '../ui/AlertContext';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin);

export default function Chart_BarChart({ chart, dashboard, onRendered, height = '75%', width = '75%' }) {
	const alertCtx = useContext(AlertContext);
	const [loading, setLoading] = useState(null);
	const [data, setData] = useState(null);
	const [groupedData, setGroupedData] = useState(null);
	const [reportDates, setReportDates] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [datasetVisibility, setDatasetVisibility] = useState({});
	const [useDataSet, setUseDataSet] = useState(null);
	const [yAxisMax, setYAxisMax] = useState(null);
	const [visibleGroupedData, setVisibleGroupedData] = useState(null);

	const toggleDatasetVisibility = (label) => {
		setDatasetVisibility((prevState) => ({
			...prevState,
			[label]: !prevState[label],
		}));
	};

	/**
	 * gets the data for the dashboard
	 */
	useEffect(() => {
		setLoading(true);

		const awaitQuery = async () => {
			setData(null);
			setGroupedData(null);
			setReportDates(null);
			setSelectedDate(null);

			// console.log('dashboard: ', dashboard);
			// console.log('chart: ', chart);
			const response = await Dashboard_Query(dashboard, chart);
			// console.log('response: ', response);

			/**
			 * if data is empty, then display alert
			 * also stop loading
			 */
			if (response.error) {
				if (response.error === 'Firebase Composite Index')
					alertCtx.setMessage(`${response.message} <a href='${response.url}' target='_blank'>Link</a>`);
				else alertCtx.setMessage(response.message);
				alertCtx.setSeverity('error');
				alertCtx.setTitle('Data Query');
				alertCtx.setActive(true);
				setLoading(false);
				return;
			} else if (!response.data) {
				console.log('No Data Returned');
				alertCtx.setMessage('No data returned.');
				alertCtx.setSeverity('warning');
				alertCtx.setTitle('Data Query');
				alertCtx.setActive(true);
				setLoading(false);
				return;
			} else if (response.data) {
				setData(response.data);
			}
		};
		awaitQuery();
	}, [chart, dashboard]);

	// /**
	//  * processes the dates based on the chart settings
	//  */
	// useEffect(() => {
	// 	if (data) {
	// 		// console.log('data: ', data);

	// 		//gets the dates for the menu
	// 		const dateItems = [];
	// 		data.forEach((item) => {
	// 			dateItems.push({ date: item['Report Date'] });
	// 		});

	// 		const datesData = Array.from(
	// 			d3.group(dateItems, (d) => d.date),
	// 			([date]) => ({ date })
	// 		);
	// 		// console.log('datesData: ', datesData);

	// 		/**
	// 		 * display only the Most Recent date
	// 		 */
	// 		if (chart.displayDateRange === 'Most Recent') {
	// 			// Convert the string dates to Date objects for comparison
	// 			const dateObjects = datesData.map((dateItem) => new Date(dateItem.date));

	// 			// Find the latest date
	// 			const latestDate = new Date(Math.max.apply(null, dateObjects));

	// 			// Set selectedDate to the latest date
	// 			setSelectedDate(latestDate.toISOString());
	// 		}

	// 		/**
	// 		 * display All Available Dates
	// 		 */
	// 		if (chart.displayDateRange === 'All Available') {
	// 			setReportDates(datesData);

	// 			// Convert the string dates to Date objects for comparison
	// 			const dateObjects = datesData.map((dateItem) => new Date(dateItem.date));

	// 			// Find the latest date
	// 			const latestDate = new Date(Math.max.apply(null, dateObjects));

	// 			// Set selectedDate to the latest date
	// 			setSelectedDate(latestDate.toISOString());
	// 		}
	// 	}
	// }, [data]);

	/**
	 * groups data by
	 */
	useEffect(() => {
		if (data) {
			// console.log('data', data);

			// Define an array to hold key functions
			const keyFunctions = [];

			// Conditionally add key functions based on chart settings
			// if (chart.barChart_xAxis) keyFunctions.push((d) => d[chart.barChart_xAxis]);

			for (const groupBy of chart.barChart_groupBy) keyFunctions.push((d) => d[groupBy]);

			const calculation = chart.calculation; // This value could come from your database
			const calculationField = chart.calculationField; // This value could come from your database

			// Create a function to calculate the desired measure
			const calculateMeasure = (D) => {
				switch (calculation) {
					case 'count':
						return D.length;
					case 'median':
						return d3.median(D, (d) => d[calculationField]);
					case 'mean':
						return d3.mean(D, (d) => d[calculationField]);
					// ... add cases for other measures as needed
					default:
						throw new Error('Unsupported calculation: ' + calculation);
				}
			};

			// Then use this function in your flatRollup:
			const flatRollupData = d3.flatRollup(
				data,
				calculateMeasure,
				...keyFunctions // Spread the array into arguments
			);

			// console.log('flatRollupData', flatRollupData);

			// Initialize the labels and datasets arrays for Chart.js
			const labels = new Set(); // Using a Set to ensure uniqueness

			const colorPalette = [
				'#1976D280', // Dark Blue
				'#8E44AD80', // Dark Purple
				'#388E3C80', // Dark Green
				'#F39C1280', // Orange
				'#C2185B80', // Dark Pink
				'#0097A780', // Teal
				'#E64A1980', // Dark Orange
				'#7B1FA280', // Purple
				'#C0392B80', // Red
				'#2980B980', // Blue
				'#27AE6080', // Green
				'#D32F2F80', // Dark Red
				'#8E24AA80', // Magenta
				'#FBC02D80', // Yellow
				'#0288D180', // Light Blue
				'#7CB34280', // Lime Green
				'#E5393580', // Light Red
				'#6D4C4180', // Brown
				'#546E7A80', // Blue Grey
				'#D81B6080', // Pink
			];

			const borderPalette = [
				'#1976D2', // Dark Blue
				'#8E44AD', // Dark Purple
				'#388E3C', // Dark Green
				'#F39C12', // Orange
				'#C2185B', // Dark Pink
				'#0097A7', // Teal
				'#E64A19', // Dark Orange
				'#7B1FA2', // Purple
				'#C0392B', // Red
				'#2980B9', // Blue
				'#27AE60', // Green
				'#D32F2F', // Dark Red
				'#8E24AA', // Magenta
				'#FBC02D', // Yellow
				'#0288D1', // Light Blue
				'#7CB342', // Lime Green
				'#E53935', // Light Red
				'#6D4C41', // Brown
				'#546E7A', // Blue Grey
				'#D81B60', // Pink
			];

			function getRandomColor() {
				const letters = '0123456789ABCDEF';
				let color = '#';
				for (let i = 0; i < 6; i++) {
					color += letters[Math.floor(Math.random() * 16)];
				}
				return color;
			}

			// Initialize a Map to hold the category datasets
			const datasetMap = new Map();

			let colorIndex = 0;

			// Iterate over the flat rollup data
			for (const item of flatRollupData) {
				let xAxisLabel = item[0];
				let count = item[item.length - 1];
				let seriesData = item.slice(1, item.length - 1); // Take the middle elements as series data

				labels.add(xAxisLabel);

				// Helper function to capitalize the first letter of a string
				const capitalizeFirstLetter = (string) => {
					const str = String(string); // Convert to string explicitly
					return str.charAt(0).toUpperCase() + str.slice(1);
				};

				// Create a unique dataset key based on seriesData and groupBy
				const datasetKey = chart.barChart_groupBy
					.slice(1)
					.map(
						(groupByItem, index) =>
							`${capitalizeFirstLetter(groupByItem)} (${capitalizeFirstLetter(seriesData[index])})`
					)
					.join(' & ');

				// Initialize the dataset for this key if it doesn't exist yet
				if (!datasetMap.has(datasetKey)) {
					datasetMap.set(datasetKey, {
						label: datasetKey,
						data: [],
						fill: false,
						backgroundColor:
							datasetMap.length > colorPalette.length
								? getRandomColor()
								: colorPalette[colorIndex % colorPalette.length], // Set color as needed
						borderColor:
							datasetMap.length > borderPalette.length
								? getRandomColor()
								: borderPalette[colorIndex % borderPalette.length],
						borderWidth: 2,
						borderRadius: 5,
					});

					colorIndex++;
				}

				// Add the count for the current xAxisLabel and series to the dataset
				const dataset = datasetMap.get(datasetKey);
				if (moment(xAxisLabel).isValid()) {
					dataset.data.push({ x: moment(xAxisLabel).format('MMM DD, YYYY'), y: count });
				} else {
					dataset.data.push({ x: xAxisLabel, y: count });
				}
			}

			// Sort labels conditionally (as Date objects or strings) and convert back to array
			const sortedLabels = Array.from(labels).sort((a, b) => {
				const aIsDate = moment(a).isValid();
				const bIsDate = moment(b).isValid();

				if (aIsDate && bIsDate) {
					return new Date(a) - new Date(b);
				} else if (aIsDate) {
					return 1; // Put a after b
				} else if (bIsDate) {
					return -1; // Put a before b
				} else {
					return a.localeCompare(b);
				}
			});

			// Format labels
			const formattedLabels = sortedLabels.map((label) => {
				if (moment(label).isValid()) {
					return moment(label).format('MMM DD, YYYY');
				}
				return label;
			});

			// Create the final chart data
			const chartData = {
				labels: formattedLabels,
				datasets: Array.from(datasetMap.values()),
			};

			// console.log('Final Chart Data', chartData);

			setGroupedData(chartData);
			setLoading(false);
		}
	}, [data]);

	/**
	 * sends feedback if the component is rendered
	 * used for Dashboard_SendEmail component
	 */
	useEffect(() => {
		if (onRendered && loading === false) {
			onRendered(false); // Pass the loading state back
		}
	}, [loading]);

	/**
	 * sets visibleGroupedData from the checkboxes
	 */
	useEffect(() => {
		const visibleDatasets = groupedData
			? groupedData.datasets.filter((dataset) => datasetVisibility[dataset.label])
			: [];

		setVisibleGroupedData({ ...groupedData, datasets: visibleDatasets });
	}, [groupedData, datasetVisibility]);

	// Determine the maximum value in the dataset
	useEffect(() => {
		if (visibleGroupedData && groupedData) {
			if (chart.barChart_addSideBar) setUseDataSet(visibleGroupedData);
			else setUseDataSet(groupedData);
		}
	}, [visibleGroupedData, groupedData]);

	// Set the Y-axis max to 10, or the max value of the dataset, whichever is higher
	useEffect(() => {
		if (useDataSet) {
			const maxDatasetValue = Math.max(
				...useDataSet.datasets.flatMap((dataset) => dataset.data.map((point) => point.y))
			);
			setYAxisMax(Math.max(chart.barChart_yAxisMax, maxDatasetValue));
		}
	}, [useDataSet]);

	// Chart options
	const options = {
		scales: {
			x: {
				ticks: {
					font: {
						// size: 12,
					},
				},
			},
			y: {
				beginAtZero: true,
				max: chart.barChart_addSideBar ? yAxisMax : null,
			},
		},
		responsive: true,
		aspectRatio: 2, // Adjust to fit your needs
		plugins: {
			legend: {
				display: chart.barChart_includeLegend, // dynamically set based on the boolean
				position: chart.barChart_legendPosition ? chart.barChart_legendPosition : 'top', // Available positions: 'top', 'left', 'bottom', 'right'
				labels: {
					font: {
						size: 13,
					},
				},
			},
			title: {
				display: true,
				text: chart.title,
				fullsize: true,
				font: {
					size: 20,
				},
			},
			annotation: {
				annotations: chart.barChart_referenceLine
					? [
							{
								type: 'line',
								mode: 'horizontal',
								scaleID: 'y',
								value: chart.barChart_referenceLineValue,
								borderColor: chart.barChart_referenceLineColor,
								borderWidth: chart.barChart_referenceLineThickness,
								label: {
									enabled: true,
									content: 'Benchmark',
								},
							},
					  ]
					: [],
			},
		},
		layout: {
			autpadding: true,
		},
	};

	if (loading) return <LoadingSpinner />;

	return (
		<>
			{groupedData ? (
				<Box m={3} sx={{ display: 'flex', justifyContent: 'center' }} height={height} width={width}>
					<Stack flexGrow={1} flexShrink={1}>
						<Stack direction={'row'} spacing={1} flexGrow={1} flexShrink={1}>
							<Box flexGrow={1} flexShrink={1}>
								{/* bar chart */}
								<Bar data={chart.barChart_addSideBar ? visibleGroupedData : groupedData} options={options} />
							</Box>

							{/* side bar */}
							{chart.barChart_addSideBar && (
								<Box
									flexGrow={0}
									flexShrink={1}
									// maxWidth={'25%'}
									sx={{
										maxHeight: '30vh',
										overflowY: 'auto', // Enable vertical scroll
										overflowX: 'auto',
										border: '2px solid gray', // Add border
										borderRadius: '8px',
										backgroundColor: '#f7f7f7', // Set background color
									}}
								>
									{console.log(groupedData.datasets)}
									{groupedData.datasets
										.sort((a, b) => a.label.localeCompare(b.label))
										.map((dataset, index) => (
											<Stack direction={'row'} key={index} alignItems={'center'} textAlign={'left'}>
												{/* checkboxes */}
												<Checkbox
													size='small'
													checked={datasetVisibility[dataset.label] || false}
													onChange={() => toggleDatasetVisibility(dataset.label)}
													color='info'
												/>
												<Typography variant='body2' sx={{ fontSize: '0.7rem' }}>
													{dataset.label}
												</Typography>
											</Stack>
										))}
								</Box>
							)}
						</Stack>
					</Stack>
				</Box>
			) : (
				'Error...'
			)}
		</>
	);
}
