import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
	doc,
	getDoc,
	collection,
	getDocs,
	query,
	where,
	orderBy,
	limit,
	Timestamp,
	getCountFromServer,
} from 'firebase/firestore';

import {
	Button,
	Stack,
	Box,
	Typography,
	Container,
	Autocomplete,
	TextField,
	ButtonGroup,
	Divider,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import PageCards from '../components/ui/PageCards';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';
import LoadingSpinner from '../components/ui/LoadingSpinner';
// import DashboardBarChart from '../components/dashboard/DashboardBarChart';
import Chart_Table from '../components/dashboard/Chart_Table';
import Chart_BarChart from '../components/dashboard/Chart_BarChart';
import Chart_IFrame from '../components/dashboard/Chart_IFrame';
import { db } from '../App';

/**
 *
 * @param {*} dashboard
 * @param {*} claimsCtx
 * @returns returns true if user can access this dashboard (not restricted), otherwise returns false
 */
const userCanView = (dashboard, claimsCtx) => {
	let response = false;
	for (const restriction of dashboard.restrictedTo) {
		switch (restriction) {
			case 'Admin':
				if (claimsCtx.claims.admin) response = true;
				break;
			case 'Board of Directors':
				if (claimsCtx.claims.BoardOfDirectors) response = true;
				break;
			case 'EPT APP':
				if (claimsCtx.claims.isEPT && checkIfAPP(claimsCtx.claims.memberType)) response = true;
				break;
			case 'EPT Physician':
				if (claimsCtx.claims.isEPT && claimsCtx.claims.memberType === 'Physician') response = true;
				break;
			case 'Office':
				if (claimsCtx.claims.memberType === 'Office') response = true;
				break;
			case 'OPT APP':
				if (claimsCtx.claims.isOPT && checkIfAPP(claimsCtx.claims.memberType)) response = true;
				break;
			case 'OPT Physician':
				if (claimsCtx.claims.isOPT && claimsCtx.claims.memberType === 'Physician') response = true;
				break;
			case 'PBC Committee':
				if (claimsCtx.claims.PBCCommittee) response = true;
				break;
			default:
				response = false;
		}
	}

	return response;
};

const checkIfAPP = (memberType) => {
	if (
		memberType === 'APP - Tier 1' ||
		memberType === 'APP - Tier 2' ||
		memberType === 'APP - Tier 3' ||
		memberType === 'APP - Tier 4' ||
		memberType === 'APP - Tier 4T'
	)
		return true;
	else return false;
};

function getActiveDashboards(dashboards) {
	let activeDashboardList = [];

	for (const dashboard of dashboards) {
		if (dashboard?.dashboard?.active) activeDashboardList.push(dashboard);
		// if (!dashboard?.disabled) activeDashboardList.push(dashboard);
	}
	return activeDashboardList;
}

function Dashboards() {
	const claimsCtx = useContext(CustomClaimsContext);
	const [loading, setLoading] = useState(false);
	const [activeDashboards, setActiveDashboards] = useState(null);
	const [selectedDashboard, setSelectedDashboard] = useState(null);
	const [selectedChart, setSelectedChart] = useState(null);

	//gets the active dashboards and charts to display
	useEffect(() => {
		const getCharts = async () => {
			setLoading(true);
			const tempDashboards = [];
			const querySnapshot = await getDocs(collection(db, 'dashboards'));
			querySnapshot.forEach((doc) => {
				tempDashboards.push(doc.data());
			});

			// Fetching active charts of the active dashboard
			const active = getActiveDashboards(tempDashboards);

			// Sort active by active.name
			const sortedActive = active.sort((a, b) => a.name.localeCompare(b.name));
			// console.log(sortedActive);
			setActiveDashboards(sortedActive);
			setLoading(false);
		};
		getCharts();
	}, []);

	//sets the initial selectedDashboard
	useEffect(() => {
		if (!selectedDashboard && activeDashboards) {
			for (const dashboard of activeDashboards) {
				const { restricted } = dashboard.dashboard;
				if (!restricted || (restricted && userCanView(dashboard.dashboard, claimsCtx))) {
					// console.log(dashboard.name);
					// console.log(userCanView(dashboard.dashboard, claimsCtx));
					setSelectedDashboard(dashboard);
					break;
				}
			}
		}
	}, [activeDashboards]);

	//sets the initial selectedChart
	useEffect(() => {
		if (setSelectedDashboard && selectedDashboard) {
			for (const chart of selectedDashboard?.dashboard?.charts || []) {
				if (chart.active) {
					const { restricted } = chart;
					if (!restricted || (restricted && userCanView(chart, claimsCtx))) {
						setSelectedChart(chart);
						break;
					}
				}
			}
		}
	}, [selectedDashboard, setSelectedDashboard, claimsCtx]);

	const handleChartChange = (chart) => {
		setSelectedChart(chart);
	};

	// useEffect(() => {
	// 	// console.log('selectedChart', selectedChart);
	// 	// if (
	// 	// 	selectedChart?.type !== 'Bar Chart' ||
	// 	// 	selectedChart?.type !== 'Table' ||
	// 	// 	selectedChart?.type !== 'External Page'
	// 	// )
	// 	// 	console.log('here');
	// 	// console.log('activeDashboards', activeDashboards);
	// 	// console.log('selectedDashboard', selectedDashboard);
	// }, [selectedChart, activeDashboards, selectedDashboard]);

	if (loading || !selectedChart || !selectedDashboard)
		return (
			<Grid xs={12}>
				<PageCards>
					<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
						Dashboards
					</Typography>

					<LoadingSpinner />
				</PageCards>
			</Grid>
		);

	return (
		<PageCards>
			{/* page title */}
			<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
				Dashboards
			</Typography>

			{selectedDashboard && selectedChart && (
				<Grid container sx={{ display: { xs: 'block' } }}>
					<Grid xs={12} style={{ flexGrow: 1 }}>
						{/* dashboards tabs - only displays active */}
						<Box>
							<Stack direction='row' spacing={1} justifyContent='center'>
								{activeDashboards?.map((dashboard, dashboardIndex) => {
									if (
										!dashboard.dashboard.restricted ||
										(dashboard.dashboard.restricted && userCanView(dashboard.dashboard, claimsCtx))
									)
										return (
											<Button
												variant={dashboard === selectedDashboard ? 'contained' : 'outlined'}
												color={dashboard === selectedDashboard ? 'primary' : 'secondary'}
												size='large'
												key={dashboardIndex}
												onClick={() => {
													setSelectedChart(null);
													setSelectedDashboard(dashboard);
												}}
											>
												{dashboard.name}
											</Button>
										);
								})}
							</Stack>

							{selectedDashboard && (
								<Box>
									{/* dashboard info */}
									<div
										dangerouslySetInnerHTML={{ __html: selectedDashboard?.dashboard?.info }}
										style={{ margin: '2rem', display: 'flex', justifyContent: 'center' }}
									/>

									{/* chart tabs - only displays active charts */}
									<Box display='flex' justifyContent='center'>
										<ButtonGroup>
											{selectedDashboard?.dashboard?.charts?.map((chart, chartIndex) => {
												if (chart.active && (!chart.restricted || (chart.restricted && userCanView(chart, claimsCtx))))
													return (
														<Button
															key={chartIndex}
															color={selectedChart === chart ? 'selectedButton' : 'unSelectedButton'}
															variant={selectedChart === chart ? 'contained' : 'outlined'}
															disableElevation
															onClick={() => {
																handleChartChange(chart);
															}}
														>
															{chart.title}
														</Button>
													);
											})}
										</ButtonGroup>
									</Box>

									<Box display='flex' justifyContent='center'>
										<Divider variant='middle' sx={{ margin: '0.25rem', width: '75%' }} />
									</Box>
								</Box>
							)}
						</Box>

						{/* charts */}
						{!selectedChart && <LoadingSpinner />}
						{selectedDashboard && selectedChart && (
							<Box display={'flex'} justifyContent={'center'} mt={2}>
								{selectedChart?.type === 'Bar Chart' && (
									<Chart_BarChart chart={selectedChart} dashboard={selectedDashboard} />
								)}
								{selectedChart?.type === 'Table' && <Chart_Table chart={selectedChart} dashboard={selectedDashboard} />}
								{selectedChart?.type === 'External Page' && (
									<Chart_IFrame chart={selectedChart} dashboard={selectedDashboard} height='3500px' />
								)}
							</Box>
						)}
					</Grid>
				</Grid>
			)}
		</PageCards>
	);
}

export default Dashboards;
