//finds the Parent path to a path string
export default function ParentPath(path) {
	const regexSlash = /\//g;
	let slashLastIndex;
	let childrenLastIndex;

	while (regexSlash.test(path)) {
		slashLastIndex = regexSlash.lastIndex;
	}

	let parent = path.slice(0, slashLastIndex - 1);

	const regexChildren = /children/g;
	while (regexChildren.test(parent)) {
		childrenLastIndex = regexChildren.lastIndex;
	}

	if (parent.length === childrenLastIndex) {
		return parent.slice(0, parent.length - 9);
	}

	return parent;
}
