import React, { useContext, useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Grid from '@mui/material/Unstable_Grid2';

import AuthContext from '../components/auth/authContext';
import MyInfo from '../components/myInfo/MyInfo';
import EmailSignature from '../components/myInfo/EmailSignature';
import PublicInfoConsent from '../components/myInfo/PublicInfoConsent';

function MyInfoPage() {
	const authCtx = useContext(AuthContext);
	const [newInfo, setNewInfo] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loadedData, setLoadedData] = useState(null);

	const handleNewInfo = (data) => {
		const adminResponse = data;
		setLoadedData((loadedData) => ({
			...loadedData,
			adminResponse,
		}));
		setNewInfo(true);
		setTimeout(() => {
			setNewInfo(false);
		}, 100);
	};

	//getMyInfo on initial load
	useEffect(() => {
		if (authCtx.email && authCtx.isLoggedIn) {
			setIsLoading(true);

			//gets info on member once logged in
			const functions = getFunctions();
			const getMyInfo = httpsCallable(functions, 'getMyInfo');
			getMyInfo({
				// email: 'test@ept911.com',
			}).then((result) => {
				if (result) {
					// console.log(result.data);
					if (result.data.code === 401) {
						authCtx.logout();
						// console.log('Logged out.');
					} else {
						// console.log(result.data);
						setLoadedData(result.data);
						setIsLoading(false);
					}
				}
			});
		}
	}, [authCtx.email, authCtx.isLoggedIn, authCtx]);

	return (
		<Grid container spacing={1}>
			<Grid xs={12} md={6}>
				<MyInfo isLoggedIn={authCtx.isLoggedIn} newInfo={handleNewInfo} isLoading={isLoading} loadedData={loadedData} />
			</Grid>
			<Grid xs={12} md={6}>
				<PublicInfoConsent loadedData={loadedData} />
				<EmailSignature newInfo={newInfo} isLoading={isLoading} loadedData={loadedData} />
			</Grid>
		</Grid>
	);
}

export default MyInfoPage;
