import React, { useEffect, useState, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import {
	TextField,
	FormGroup,
	FormControlLabel,
	Button,
	Stack,
	Typography,
	ToggleButton,
	ToggleButtonGroup,
	Switch,
} from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import AlertContext from '../ui/AlertContext';
import PageCards from '../ui/PageCards';
import classes from './EmailSignature.module.css';
import phoneNumber from '../localFunctions/phoneNumber';
import customSchemasNew from '../localFunctions/customSchemasNew';
import Tooltip from '../ui/Tooltip';
import LoadingSpinner from '../ui/LoadingSpinner';
import formatPhoneNumber from '../localFunctions/formatPhoneNumber';
import { db } from '../../App';

const eptFaxNumber = '(757) 467-4173';
const webAddress = 'ept911.com';
const workAddress = '6379 Center Drive, Norfolk, VA 23502';
const eptLogo = 'https://logos.ept911.com/static/media/Logo%20-%20Email%20Signature%20-%20EPT.e868df80bb703b0c9643.png';

export default function EmailSignature(props) {
	const auth = getAuth();
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(true);
	const [isUploading, setIsUploading] = useState(false);
	const [data, setData] = useState(null);
	const [thumbnail, setThumbnail] = useState(null);
	const [faxType, setFaxType] = useState(null);
	const mainPhone = '(757) 467-4200';
	const [emailPositions, setEmailPositions] = useState(null);
	const [emailSettings, setEmailSettings] = useState(null);

	//loads the data
	useEffect(() => {
		const getData = async () => {
			if (props.loadedData) {
				setThumbnail(props.loadedData.peopleResponse);
				if (data === null) setData(props.loadedData.adminResponse);
			}

			if (!emailPositions && auth?.currentUser?.uid) {
				const docRef = doc(db, 'Users', auth.currentUser.uid);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					const data = docSnap.data();
					setEmailPositions(data?.Positions || {});
					setEmailSettings(data?.EmailSettings || {});
					// console.log('Document data:', docSnap.data());
				} else {
					setEmailPositions({});
					setEmailSettings({});
					// docSnap.data() will be undefined in this case
					// console.log('No such document!');
				}
			}
		};
		if (auth) getData();
	}, [props.loadedData, auth?.currentUser?.uid]);

	//sets the initial faxType
	useEffect(() => {
		if (data && emailPositions && emailSettings && isLoading) {
			setIsLoading(false);
			if (phoneNumber(data, 'work_fax') && phoneNumber(data, 'work_fax') !== eptFaxNumber) setFaxType('work_fax');
			else setFaxType('ept_fax');
		}
	}, [data]);

	//sets the initial fax number inside of data
	useEffect(() => {
		if (!isLoading && faxType === 'ept_fax') {
			setData((prevState) => {
				const newState = { ...prevState };
				const existingIndex = newState.phones.findIndex((phone) => phone.type === 'work_fax');
				if (existingIndex !== -1) {
					// If the object exists, update its value
					newState.phones[existingIndex].value = eptFaxNumber;
				} else {
					// If the object doesn't exist, add it to the array
					newState.phones.push({ type: 'work_fax', value: eptFaxNumber });
				}
				return newState;
			});
		}
	}, [faxType, isLoading]);

	const handleFaxChange = (event, newFax) => {
		if (newFax === null) {
			setFaxType('ept_fax');
		} else setFaxType(newFax);
		if (newFax === 'ept_fax') {
			setData((prevState) => {
				const newState = { ...prevState };
				newState.phones.work_fax = eptFaxNumber;
				return newState;
			});
		}
	};

	const handleFaxNumberChange = (e) => {
		let number = e.target.value;
		number = formatPhoneNumber(number);

		setData((prevState) => {
			const newState = { ...prevState };
			const existingIndex = newState.phones.findIndex((phone) => phone.type === 'work_fax');
			if (existingIndex !== -1) {
				// If the object exists, update its value
				newState.phones[existingIndex].value = number;
			} else {
				// If the object doesn't exist, add it to the array
				newState.phones.push({ type: 'work_fax', value: number });
			}
			return newState;
		});
	};

	const handlePositions = async (toggleName) => {
		setIsUploading(true);

		const newData = { ...emailPositions };
		newData[toggleName].DisplayInEmailSignature = !newData[toggleName].DisplayInEmailSignature;

		try {
			const ref = doc(db, 'Users', auth.currentUser.uid);
			await updateDoc(ref, { Positions: newData });

			setIsUploading(false);
		} catch (error) {
			setIsUploading(false);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage(error);
			alertCtx.setTimer(10000);
			newData[toggleName].DisplayInEmailSignature = !newData[toggleName].DisplayInEmailSignature; //resets consent if it was not successfully saved
			console.log(error);
		}

		setEmailPositions(newData);
	};

	const handleEmailSettings = async (toggleName) => {
		setIsUploading(true);

		const newData = { ...emailSettings };
		newData[toggleName] = !newData[toggleName];

		try {
			const ref = doc(db, 'Users', auth.currentUser.uid);
			await updateDoc(ref, { EmailSettings: newData });

			setIsUploading(false);
		} catch (error) {
			setIsUploading(false);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage(error);
			alertCtx.setTimer(10000);
			newData[toggleName] = !newData[toggleName]; //resets consent if it was not successfully saved
			console.log(error);
		}

		setEmailSettings(newData);
	};

	const handleSalutationChange = (e, toggleName) => {
		setEmailSettings((prevState) => {
			const newState = { ...prevState };
			newState[toggleName] = e.target.value;
			return newState;
		});
	};

	const handleSalutationSave = async () => {
		setIsUploading(true);

		const newData = { ...emailSettings };

		try {
			const ref = doc(db, 'Users', auth.currentUser.uid);
			await updateDoc(ref, { EmailSettings: newData });

			setIsUploading(false);
		} catch (error) {
			setIsUploading(false);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage(error);
			alertCtx.setTimer(10000);
			console.log(error);
		}

		setEmailSettings(newData);
	};

	//submits email signature
	const handleSubmitEmailSignature = async () => {
		setIsUploading(true);

		const signature = `<br></br>
			<div style='display:block; max-width:375px;'>
			${emailSettings.SalutationFirstLine || ''}
			${emailSettings.SalutationFirstLine ? '<br></br>' : ''}
			${emailSettings.SalutationSecondLine || ''}
			${
				thumbnail === null
					? ''
					: `<div style='display:flex;'>
					<img style='border:none; border-radius:90px; margin-right:0.5rem; maxHeight:7.5rem; margin-top:auto; margin-bottom:auto' src=${thumbnail} />`
			}
	
					<div style='display:block; padding-top:0.5rem; justify-content:center; marginTop:auto; marginBottom:auto;'>
						<div style='font-weight:bold; color:#4472c4; font-size:0.85rem;'}>
							${data.name.fullName}${customSchemasNew(data, 'Credentials') ? `, ${customSchemasNew(data, 'Credentials')}` : ''}
						</div>
						${
							emailPositions &&
							Object.keys(emailPositions)
								.map((key) => {
									return emailPositions[key].DisplayInEmailSignature
										? `<div style='font-weight:bold; color:black; font-size:0.75rem;'>${key}</div>`
										: null;
								})
								.join('') // Use join('') to concatenate without commas
						}
						${
							emailSettings.IncludeMobile || emailSettings.IncludeOffice
								? `
						<div style='display:flex; font-size: 0.7rem'>
							${emailSettings.IncludeMobile && phoneNumber(data, 'mobile') ? `Mobile: ${phoneNumber(data, 'mobile')}` : ''}
							${
								emailSettings.IncludeMobile &&
								phoneNumber(data, 'mobile') &&
								emailSettings.IncludeOffice &&
								phoneNumber(data, 'work')
									? `<span>&nbsp;</span>
										<span>&nbsp;</span>`
									: ''
							}
							${emailSettings.IncludeOffice && phoneNumber(data, 'work') ? `Office: ${phoneNumber(data, 'work')}` : ''}
						</div>`
								: ''
						}
						${
							emailSettings.IncludeMain || emailSettings.IncludeFax
								? `
						<div style='display:flex; font-size: 0.7rem'>
							${emailSettings.IncludeMain && mainPhone ? `Main: ${mainPhone}` : ''}
							${
								emailSettings.IncludeMain && mainPhone && emailSettings.IncludeFax && phoneNumber(data, 'work_fax')
									? `<span>&nbsp;</span>
										<span>&nbsp;</span>`
									: ''
							}
							${emailSettings.IncludeFax && phoneNumber(data, 'work_fax') ? `Fax: ${phoneNumber(data, 'work_fax')}` : ''}
						</div>`
								: ''
						}
						<div style='font-size:0.7rem'>
							Email: <a href=mailto:${data.primaryEmail} target='_blank' style='color:#4472c4'><u>${data.primaryEmail}</u></a>
						</div>
						<div style='font-size: 0.7rem;'>
							<a href='${webAddress}}' target='_blank' style='color: #4472c4;'><u>Emergency Physicians of Tidewater</u></a>
						</div>
						<div style='font-size: 0.7rem'>${workAddress}</div>
					</div>
				</div>			
				<img src='${eptLogo}' />
				<br/>
			</div>`;

		const functions = getFunctions();
		const updateSignature = httpsCallable(functions, 'updateSignature');
		const response = await updateSignature({
			signature: signature,
		});

		if (response) {
			if (response.data.code === 200) {
				setIsUploading(false);
				alertCtx.setActive(true);
				alertCtx.setSeverity('success');
				alertCtx.setMessage('Your signature was updated successfully. <strong>Refresh Gmail</strong> if it is open.');
				alertCtx.setTimer(10000);
			} else {
				setIsUploading(false);
				alertCtx.setActive(true);
				alertCtx.setSeverity('error');
				alertCtx.setMessage('Your signature was <strong>not</strong> updated. Try again.');
				alertCtx.setTimer(10000);
			}
		}
	};

	//if loading return loader...
	if (isLoading) {
		return (
			<PageCards>
				<Typography variant='h3' textAlign='center' color={'primary'}>
					Email Signature
				</Typography>
				<LoadingSpinner />
			</PageCards>
		);
	}

	//if done loading return...
	return (
		<PageCards>
			<Typography variant='h3' textAlign='center' mb={2} color={'primary'}>
				Email Signature
			</Typography>

			{/* include positions */}
			<Typography fontWeight={'bold'} textAlign={'center'}>
				Positions to include in my Email Signature.
			</Typography>
			<Stack mb={2}>
				{emailPositions &&
					Object.keys(emailPositions).map((key, index) => {
						return (
							<FormControlLabel
								key={key}
								control={
									<Switch
										checked={emailPositions[key].DisplayInEmailSignature || false}
										onChange={() => {
											handlePositions(key);
										}}
										color='primary'
									/>
								}
								disabled={isUploading}
								label={key}
								labelPlacement='end'
							/>
						);
					})}
			</Stack>

			{/* include phone numbers */}
			<Typography fontWeight={'bold'} textAlign={'center'}>
				Phone Numbers to include in my Email Signature.
			</Typography>
			<Stack mb={2}>
				{/* include mobile */}
				{phoneNumber(data, 'mobile') && (
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									disabled={isUploading}
									checked={emailSettings.IncludeMobile || false}
									onChange={() => {
										handleEmailSettings('IncludeMobile');
									}}
									color='primary'
								/>
							}
							label='Include: Mobile'
						/>
					</FormGroup>
				)}

				{/* include office */}
				{phoneNumber(data, 'work') && (
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									disabled={isUploading}
									checked={emailSettings.IncludeOffice || false}
									onChange={() => {
										handleEmailSettings('IncludeOffice');
									}}
									color='primary'
								/>
							}
							label='Include: Office'
						/>
					</FormGroup>
				)}

				{/* include main */}
				{mainPhone && (
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									disabled={isUploading}
									checked={emailSettings.IncludeMain || false}
									onChange={() => {
										handleEmailSettings('IncludeMain');
									}}
									color='primary'
								/>
							}
							label='Include: Main'
						/>
					</FormGroup>
				)}

				{/* include fax */}
				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								disabled={isUploading}
								checked={emailSettings.IncludeFax || false}
								onChange={() => {
									handleEmailSettings('IncludeFax');
								}}
								color='primary'
							/>
						}
						label='Include: Fax'
					/>
				</FormGroup>
			</Stack>

			{/* fax number choices */}
			<ToggleButtonGroup
				disabled={!emailSettings.IncludeFax || isUploading}
				size='small'
				color='secondary'
				value={faxType}
				exclusive
				onChange={handleFaxChange}
				id='Fax Types'
			>
				<ToggleButton value='ept_fax'>EPT Fax</ToggleButton>
				<ToggleButton value='work_fax'>Custom</ToggleButton>
			</ToggleButtonGroup>

			{/* fax number */}
			<TextField
				margin='dense'
				id='faxNumber'
				disabled={!emailSettings.IncludeFax || faxType === 'ept_fax' || isUploading}
				label='Fax Number'
				fullWidth
				variant='outlined'
				color='secondary'
				onChange={handleFaxNumberChange}
				value={
					faxType === 'ept_fax' ? eptFaxNumber : phoneNumber(data, 'work_fax') ? phoneNumber(data, 'work_fax') : ''
				}
			/>

			{/* salutation first line */}
			<TextField
				sx={{ marginTop: 4 }}
				disabled={isUploading}
				margin='dense'
				id='salutationFirstLine'
				label='Salutation First Line'
				fullWidth
				variant='outlined'
				color='secondary'
				onBlur={handleSalutationSave}
				onChange={(e) => {
					handleSalutationChange(e, 'SalutationFirstLine');
				}}
				value={emailSettings.SalutationFirstLine || ''}
			/>

			{/* salutation second line */}
			<TextField
				disabled={isUploading}
				margin='dense'
				id='salutationSecondLine'
				label='Salutation Second Line'
				fullWidth
				variant='outlined'
				color='secondary'
				onBlur={handleSalutationSave}
				onChange={(e) => {
					handleSalutationChange(e, 'SalutationSecondLine');
				}}
				value={emailSettings.SalutationSecondLine || ''}
			/>

			<br />
			<br />

			{/* signature card */}
			<div className={classes.signatureHeader}>
				<Tooltip text='Your signature with appear without the border.' />
				Your Signature will appear like this:
			</div>
			<div className={classes.signatureCard}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginLeft: 'auto',
						marginRight: 'auto',
					}}
				>
					<div style={{ display: 'block', justifyContent: 'center', maxWidth: '375px' }}>
						{emailSettings.SalutationFirstLine || null}
						<br></br>
						{emailSettings.SalutationSecondLine || null}
						<div style={{ display: 'flex', justifyContent: 'left' }}>
							{/* user image */}
							<img
								style={{
									border: 'none',
									borderRadius: '90px',
									maxHeight: '7.5rem',
									marginTop: 'auto',
									marginBottom: 'auto',
									marginRight: '0.5rem',
								}}
								src={thumbnail}
								alt=''
								referrerPolicy='no-referrer'
							/>

							{/* inner container */}
							<div style={{ display: 'block', justifyContent: 'center', marginTop: 'auto', marginBottom: 'auto' }}>
								{/* name */}
								<div style={{ fontWeight: 'bold', color: '#4472c4', fontSize: '0.85rem' }}>
									{data.name.fullName}
									{customSchemasNew(data, 'Credentials') ? ', ' + customSchemasNew(data, 'Credentials') : ''}
								</div>

								{/* positions */}
								{emailPositions &&
									Object.keys(emailPositions).map((key, index) =>
										emailPositions[key].DisplayInEmailSignature ? (
											<div key={key} style={{ fontWeight: 'bold', color: 'black', fontSize: '0.75rem' }}>
												{key}
											</div>
										) : (
											''
										)
									)}

								{/* mobile & office phones*/}
								<div style={{ display: 'flex', fontSize: '0.7rem' }}>
									{emailSettings.IncludeMobile ? <div>Mobile: {phoneNumber(data, 'mobile')}</div> : ''}
									{emailSettings.IncludeMobile &&
									phoneNumber(data, 'mobile') &&
									emailSettings.IncludeOffice &&
									phoneNumber(data, 'work') ? (
										<div>
											<span>&nbsp;</span>
											<span>&nbsp;</span>
											<span>&nbsp;</span>
											<span>&nbsp;</span>
										</div>
									) : (
										''
									)}
									{emailSettings.IncludeOffice && phoneNumber(data, 'work') ? (
										<div>Office: {phoneNumber(data, 'work')}</div>
									) : (
										''
									)}
								</div>

								{/* main & fax phones */}
								<div style={{ display: 'flex', fontSize: '0.7rem' }}>
									{emailSettings.IncludeMain && mainPhone ? <div>Main: {mainPhone}</div> : ''}
									{emailSettings.IncludeMain &&
									mainPhone &&
									emailSettings.IncludeFax &&
									phoneNumber(data, 'work_fax') ? (
										<div>
											<span>&nbsp;</span>
											<span>&nbsp;</span>
											<span>&nbsp;</span>
											<span>&nbsp;</span>
										</div>
									) : (
										''
									)}
									{emailSettings.IncludeFax && phoneNumber(data, 'work_fax') ? (
										<div>Fax: {phoneNumber(data, 'work_fax')}</div>
									) : (
										''
									)}
								</div>

								{/* ept email link */}
								<div style={{ fontSize: '0.7rem' }}>
									Email:
									<a
										href={`mailto:${data.eptEmail}`}
										target={'_blank'}
										rel='noreferrer'
										style={{ color: '#4472c4', fontSize: '0.7rem' }}
									>
										<span>&nbsp;</span>
										<u>{data.primaryEmail}</u>
									</a>
								</div>

								{/* company link */}
								<div style={{ color: '#4472c4', fontSize: '0.7rem' }}>
									<a href={webAddress} target={'_blank'} rel='noreferrer'>
										<u>Emergency Physicians of Tidewater</u>
									</a>
								</div>

								{/* company address */}
								<div style={{ fontSize: '0.7rem' }}>{workAddress}</div>
							</div>
						</div>
						{/* ept logo */}
						<img src={eptLogo} alt='ept logo' />
					</div>
				</div>
			</div>
			<br></br>
			{!isUploading && (
				<Stack direction='row' spacing={2} justifyContent='center'>
					<Button
						variant='contained'
						startIcon={<SaveRoundedIcon />}
						color='saveButton'
						onClick={handleSubmitEmailSignature}
					>
						SAVE
					</Button>
				</Stack>
			)}
			{isUploading && <LoadingSpinner />}
		</PageCards>
	);
}
