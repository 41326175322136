import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../App';

/**
 * finds the current user from Firestore Users collection and returns the field of interest
 * @param {string} field - the field to return from the User
 * @returns a field from the Users document or null if nothing exists
 */
export default function GetUserData(field) {
	return new Promise((resolve) => {
		const auth = getAuth();

		const getData = async () => {
			const docRef = doc(db, 'Users', auth.currentUser.uid);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				const data = docSnap.data();
				resolve(data?.[field] || null);
			} else {
				resolve({});
			}
		};

		if (auth?.currentUser?.uid) {
			getData();
		}
	});
}
