import { getFunctions, httpsCallable } from 'firebase/functions';

const UpdateDirectoryInfo = (submitterEmail, submitterName, newData, originalData) => {
	return new Promise((response) => {
		const functions = getFunctions();
		const updateDirectoryInfo = httpsCallable(functions, 'updateDirectoryInfo');
		updateDirectoryInfo({
			submitterEmail: submitterEmail,
			submitterName: submitterName,
			newData: newData,
			originalData: originalData,
		}).then((result) => {
			// console.log(result);
			if (result.data === 'success') {
				response(true);
			} else if (result.data === 'no_new_data') {
				response('no_new_data');
			} else {
				response(false);
			}
		});
	});
};

export default UpdateDirectoryInfo;
