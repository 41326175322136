import { API_KEY } from '../auth/SignIn';
import { gapi } from 'gapi-script';

/**
 *
 * @param {*} emailAddress - the email address provided which will get a photoURL
 * @param {*} token - the accessToken for the user
 * @returns - the photoURL of the email address provided
 */
export default function GetPhotoURL(emailAddress, token) {
	return new Promise((succeeded) => {
		const initClient = async () => {
			gapi.client.setToken({ access_token: token });

			try {
				await gapi.client
					.request({
						method: 'GET',
						path: `https://admin.googleapis.com/admin/directory/v1/users/${emailAddress}?projection=basic&viewType=domain_public&key=${API_KEY}`,
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then((response) => {
						// console.log(emailAddress);
						// console.log(response.result.thumbnailPhotoUrl);
						succeeded(response.result.thumbnailPhotoUrl);
					});
			} catch (error) {
				// console.error('Error: ' + error.result.error.message);
				succeeded({ title: 'Error', message: error.result.error.message });
			}
		};

		if (emailAddress === '') {
			succeeded('');
		} else {
			gapi.load('client', initClient);
		}
	});
}
