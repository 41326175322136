import moment from 'moment';
import RemoveTimeZone from './RemoveTimeZone';

const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

/**
 * loops through each cell and sets formatting based on header
 * @param {*} headers
 * @param {*} column
 * @param {*} cell
 */
export default function Formatter(headers, column, cell) {
	for (const header of headers) {
		if (column === header.id) {
			switch (header.type) {
				case 'Date':
					return moment(RemoveTimeZone(cell)).format('MMM D, YYYY');
				case 'Currency':
					return currencyFormatter.format(cell);
				case 'String':
					return `${cell}`;
				default:
					return `${cell}`;
			}
		}
	}
}
