import React, { useContext } from 'react';
import { Fragment, useState, useEffect } from 'react';

import moment from 'moment';

import { collection, getDoc, getDocs, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { Stack, Typography, TextField, Button, InputAdornment } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import LoadingSpinner from '../ui/LoadingSpinner';
import AreObjectsEqual from '../localFunctions/AreObjectsEqual';
import { db } from '../../App';
import Tooltip from '../ui/Tooltip';
import CustomClaimsContext from '../auth/CustomClaimsContext';

export default function TickSettings() {
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [data, setData] = useState(null);
	const [originalData, setOriginalData] = useState(null);
	const claimsCtx = useContext(CustomClaimsContext)

	//get Settings from Firestore
	useEffect(() => {
		if (db) {
			const getData = async () => {
				setLoading(true);

				const tempData = {};
				const querySnapshot = await getDocs(collection(db, 'settings'));
				querySnapshot.forEach((document) => {
					let data = document.data();
					tempData[document.id] = { ...data };
					// console.log(data);
				});

				setData(tempData);
				setOriginalData(JSON.parse(JSON.stringify(tempData)));
				// console.log(tempData['Last TickLog Download'].date.toDate());

				setLoading(false);
			};

			getData();
		}
	}, [db]);

	/**
	 * @param {string} e - value of item being updated
	 * @param {string} document - the name of the document to be updated
	 * @param {string} field - the field to be updated (i.e. hourlyRate)
	 * @param {string} fieldIndex - an index if one exists for the field (i.e. a field with an array)
	 */
	const handleChange = (e, document, field, fieldIndex) => {
		setData((prevData) => {
			const newData = { ...prevData };
			if (fieldIndex !== null) {
				newData[document][field][fieldIndex] = e;
			} else newData[document][field] = e;
			return newData;
		});
	};

	/**
	 * removes item
	 * @param {string} ID - the ID of the thing to be updated
	 * @param {string} field - the field to be updated (i.e. groups)
	 * @param {string} index - a index of the field for the item to be remove
	 */
	const handleRemoval = (ID, item, index) => {
		setData((prevData) => {
			const newData = { ...prevData };
			newData[ID][item].splice([index], 1);
			return newData;
		});
	};

	const handleSave = async (ID, Document) => {
		setSaving(true);

		const ref = doc(db, 'settings', ID);
		await updateDoc(ref, { ...Document });

		const docRef = doc(db, 'settings', ID);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setData((prevData) => {
				const newData = { ...prevData };
				newData[ID] = { ...docSnap.data() };
				return newData;
			});
			setOriginalData((prevData) => {
				const newData = { ...prevData };
				newData[ID] = { ...docSnap.data() };
				return JSON.parse(JSON.stringify(newData));
			});
		}

		setSaving(false);
	};

	//display if loading
	if (loading) {
		return <LoadingSpinner />;
	}

	return (
		<Fragment>
			<Grid2 container spacing={5}>
				{/* Last Tick Log Download */}
				<Grid2 sm={12} md={6} lg={4}>
					<Stack>
						<Stack direction='row' sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography textAlign='center'>Last Tick Log Download</Typography>
							<Tooltip text="The date and time of the last Tick Log download that was attempted.  In the 'Successful' box it will display if this last attempt was successful or not." />
						</Stack>
						<TextField
							disabled
							id='Date'
							label='Date'
							variant='outlined'
							margin='dense'
							value={
								moment(new Date(data['Last TickLog Download'].date.toDate())).format('MMM D, YYYY h:mm:ss A') || null
							}
						/>
						<TextField
							disabled
							id='Successful'
							label='Successful'
							variant='outlined'
							margin='dense'
							value={data['Last TickLog Download'].successful || null}
						/>
					</Stack>
				</Grid2>

				{/* Physician Hourly Rate */}
				<Grid2 sm={12} md={6} lg={4}>
					<Stack>
						<Stack direction='row' sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography textAlign='center'>Physician Hourly Rate</Typography>
							<Tooltip text='Used to calculate the hourly work done for CAT/Stipend positions when downloading Tick Logs.' />
						</Stack>
						<TextField
							disabled={!claimsCtx?.claims?.admin || saving}
							id='Physician Hourly Rate'
							label='Physician Hourly Rate'
							variant='outlined'
							margin='dense'
							value={data['Physician Hourly Rate'].hourlyRate || ''}
							type='number'
							onChange={(e) => {
								handleChange(Number(e.target.value), 'Physician Hourly Rate', 'hourlyRate', null);
							}}
							InputProps={{
								startAdornment: <InputAdornment position='start'>$</InputAdornment>,
							}}
						/>
						<Button
							color='saveButton'
							variant='contained'
							startIcon={<SaveRoundedIcon />}
							disabled={
								saving ||
								!claimsCtx?.claims?.admin ||
								AreObjectsEqual(data['Physician Hourly Rate'], originalData['Physician Hourly Rate'])
							}
							onClick={() => {
								handleSave('Physician Hourly Rate', data['Physician Hourly Rate']);
							}}
						>
							SAVE
						</Button>
					</Stack>
				</Grid2>
			</Grid2>
		</Fragment>
	);
}
